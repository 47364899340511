export default function getPageConfigBase(pageId) {
  const history = {
    'dataConfig': {
      'index': 'depth',
      'logCols': [{
        'name': 'RtLoggingEntity.depth',
        'type': 'number',
        'unit': 'm'
      }, {
        'name': 'RtLoggingEntity.spm1',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.spm2',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.spm3',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit1',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit2',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit3',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit4',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit5',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit6',
        'type': 'number',
        'unit': 'm³'
      }]
    },
    'colors': ['red', 'blue', 'green', 'black'],
    'tracks': [{
      'trackType': 'log',
      'order': 1,
      'curves': [{
        'type': 'LogCurve',
        'order': 0,
        'id': 'RtLoggingEntity.spm1'
      }, {
        'type': 'LogCurve',
        'order': 1,
        'id': 'RtLoggingEntity.spm2'
      }, {
        'type': 'LogCurve',
        'order': 2,
        'id': 'RtLoggingEntity.spm3'
      }, {
        'type': 'LogCurve',
        'order': 3,
        'id': 'RtLoggingEntity.pit1'
      }]
    }, {
      'trackType': 'log',
      'order': 2,
      'curves': [{
        'type': 'LogCurve',
        'order': 3,
        'id': 'RtLoggingEntity.pit3'
      }, {
        'type': 'LogCurve',
        'order': 2,
        'id': 'RtLoggingEntity.pit4'
      }, {
        'type': 'LogCurve',
        'order': 1,
        'id': 'RtLoggingEntity.pit5'
      }, {
        'type': 'LogCurve',
        'order': 0,
        'id': 'RtLoggingEntity.pit6'
      }]
    }, {
      'trackType': 'gongkuang',
      'order': 3
    }]
  }

  const realTime = {
    header: {
      cols: [{
        id: 'RtLoggingEntity.gk',
        order: 0
      },
      {
        id: 'RtLoggingEntity.sjjs',
        order: 0
      },
      {
        id: 'RtLoggingEntity.depth',
        order: 1
      },
      {
        id: 'RtLoggingEntity.bitdep',
        order: 2
      },
      {
        id: 'RtLoggingEntity.zbc',
        order: 3
      }
      ]
    },
    wellDrilling: {
      isOpen: true,
      cols: [{
        id: 'RtLoggingEntity.depth',
        order: 0
      },
      {
        id: 'RtLoggingEntity.bitdep',
        order: 1
      }
      ]
    },
    water: {
      isOpen: true,
      cols: [{
        id: 'RtLoggingEntity.mfi',
        order: 0
      },
      {
        id: 'RtLoggingEntity.flowout',
        order: 1
      },
      {
        id: 'RtLoggingEntity.mwi',
        order: 2
      },
      {
        id: 'RtLoggingEntity.mwo',
        order: 3
      },
      {
        id: 'RtLoggingEntity.tpit',
        order: 4
      }, {
        id: 'RtLoggingEntity.gas',
        order: 5
      }
      ]
    },
    temperature: {
      isOpen: true,
      cols: [{
        id: 'RtLoggingEntity.mti',
        order: 0
      },
      {
        id: 'RtLoggingEntity.mto',
        order: 1
      }
      ]
    },
    realtime: {
      isOpen: true,
      cols: [{
        id: 'RtLoggingEntity.wob',
        order: 0
      },
      {
        id: 'RtLoggingEntity.rpm',
        order: 1
      },
      {
        id: 'RtLoggingEntity.hkload',
        order: 2
      },
      {
        id: 'RtLoggingEntity.spp',
        order: 3
      },
      {
        id: 'RtLoggingEntity.timedistInst',
        order: 4
      }, {
        id: 'RtLoggingEntity.cup',
        order: 5
      }
      ]
    },
    paramList: {
      isOpen: true,
      cols: [{
        id: 'engineering',
        order: 0,
        cols: [{
          id: 'RtLoggingEntity.spp',
          order: 0
        },
        {
          id: 'RtLoggingEntity.cup',
          order: 1
        }
        ]
      },
      {
        id: 'mud',
        order: 1,
        cols: [{
          id: 'RtLoggingEntity.pit1',
          order: 0
        },
        {
          id: 'RtLoggingEntity.pit2',
          order: 1
        }
        ]
      },
      {
        id: 'gas',
        order: 2,
        cols: [{
          id: 'RtLoggingEntity.zbc',
          order: 0
        }]
      },
      {
        id: 'cal',
        order: 3,
        cols: [{
          id: 'RtLoggingEntity.torque',
          order: 0
        }]
      }
      ]
    }
  }
  const multiwell = {
    cols: [{
      id: 'ProcDrillcrewEntity.ljd',
      order: 0
    },
    {
      id: 'ProcDrillcrewEntity.ljdxcdh',
      order: 1
    },
    {
      id: 'ProcDrillcrewEntity.zjd',
      order: 2
    },
    {
      id: 'ProcDrillcrewEntity.zjdxcdh',
      order: 3
    }
    ],
    orders: []
  }
  const meterData = {
    'dataConfig': {
      'index': 'depth',
      'logCols': [{
        'name': 'RtLoggingEntity.depth',
        'type': 'number',
        'unit': 'm'
      }, {
        'name': 'RtLoggingEntity.spm1',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.spm2',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.spm3',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit1',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit2',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit3',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit4',
        'type': 'number',
        'unit': '1/min'
      }, {
        'name': 'RtLoggingEntity.pit5',
        'type': 'number',
        'unit': 'm³'
      }, {
        'name': 'RtLoggingEntity.pit6',
        'type': 'number',
        'unit': 'm³'
      }]
    },
    'colors': ['red', 'blue', 'green', 'black'],
    'tracks': [{
      'trackType': 'yancheng',
      'order': 1
    }, {
      'trackType': 'log',
      'order': 3,
      'curves': [{
        'type': 'LogCurve',
        'order': 0,
        'id': 'RtLoggingEntity.spm1'
      }, {
        'type': 'LogCurve',
        'order': 1,
        'id': 'RtLoggingEntity.spm2'
      }, {
        'type': 'LogCurve',
        'order': 2,
        'id': 'RtLoggingEntity.spm3'
      }, {
        'type': 'LogCurve',
        'order': 3,
        'id': 'RtLoggingEntity.pit1'
      }]
    }, {
      'trackType': 'log',
      'order': 4,
      'curves': [{
        'type': 'LogCurve',
        'order': 3,
        'id': 'RtLoggingEntity.pit3'
      }, {
        'type': 'LogCurve',
        'order': 2,
        'id': 'RtLoggingEntity.pit4'
      }, {
        'type': 'LogCurve',
        'order': 1,
        'id': 'RtLoggingEntity.pit5'
      }, {
        'type': 'LogCurve',
        'order': 0,
        'id': 'RtLoggingEntity.pit6'
      }]
    }]
  }
  const ivfData = {
    'colors': [
      '#0000FF',
      '#FF00FF',
      '#00FF00',
      '#FF0000'
    ],
    'dataConfig': {
      'index': 'depth',
      'logCols': [
        {
          'id': 'LLD',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': 'LLS',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': 'LL8',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': 'ILM',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω·m'
        },
        {
          'id': 'ILD',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': '4m-RT',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': '2.5m-R2.5',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': 'VSH',
          'type': 'number',
          'min': '0',
          'max': '500',
          'unit': '小数'
        },
        {
          'id': 'POR',
          'type': 'number',
          'min': '0',
          'max': '500',
          'unit': '小数'
        },
        {
          'id': 'POG',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'at/m'
        },
        {
          'id': 'POM',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'SDX',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'at/m'
        },
        {
          'id': 'SDXM',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'SDY',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'at/m'
        },
        {
          'id': 'SDYM',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'PFG',
          'type': 'number',
          'unit': 'at/m'
        },
        {
          'id': 'PFM',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '小数'
        },
        {
          'id': 'PCG',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'at/m'
        },
        {
          'id': 'PAZ',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'S/m'
        },
        {
          'id': 'POIS',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '小数'
        },
        {
          'id': 'ARFA',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '小数'
        },
        {
          'id': 'CDEN',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'g/cm3'
        },
        {
          'id': 'TS',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'us/ft'
        },
        {
          'id': 'DSR',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'Ω.m'
        },
        {
          'id': 'BULK',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '10WPa'
        },
        {
          'id': 'TXML',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '10MPa'
        },
        {
          'id': 'SMOD',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '10MPa'
        },
        {
          'id': 'BMOD',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '10MPa'
        },
        {
          'id': 'CB',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '10MPa'
        },
        {
          'id': 'UR',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'TOUR',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'ST',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'PMX',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'g/cm3'
        },
        {
          'id': 'PMY',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'g/cm3'
        },
        {
          'id': 'PMC',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'g/cm3'
        },
        {
          'id': 'RESS',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'MPa'
        },
        {
          'id': 'KV',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': ''
        },
        {
          'id': 'RF',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': ''
        },
        {
          'id': 'FII',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': ''
        },
        {
          'id': 'RG',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': ''
        },
        {
          'id': 'BRYP',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '%'
        },
        {
          'id': 'DTC',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': '%'
        },
        {
          'id': 'K',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': ''
        },
        {
          'id': 'CZDCZD',
          'min': '0',
          'max': '500',
          'type': 'number',
          'unit': 'm'
        }
      ]
    },
    'tracks': [
      {
        'trackType': 'log',
        'order': 0,
        'curves': [
          {
            'type': 'LogCurve',
            'id': 'AC',
            'order': 2,
            'qxmc': '声波时差',
            'unit': 'us/m'

          },
          {
            'type': 'LogCurve',
            'id': 'GR',
            'order': 0,
            'qxmc': '自然伽马',
            'unit': 'API'
          },
          {
            'type': 'LogCurve',
            'id': 'SP',
            'order': 1,
            'qxmc': '自然电位',
            'unit': 'mV'
          },
          {
            'type': 'LogCurve',
            'id': 'POR',
            'order': 3,
            'qxmc': '孔隙度',
            'unit': '百分数'

          },
        ]
      },
      {
        'trackType': 'log',
        'order': 1,
        'curves': [
          {
            'type': 'LogCurve',
            'id': 'LL8',
            'order': '',
            'qxmc': '八侧向',
            'unit': 'Ω.m'
          },
          {
            'type': 'LogCurve',
            'id': 'ILM',
            'order': '',
            'qxmc': '中感应',
            'unit': 'Ω.m'
          },
          {
            'type': 'LogCurve',
            'id': 'ILD',
            'order': '',
            'qxmc': '深感应',
            'unit': 'Ω.m'
          }
          // {
          //   'type': 'LogCurve',
          //   'id': 'LLS',
          //   'order': '',
          //   'qxmc': '浅侧向',
          //   'unit': 'Ω.m'
          // }
        ]
      },
      {
        'trackType': 'log',
        'order': 2,
        'curves': [
          {
            'type': 'LogCurve',
            'id': 'COND',
            'order': '',
            'qxmc': '感应电导率',
            'unit': ''

          }
        ]
      },
      {
        'trackType': 'log',
        'order': 3,
        'curves': [
          {
            'qxmc': '含油饱和度',
            'type': 'LogCurve',
            'id': 'SH2',
            'order': '',
            'unit': '百分数'
          },
          {
            'type': 'LogCurve',
            'id': 'SH1',
            'qxmc': '岩性组合',
            'order': '',
            'unit': '%'
          }
        ]
      }
    ]
  }
  const ivfTrack2 = {
    'dimId': '',
    'scopeType': 'noSystem',
    'cols': [
      {
        'bm': 'VSH',
        'type': 'number',
        'min': '',
        'qxmc': '泥质含量',
        'max': '',
        'dw': '小数'
      },
      {
        'bm': 'POR',
        'type': 'number',
        'qxmc': '孔隙度',
        'min': '',
        'max': '',
        'dw': '小数'
      },
      {
        'bm': 'POG',
        'qxmc': '垂向应力梯度（上覆压力梯度）',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'at/m'
      },
      {
        'bm': 'POM',
        'min': '',
        'qxmc': '垂向应力（上覆压力）',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'SDX',
        'min': '',
        'qxmc': '水平最大主应力梯度',
        'max': '',
        'type': 'number',
        'dw': 'at/m'
      },
      {
        'bm': 'SDXM',
        'min': '',
        'qxmc': '水平最大主应力',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'SDY',
        'min': '',
        'max': '',
        'qxmc': '水平最小主应力梯度',
        'type': 'number',
        'dw': 'at/m'
      },
      {
        'bm': 'SDYM',
        'min': '',
        'max': '',
        'qxmc': '水平最小主应力',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'PFG',
        'min': '',
        'qxmc': '破裂压力梯度',
        'max': '',
        'type': 'number',
        'dw': 'at/m'
      },
      {
        'qxmc': '破裂压力',
        'bm': 'PFM',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '小数'
      },
      {
        'qxmc': '坍塌压力梯度',
        'bm': 'PCG',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'at/m'
      },
      {
        'qxmc': '水平最大主应力方向',
        'bm': 'PAZ',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'S/m'
      },
      {
        'qxmc': '泊松比',
        'bm': 'POIS',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '小数'
      },
      {
        'qxmc': '毕奥特系数',
        'bm': 'ARFA',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '小数'
      },
      {
        'qxmc': '计算密度',
        'bm': 'CDEN',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'g/cm3'
      },
      {
        'bm': 'TS',
        'min': '',
        'qxmc': '计算横波',
        'max': '',
        'type': 'number',
        'dw': 'us/ft'
      },
      {
        'qxmc': '纵，横波速度比',
        'bm': 'DSR',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'Ω.m'
      },
      {
        'qxmc': '出砂指数',
        'bm': 'BULK',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '10WPa'
      },
      {
        'bm': 'TXML',
        'qxmc': '弹性模量',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '10MPa'
      },
      {
        'bm': 'SMOD',
        'qxmc': '切变模量',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '10MPa'
      },
      {
        'bm': 'BMOD',
        'qxmc': '体积弹性模量',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '10MPa'
      },
      {
        'bm': 'CB',
        'qxmc': '体积压缩模量',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '10MPa'
      },
      {
        'bm': 'UR',
        'qxmc': '单轴抗压强度',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'TOUR',
        'qxmc': '固有剪切强度',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'ST',
        'qxmc': '抗压强度',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'PMX',
        'qxmc': '破裂压力当量密度',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'g/cm3'
      },
      {
        'bm': 'PMY',
        'min': '',
        'qxmc': '坍塌压力当量密度',
        'max': '',
        'type': 'number',
        'dw': 'g/cm3'
      },
      {
        'bm': 'PMC',
        'qxmc': '钻井液密度',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'g/cm3'
      },
      {
        'bm': 'RESS',
        'min': '',
        'qxmc': '剩余剪应力',
        'max': '',
        'type': 'number',
        'dw': 'MPa'
      },
      {
        'bm': 'KV',
        'min': '',
        'qxmc': '完整性系数',
        'max': '',
        'type': 'number',
        'dw': ''
      },
      {
        'bm': 'RF',
        'min': '',
        'qxmc': '裂缝发育程度',
        'max': '',
        'type': 'number',
        'dw': ''
      },
      {
        'bm': 'FII',
        'min': '',
        'qxmc': '岩石破裂率',
        'max': '',
        'type': 'number',
        'dw': ''
      },
      {
        'bm': 'RG',
        'qxmc': '岩石稳定系数',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': ''
      },
      {
        'qxmc': '脆性指数',
        'bm': 'BRYP',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '%'
      },
      {
        'bm': 'DTC',
        'qxmc': '有机碳含量',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': '%'
      },
      {
        'bm': 'K',
        'qxmc': '应力差异系数',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': ''
      },
      {
        'qxmc': '（斜井）计算垂深',
        'bm': 'CZDCZD',
        'min': '',
        'max': '',
        'type': 'number',
        'dw': 'm'
      }
    ],
    'target': '209673570417389568'
  }
  const pageConfig = {
    history,
    realTime,
    multiwell,
    meterData,
    ivfData,
    ivfTrack2
  }
  return pageConfig[pageId]
}
