import Vue from 'vue'
import SvgIcon from '@/framework/components/SvgIcon' // svg组件
import generateIconsView from '@/framework/views/svgIcons/generateIconsView.js' // just for @/framework/views/icons , you can delete it

// register globally
Vue.component("svg-icon", SvgIcon);

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./svgIcon", false, /\.svg$/);
const iconMap = requireAll(req);

generateIconsView.generate(iconMap);
