import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

// 公共多语言字典 start
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'
// 公共多语言字典 end

// 华信多语言字典 start
import HX_en from '@/SZGZ/lang/en'
import HX_zh from '@/SZGZ/lang/zh'
// 华信多语言字典 end

// 亚太多语言字典 start
import ProcesscentersZH from '@/Processcenters/lang/zh'
import ProcesscentersEN from '@/Processcenters/lang/en'
import WellcompletionManageZH from '@/WellcompletionManage/lang/zh'
import WellcompletionManageEN from '@/WellcompletionManage/lang/en'
import WorkbenchZH from '@/Workbench/lang/zh'
import WorkbenchEN from '@/Workbench/lang/en'
import ArtBagZH from '@/ArtBag/lang/zh'
import ArtBagEN from '@/ArtBag/lang/en'
import DailyFeeMangeZH from '@/DailyFeeMange/lang/zh'
import DailyFeeMangeEN from '@/DailyFeeMange/lang/en'
import OrdertrackingZH from '@/Ordertracking/lang/zh'
import OrdertrackingEN from '@/Ordertracking/lang/en'
import ChangeProcessZH from '@/ChangeProcess/lang/zh'
import ChangeProcessEN from '@/ChangeProcess/lang/en'
// 亚太多语言字典 end

// 盛华多语言字典 start
import WellCompZH from '../wellComp/lang/zh'
import WellCompEN from '../wellComp/lang/en'
import OnlineDesignZH from '../onlineDesign/lang/zh'
import OnlineDesignEN from '../onlineDesign/lang/en'
import prodScheduleZH from '../prodSchedule/lang/zh'
import prodScheduleEN from '../prodSchedule/lang/en'
// 盛华多语言字典 end

// 胜软多语言字典 start
// TBD
// 胜软多语言字典 end

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
    ...HX_en,
    ...OrdertrackingEN,
    ...ChangeProcessEN,
    ...DailyFeeMangeEN,
    ...ArtBagEN,
    ...ProcesscentersEN,
    ...WellcompletionManageEN,
    ...WorkbenchEN,
    ...WellCompEN,
    ...prodScheduleEN,
    ...OnlineDesignEN
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
    ...HX_zh,
    ...OrdertrackingZH,
    ...ChangeProcessZH,
    ...DailyFeeMangeZH,
    ...ArtBagZH,
    ...ProcesscentersZH,
    ...WellcompletionManageZH,
    ...WorkbenchZH,
    ...WellCompZH,
    ...prodScheduleZH,
    ...OnlineDesignZH
  }
}

const i18n = new VueI18n({
  locale: Cookies.get('PEAdviserLanguage') || 'zh', // set locale
  messages, // set locale messages
  silentTranslationWarn: true
})

export default i18n
