import Vue from 'vue'
import Router from 'vue-router'
// import LayoutDP from '../framework/views/layout/LayoutDP'
const _import = file => () => import ('@/' + file + '.vue')
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;

Vue.use(Router)

/* Layout */
import Layout from '../framework/views/layout/Layout'
// import SecondLayout from '../framework/views/secondLev'
import FrontPageHead from '../Workbench/views/FrontPageHead'
// import FrontPageHeadNew from '../Workbench/views/FrontPageHeadNew'
export const LayoutComponent = Layout
export const _import_ = _import

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if fasle ,the page will no be cached(default is false)
  }
 **/
export const constantRouterMap = [
  { path: '/mainHome', name: 'login', component: _import('framework/views/login/index'), hidden: true },
  { path: '/logout', name: 'logut', component: _import('framework/views/login/logout'), hidden: true },
  { path: '/authredirect', name: 'authredirect', component: _import('framework/views/login/authredirect'), hidden: true },
  { path: '/404', name: '404', component: _import('framework/views/404'), hidden: true },

  // 总部首页
  { path: '', name: 'homeindex', component: _import('Workbench/views/index'), redirect: '/workbench1/workbench1', hidden: true },
  { path: '/portal', name: 'portal', component: _import('Workbench/views/index'), redirect: '/workbench1/workbench1', hidden: true },


  { path: '/register', name: 'register', component: _import('framework/views/login/register'), hidden: true },
  { path: '/sinopecAuth/redirect', name: 'redirect', component: _import('framework/views/home/identity'), hidden: true },
  { path: '/sinopecAuth/callback', name: 'callback', component: _import('framework/views/home/callback'), hidden: true },
  { path: '/sinopecAuth/registerRedirect', name: 'registerRedirect', component: _import('framework/views/home/registerIdentity'), hidden: true },
  { path: '/sinopecAuth/registerCallback', name: 'registerCallback', component: _import('framework/views/login/register'), hidden: true },
  { path: '/edit', name: 'edit', component: _import('onlineDesign/components/WebEdit'), hidden: true },
  { path: '/libray', name: 'libray', component: _import('QYFZ/views/drillingFaultLibray/index'), hidden: true },
  // { path: '/sys/admin/supplier', name: 'supplier', component: _import('admin/supplier/index'), hidden: true },
  // 添加路由
  {
    path: '/own',
    component: LayoutComponent,
    redirect: '/own/ownspace',
    hidden: true,
    name: 'own',
    meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
    children: [
      {
        path: 'ownspace',
        component: _import('framework/views/home/ownspace'),
        name: 'ownspace',
        meta: { title: 'ownspace', noCache: true, icon: 'home' }
      }
    ]
  },

  // 大屏
  // {
  //   path: '/dp',
  //   component: LayoutDP,
  //   redirect: '/dp/realDataDp',
  //   name: 'dp',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [
  //     {
  //       path: 'realDataDp',
  //       component: _import('SZGZ/views/realDataDp/indexDP'),
  //       name: 'realDataDp',
  //       meta: { title: 'realDataDp', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'multiwellDp',
  //       component: _import('SZGZ/views/multiwellDp/index'),
  //       name: 'multiwellDp',
  //       meta: { title: 'multiwellDp', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'gisDp',
  //       component: _import('SZGZ/views/wellPositionDp/index'),
  //       name: 'gisDp',
  //       meta: { title: 'gisDp', noCache: true, icon: 'home' }
  //     }
  //   ]
  // },
  //
  // 亚太模块
  // 钻井路由
  // {
  //   path: '/engineeringmanagement',
  //   // path: '/processcenters',
  //   component: Layout,
  //   redirect: '/engineeringmanagement/designtasklist',
  //   name: 'engineeringmanagement',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [
  //     {
  //       path: 'designtasklist',
  //       component: _import('Processcenters/components/designtasklist/index'),
  //       name: 'designtasklist',
  //       meta: { title: '提交设计任务', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'organizationalReviewList',
  //       component: _import('Processcenters/components/organizationalReviewList/index'),
  //       name: 'organizationalReviewList',
  //       meta: { title: '组织设计审查', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'designReviewList',
  //       component: _import('Processcenters/components/designReviewList/index'),
  //       name: 'designReviewList',
  //       meta: { title: '钻井设计审查', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'revisionOpinionList',
  //       component: _import('Processcenters/components/revisionOpinionList/index'),
  //       name: 'revisionOpinionList',
  //       meta: { title: '钻井设计修订', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'auditOpinionList',
  //       component: _import('Processcenters/components/auditOpinionList/index'),
  //       name: 'auditOpinionList',
  //       meta: { title: '钻井修订审核', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'durillingRoadworkBidResultWarehousing',
  //       component: _import('Processcenters/components/durillingRoadworkBidResultWarehousing/index'),
  //       name: 'durillingRoadworkBidResultWarehousing',
  //       meta: { title: '工程总览', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'ProjectNodeFillDate',
  //       component: _import('Processcenters/components/ProjectNodeFillDate/index'),
  //       name: 'ProjectNodeFillDate',
  //       meta: { title: '工程节点填报', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'drillingEngineeringEvaluationBooksList',
  //       component: _import('Processcenters/components/drillingEngineeringEvaluationBooksList/index'),
  //       name: 'drillingEngineeringEvaluationBooksList',
  //       meta: { title: '工程评定书', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'flagBrowsing',
  //       component: _import('Processcenters/components/flagBrowsing/index'),
  //       name: 'flagBrowsing',
  //       meta: { title: '流动红旗浏览', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'flagManagement',
  //       component: _import('Processcenters/components/flagBrowsing/flagManagement'),
  //       name: 'flagManagement',
  //       meta: { title: '流动红旗管理', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'WellReportMaintenance',
  //       component: _import('Processcenters/components/WellReportMaintenance/index'),
  //       name: 'WellReportMaintenance',
  //       meta: { title: '井设计报告维护', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'historyWellMaintain',
  //       component: _import('Workbench/components/singleWellInfo/component/fileNew/fileUpload'),
  //       name: 'WellReportMaintenance',
  //       meta: { title: '历史井文档维护', noCache: true, icon: 'jsjbgwh' }
  //     },
  //     {
  //       path: 'expertSort',
  //       component: _import('Processcenters/components/expertSort/index'),
  //       name: 'expertSort',
  //       meta: { title: '专家排序管理', noCache: true, icon: 'jsjbgwh' }
  //     }
  //   ]
  // },

  // 完井路由 --- 会影响样式
  // {
  //   path: '/wellcompletionManage',
  //   component: Layout,
  //   redirect: 'wellcompletionManage/taskpublishlists',
  //   name: 'wellcompletionManage',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [
  //     {
  //       path: 'taskpublishlists',
  //       component: _import('WellcompletionManage/components/taskpublishlists/index'),
  //       name: 'taskpublishlists',
  //       meta: { title: '完井任务下达', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'designtasklists',
  //       component: _import('WellcompletionManage/components/designtasklist/index'),
  //       name: 'designtasklists',
  //       meta: { title: '提交设计任务', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'organizationalReviewLists',
  //       component: _import('WellcompletionManage/components/organizationalReviewList/index'),
  //       name: 'organizationalReviewLists',
  //       meta: { title: '组织设计审查', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'designReviewLists',
  //       component: _import('WellcompletionManage/components/designReviewList/index'),
  //       name: 'designReviewLists',
  //       meta: { title: '完井设计审查', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'revisionOpinionLists',
  //       component: _import('WellcompletionManage/components/revisionOpinionList/index'),
  //       name: 'revisionOpinionLists',
  //       meta: { title: '完井设计修订', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'auditOpinionLists',
  //       component: _import('WellcompletionManage/components/auditOpinionList/index'),
  //       name: 'auditOpinionLists',
  //       meta: { title: '完井修订审核', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'drillingEngineeringEvaluationBooksLists',
  //       component: _import('WellcompletionManage/components/drillingEngineeringEvaluationBooksList/index'),
  //       name: 'drillingEngineeringEvaluationBooksLists',
  //       meta: { title: '工程评定书', noCache: true, icon: 'home' }
  //     }
  //   ]
  // },

  // 工作台
  {path: '/workbench1',
    component: FrontPageHead,
    redirect: 'workbench1/workbench1',
    name: 'workbench1',
    meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
    children: [{
      path: 'workbench1',
      component: _import('Workbench/views/indexSZGZ'),
      name: 'workbench1',
      meta: { title: 'workbench1', noCache: true, icon: 'home' }
    }]},
  // {
  //   path: '/workbench',
  //   component: FrontPageHeadNew,
  //   redirect: 'workbench/workbench',
  //   name: 'workbench',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'workbench',
  //     component: _import('Workbench/views/index'),
  //     name: 'workbench',
  //     meta: { title: 'workbench', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'workbenchapproval',
  //     component: _import('Workbenchapproval/views/index'),
  //     name: 'workbenchapproval',
  //     meta: { title: 'workbenchapproval', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'processcenters',
  //     component: _import('Processcenters/views/index'),
  //     name: 'processcenters',
  //     meta: { title: 'processcenters', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'ordertracking',
  //     component: _import('Ordertracking/views/index'),
  //     name: 'ordertracking',
  //     meta: { title: 'ordertracking', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'instructedto',
  //     component: _import('Ordertracking/components/instructedto/index'),
  //     name: 'instructedto',
  //     meta: { title: 'instructedto', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'handledetails',
  //     component: _import('Ordertracking/components/handledetails/index'),
  //     name: 'handledetails',
  //     meta: { title: 'handledetails', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'confirmcomplaint',
  //     component: _import('Ordertracking/components/confirmcomplaint/index'),
  //     name: 'confirmcomplaint',
  //     meta: { title: 'confirmcomplaint', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'handledconfirm',
  //     component: _import('Ordertracking/components/handledconfirm/index'),
  //     name: 'handledconfirm',
  //     meta: { title: 'handledconfirm', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'artbag',
  //     component: _import('ArtBag/views/index'),
  //     name: 'artbag',
  //     meta: { title: 'artbag', noCache: true, icon: 'home' }
  //   },
  //     {
  //       path: 'dailyFeeMange',
  //       component: _import('DailyFeeMange/views/index'),
  //       name: 'dailyFeeMange',
  //       meta: { title: 'dailyFeeMange', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'dailyFeeMangeAdmin',
  //       component: _import('DailyFeeMange/views/index'),
  //       name: 'dailyFeeMangeAdmin',
  //       meta: { title: 'dailyFeeMangeAdmin', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'urlPages',
  //       component: _import('Processcenters/components/urlPages/index'),
  //       name: 'urlPages',
  //       meta: { title: 'urlPages', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'fileLists',
  //       component: _import('Processcenters/fileLists/index'),
  //       name: 'fileLists',
  //       meta: { title: 'fileLists', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'singleWell',
  //       component: _import('Workbench/components/singleWell/index'),
  //       name: 'singleWell',
  //       meta: { title: 'singleWell', noCache: true, icon: 'home' }
  //     }, {
  //       path: 'singleWellInfo',
  //       component: _import('Workbench/components/singleWellInfo/index'),
  //       name: 'singleWellInfo',
  //       meta: { title: 'singleWellInfo', noCache: true, icon: 'home' }
  //     }
  //   ]
  // },
  // 变更设计任务
  // {
  //   path: '/transform',
  //   component: Layout,
  //   redirect: '/transform/designChange',
  //   name: 'transform',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'designChange',
  //     component: _import('ChangeProcess/views/index'),
  //     name: 'designChange',
  //     meta: { title: '变更设计任务下达', noCache: true, icon: 'home' }
  //   },
  //     {
  //       path: 'designTaskList',
  //       component: _import('ChangeProcess/components/designTaskList/taskList'),
  //       name: 'designTaskList',
  //       meta: { title: '变更设计报告提交', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'reviewTaskList',
  //       component: _import('ChangeProcess/components/reviewTaskList/taskList'),
  //       name: 'reviewTaskList',
  //       meta: { title: '变更设计评审', noCache: true, icon: 'home' }
  //     }
  //   ]
  // },
  // 二、施工变更申请审批流程（小变更）
  // {
  //   path: '/smallChange',
  //   component: Layout,
  //   redirect: '/smallChange/changeLaunch',
  //   name: 'smallChange',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'changeLaunch',
  //     component: _import('Small_change/views/index'),
  //     name: 'changeLaunch',
  //     meta: { title: '施工变更申请', noCache: true, icon: 'sgbgsq' }
  //   }, {
  //     path: 'changeApproval',
  //     component: _import('Small_change/component/changeApproval'),
  //     name: 'changeApproval',
  //     meta: { title: '施工变更审批', noCache: true, icon: 'sgbgsp' }
  //   },
  //   ]
  // },
  // // 队伍考核
  // {
  //   path: '/teamAssessment',
  //   component: FrontPageHeadNew,
  //   redirect: '/teamAssessment/teamAssessmentPage',
  //   name: 'teamAssessment',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'teamAssessmentPage',
  //     component: _import('Team_assessment/views/index'),
  //     name: 'teamAssessmentPage',
  //     meta: { title: '队伍考核', noCache: true, icon: 'home' }
  //   }
  //   ]
  // },
  // // 工程概况
  // {
  //   path: '/processcenters',
  //   component: FrontPageHeadNew,
  //   // redirect: '/processcenters/gcgk',
  //   name: 'processcenters',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'gcgk/annualworkload/wellComple',
  //     component: _import('ProjectSummary/components/wellComple/index'),
  //     name: 'wellComple',
  //     meta: { title: 'wellComple', noCache: true, icon: 'home' },
  //   }, {
  //     path: 'gcgk/annualworkload/wellNum',
  //     component: _import('ProjectSummary/components/wellNum/index'),
  //     name: 'wellNum',
  //     meta: { title: 'wellNum', noCache: true, icon: 'home' },
  //   }, {
  //     path: 'gcgk/annualworkload/footage',
  //     component: _import('ProjectSummary/components/Footage/index'),
  //     name: 'footage',
  //     meta: { title: 'footage', noCache: true, icon: 'home' },
  //   },
  //     {
  //       path: 'gcgk/drillingdynamic/myDrillingList',
  //       component: _import('ProjectSummary/components/myDrillingList/index'),
  //       name: 'myDrillingList',
  //       meta: { title: 'myDrillingList', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/drillingdynamic/stateStatistics',
  //       component: _import('ProjectSummary/components/stateStatistics/index'),
  //       name: 'stateStatistics',
  //       meta: { title: 'stateStatistics', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/drillingdynamic/drillingprogress',
  //       component: _import('ProjectSummary/components/Drillingprogress/index'),
  //       name: 'drillingprogress',
  //       meta: { title: 'drillingprogress', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/kpi/MechanicalDrillingRateList',
  //       component: _import('ProjectSummary/components/MechanicalDrillingRateList/index'),
  //       name: 'MechanicalDrillingRateList',
  //       meta: { title: 'MechanicalDrillingRateList', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/kpi/myCycle',
  //       component: _import('ProjectSummary/components/myCycle/index'),
  //       name: 'myCycle',
  //       meta: { title: 'myCycle', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/fault',
  //       component: _import('ProjectSummary/components/fault/index'),
  //       name: 'fault',
  //       meta: { title: 'fault', noCache: true, icon: 'home' },
  //     },
  //     {
  //       path: 'gcgk/drillingOperation',
  //       component: _import('ProjectSummary/components/drillingOperation/index'),
  //       name: 'drillingOperation',
  //       meta: { title: 'drillingOperation', noCache: true, icon: 'home' },
  //     }
  //   ]
  // },
  // // 工程统计模块，外挂iframe的Url链接
  // {
  //   path: '/YTmodule',
  //   component: Layout,
  //   redirect: '/YTmodule/annualDrillingEngineeringQuality',
  //   name: 'YTmodule',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'home' },
  //   children: [{
  //     path: 'annualDrillingEngineeringQuality',
  //     component: _import('EnginneringStatistics_YT/annualDrillingEngineeringQuality/index'),
  //     name: 'annualDrillingEngineeringQuality',
  //     meta: { title: '钻井工程质量统计表', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'annualDrillingStatistics',
  //     component: _import('EnginneringStatistics_YT/annualDrillingStatistics/index'),
  //     name: 'annualDrillingStatistics',
  //     meta: { title: '钻井情况统计表', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'drilledWellPeriodicTable',
  //     component: _import('EnginneringStatistics_YT/drilledWellPeriodicTable/index'),
  //     name: 'drilledWellPeriodicTable',
  //     meta: { title: '完钻井钻井周期表', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'drillingEngineeringTable',
  //     component: _import('EnginneringStatistics_YT/drillingEngineeringTable/index'),
  //     name: 'drillingEngineeringTable',
  //     meta: { title: '钻井工程质量评定表', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'spudCompletionCycleSchedule',
  //     component: _import('EnginneringStatistics_YT/spudCompletionCycleSchedule/index'),
  //     name: 'spudCompletionCycleSchedule',
  //     meta: { title: '开钻/完钻/周期明细表', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'fault',
  //     component: _import('EnginneringStatistics_YT/fault/index'),
  //     name: 'fault',
  //     meta: { title: '复杂情况及事故统计分析', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'Footage',
  //     component: _import('EnginneringStatistics_YT/Footage/index'),
  //     name: 'Footage',
  //     meta: { title: '进尺统计', noCache: true, icon: 'home' }
  //   }, {
  //     path: 'myCycle',
  //     component: _import('EnginneringStatistics_YT/myCycle/index'),
  //     name: 'myCycle',
  //     meta: { title: '平均钻井周期', noCache: true, icon: 'home' }
  //   },
  //   ]
  // },
  // // 盛华模块
  // {
  //   path: '/wellComp',
  //   component: Layout,
  //   redirect: '/wellComp/wellCompInfo',
  //   name: 'wellComp',
  //   meta: { title: '完井日报', type: 'index', icon: '完井日报管理', noCache: true },
  //   children: [
  //     {
  //       path: 'wellCompInfo',
  //       component: _import('wellComp/views/rptManagement/wellCompInfo'),
  //       name: 'wellCompInfo',
  //       meta: { title: '完井施工信息', type: 'index', icon: '完井施工信息', noCache: true }
  //     },
  //     {
  //       path: 'wellCompFill',
  //       component: _import('wellComp/views/rptManagement/wellCompFill'),
  //       name: 'wellCompFill',
  //       meta: { title: '完井日报填报', type: 'index', icon: '完井日报填报', noCache: true }
  //     },
  //     {
  //       path: 'wellCompDayReport',
  //       component: _import('wellComp/views/rptManagement/wellCompDayReport'),
  //       name: 'wellCompDayReport',
  //       meta: { title: '当日完井日报', type: 'index', icon: '当日完井日报', noCache: true }
  //     },
  //     {
  //       path: 'wellCompAuditor',
  //       component: _import('wellComp/views/rptManagement/wellCompAuditor'),
  //       name: 'wellCompAuditor',
  //       meta: { title: '完井日报审核', type: 'index', icon: '完井日报审核', noCache: true }
  //     },
  //     {
  //       path: 'wellCompView',
  //       component: _import('wellComp/views/rptManagement/wellCompView'),
  //       name: 'wellCompView',
  //       meta: { title: '完井日报浏览', type: 'index', icon: '完井日报浏览', noCache: true }
  //     },
  //
  //     {
  //       path: 'wellCompMonthReport',
  //       component: _import('wellComp/views/rptManagement/wellCompMonthReport'),
  //       name: 'wellCompMonthReport',
  //       meta: { title: '完井日报汇总', type: 'index', icon: '完井日报汇总', noCache: true }
  //     },
  //
  //     {
  //       path: 'wellCompScjd',
  //       name: 'wellCompScjd',
  //       component: _import('wellComp/components/scjdlr'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '生产进度录入', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'wellScjd',
  //       component: _import('wellComp/views/rptManagement/scjddtgz'),
  //       name: 'wellScjd',
  //       meta: { title: '生产进度动态跟踪', type: 'index', icon: '生产进度跟踪', noCache: true }
  //     }
  //   ]
  // },
  // // 钻井在线设计
  // {
  //   path: '/onlineDesign1',
  //   name: 'onlineDesign1',
  //   redirect: 'onlineDesign1/plan',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'baseDataManager',
  //       name: 'baseDataManager',
  //       component: _import('onlineDesign/views/onlineDesign/plan/baseDataManager'),
  //       hidden: false,
  //       props: true,
  //       meta: { title: '钻井基础数据', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'docManager',
  //       name: 'docManager',
  //       component: _import('onlineDesign/views/onlineDesign/docManager'),
  //       props: true,
  //       meta: { title: '自定义模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'drillingTemp',
  //       name: 'drillingTemp',
  //       component: _import('onlineDesign/views/onlineDesign/drillingTemp'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '钻井模板下载', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'drillingTempManager',
  //       name: 'drillingTempManager',
  //       component: _import('onlineDesign/views/onlineDesign/drillingTempManager'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '钻井模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'plan',
  //       name: 'plan',
  //       component: _import('onlineDesign/views/onlineDesign/plan/index'),
  //       hidden: false,
  //       props: true,
  //       meta: { title: '新建在线设计', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'straightWellPlan',
  //       name: 'straightWellPlan',
  //       component: _import('onlineDesign/views/onlineDesign/plan/straightWellPlan'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '直井钻井方案', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'inclinedWellPlan',
  //       name: 'inclinedWellPlan',
  //       component: _import('onlineDesign/views/onlineDesign/plan/inclinedWellPlan'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '斜井钻井方案', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'sidetracWellPlan1',
  //       name: 'sidetracWellPlan1',
  //       component: _import('onlineDesign/views/onlineDesign/plan/sidetracWellPlan1'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '侧钻井一开方案', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'sidetracWellPlan2',
  //       name: 'sidetracWellPlan2',
  //       component: _import('onlineDesign/views/onlineDesign/plan/sidetracWellPlan2'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '侧钻井二开方案', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'onlines',
  //       name: 'onlines',
  //       component: _import('onlineDesign/views/onlineDesign/index'),
  //       props: true,
  //       meta: { title: '钻井设计上传', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'onlineDesignView',
  //       name: 'onlineDesignView',
  //       component: _import('onlineDesign/views/onlineDesign/onlineDesignView'),
  //       props: true,
  //       meta: { title: '在线设计浏览', icon: 'home' }
  //     },
  //     {
  //       path: 'onlineDesignManager',
  //       name: 'onlineDesignManager',
  //       component: _import('onlineDesign/views/onlineDesign/onlineDesignManager'),
  //       props: true,
  //       meta: { title: '在线设计管理', icon: 'home' }
  //     }
  //   ]
  // },
  // // 完井在线设计
  // {
  //   path: '/wellCompOnlineDesign',
  //   name: 'wellCompOnlineDesign',
  //   redirect: 'wellCompOnlineDesign/wellCompPlan',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'wellCompBaseDataManager',
  //       name: 'wellCompBaseDataManager',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/plan/baseDataManager'),
  //       props: true,
  //       meta: { title: '基础数据管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompPlan',
  //       name: 'wellCompPlan',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/plan/index'),
  //       props: true,
  //       meta: { title: '完井方案填报', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompUserTemp',
  //       name: 'wellCompUserTemp',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/docManager'),
  //       props: true,
  //       meta: { title: '完井自定义模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompTemp',
  //       name: 'wellCompTemp',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/wellCompTemp'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '完井模板下载', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompTempManager',
  //       name: 'wellCompTempManager',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/wellCompTempManager'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '完井模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompPlanOut',
  //       name: 'wellCompPlanOut',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/plan/wellCompPlanOut'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '完井方案', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'index',
  //       name: 'index',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/index'),
  //       props: true,
  //       meta: { title: '新建完井在线设计', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'View',
  //       name: 'View',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/compOnlineDesignView'),
  //       props: true,
  //       meta: { title: '在线完井设计浏览', icon: 'home' }
  //     },
  //     {
  //       path: 'Manager',
  //       name: 'Manager',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/compOnlineDesignManager'),
  //       props: true,
  //       meta: { title: '在线完井设计管理', icon: 'home' }
  //     },
  //     {
  //       path: 'wellCompFileManager',
  //       name: 'wellCompFileManager',
  //       component: _import('onlineDesign/views/wellCompOnlineDesign/wellCompFileManager'),
  //       props: true,
  //       meta: { title: '历史文档管理', icon: 'home' }
  //     }
  //   ]
  // },
  // // 小时报
  // {
  //   path: '/HoursReport',
  //   redirect: 'HoursReport/hours',
  //   name: 'HoursReport',
  //   component: Layout,
  //   children: [
  //
  //     {
  //       path: 'hours',
  //       name: 'hours',
  //       component: _import('onlineDesign/views/hoursReport/index'),
  //       props: true,
  //       meta: { title: '完井小时报填报', icon: 'home', noCache: true }
  //     },
  //     {
  //       path: 'hoursReportTemp',
  //       name: 'hoursReportTemp',
  //       component: _import('onlineDesign/views/hoursReport/hoursReportTemp'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '完井小时报模板下载', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'hoursReportTempManager',
  //       name: 'hoursReportTempManager',
  //       component: _import('onlineDesign/views/hoursReport/hoursReportTempManager'),
  //       hidden: true,
  //       props: true,
  //       meta: { title: '完井小时报模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'HoursReportView',
  //       name: 'HoursReportView',
  //       component: _import('onlineDesign/views/hoursReport/hoursDocView'),
  //       props: true,
  //       meta: { title: '完井小时报浏览', icon: 'home' }
  //     }
  //   ]
  // },
  // {
  //   path: '/oilCasing1',
  //   name: 'oilCasing1',
  //   redirect: 'oilCasing1/oils1',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'oils1',
  //       name: 'oils1',
  //       component: _import('onlineDesign/views/oilCasing1/index'),
  //       props: true,
  //       meta: { title: '油套管计划设计', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'oilCasingTemp',
  //       name: 'oilCasingTemp',
  //       component: _import('onlineDesign/views/oilCasing1/oilCasingTemp'),
  //       hidden: false,
  //       props: true,
  //       meta: { title: '油套管模板下载', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'oilCasingTempManager',
  //       name: 'oilCasingTempManager',
  //       component: _import('onlineDesign/views/oilCasing1/oilCasingTempManager'),
  //       hidden: false,
  //       props: true,
  //       meta: { title: '油套管模板管理', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'planfillingout',
  //       name: 'planfillingout',
  //       component: _import('onlineDesign/views/oilCasing1/planfillingout'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '油套管计划填报', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'planstatisticsout',
  //       name: 'planstatisticsout',
  //       component: _import('onlineDesign/views/oilCasing1/planstatisticsout'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '油套管计划统计', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'view',
  //       name: 'view',
  //       component: _import('onlineDesign/views/oilCasing1/view'),
  //       props: true,
  //       // hidden: true,
  //       meta: { title: '油套管计划浏览', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'manager',
  //       name: 'manager',
  //       component: _import('onlineDesign/views/oilCasing1/manager'),
  //       props: true,
  //       // hidden: true,
  //       meta: { title: '油套管计划管理', noCache: false, icon: 'home' }
  //     }
  //
  //   ]
  // },
  // // 生产进度
  // {
  //   path: '/prodSchedule',
  //   component: Layout,
  //   redirect: 'prodSchedule/scjddtgz',
  //   name: 'prodSchedule',
  //   children: [
  //     {
  //       path: 'scjddtgz',
  //       name: 'scjddtgz',
  //       component: _import('prodSchedule/views/scjddtgz'),
  //       props: true,
  //       meta: { title: '生产进度动态跟踪', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'djscrz',
  //       name: 'djscrz',
  //       component: _import('prodSchedule/views/djscrz'),
  //       props: true,
  //       meta: { title: '单井生产日志', noCache: false, icon: 'home' }
  //     },
  //     {
  //       path: 'djrz',
  //       name: 'djrz',
  //       component: _import('prodSchedule/views/djrz'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '单井日志', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'wjsxfx',
  //       name: 'wjsxfx',
  //       component: _import('prodSchedule/views/wjsxfx'),
  //       props: true,
  //       meta: { title: '完井时效分析', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'wjsxyxystj',
  //       name: 'wjsxyxystj',
  //       component: _import('prodSchedule/views/wjsxyxystj'),
  //       props: true,
  //       meta: { title: '完井时效影响因素统计', noCache: true, icon: 'home' }
  //     },
  //     {
  //       path: 'scjdlr',
  //       name: 'scjdlr',
  //       component: _import('prodSchedule/views/scjdlr'),
  //       props: true,
  //       hidden: true,
  //       meta: { title: '生产进度录入', noCache: true, icon: 'home' }
  //     }
  //   ]
  // },
  //
  // 胜软模块
  // {
  //   path: '/victorysoft',
  //   component: Layout,
  //   redirect: 'home',
  //   name: 'victorysoft',
  //   meta: { title: 'dashboard', noCache: true, type: 'index', icon: 'component' },
  //   children: [
  //
  //     {
  //       path: 'wellreport',
  //       component: SecondLayout,
  //       name: 'wellreport',
  //       meta: { title: 'wellreport', noCache: true, icon: 'component' },
  //       children: [{
  //         path: 'wellreport_01',
  //         component: _import('victorysoft/views/wellreport/wellreport_01'),
  //         name: 'wellreport_01',
  //         meta: { title: 'wellreport_01', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_02',
  //         component: _import('victorysoft/views/wellreport/wellreport_02'),
  //         name: 'wellreport_02',
  //         meta: { title: 'wellreport_02', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_03',
  //         component: _import('victorysoft/views/wellreport/wellreport_03'),
  //         name: 'wellreport_03',
  //         meta: { title: 'wellreport_03', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_04',
  //         component: _import('victorysoft/views/wellreport/wellreport_04'),
  //         name: 'wellreport_04',
  //         meta: { title: 'wellreport_04', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_05',
  //         component: _import('victorysoft/views/wellreport/wellreport_05'),
  //         name: 'wellreport_05',
  //         meta: { title: 'wellreport_05', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_06',
  //         component: _import('victorysoft/views/wellreport/wellreport_06'),
  //         name: 'wellreport_06',
  //         meta: { title: 'wellreport_06', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_07',
  //         component: _import('victorysoft/views/wellreport/wellreport_07'),
  //         name: 'wellreport_07',
  //         meta: { title: 'wellreport_07', noCache: true, icon: 'yjdjpz' }
  //       }, {
  //         path: 'wellreport_m_01',
  //         component: _import('victorysoft/views/wellreport/wellreport_m_01'),
  //         name: 'wellreport_m_01',
  //         meta: { title: 'wellreport_m_01', noCache: true, icon: 'yjdjpz' }
  //       }]
  //     },
  //
  //     {
  //       path: 'mms',
  //       component: SecondLayout,
  //       name: 'mms',
  //       meta: { title: 'mms', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_mms_gckcqkcx', component: _import('victorysoft/views/mms/yyxtjc_mms_gckcqkcx'), name: 'yyxtjc_mms_gckcqkcx', meta: { title: 'yyxtjc_mms_gckcqkcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_mms_djdbwzcx', component: _import('victorysoft/views/mms/yyxtjc_mms_djdbwzcx'), name: 'yyxtjc_mms_djdbwzcx', meta: { title: 'yyxtjc_mms_djdbwzcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_mms_dhpccx', component: _import('victorysoft/views/mms/yyxtjc_mms_dhpccx'), name: 'yyxtjc_mms_dhpccx', meta: { title: 'yyxtjc_mms_dhpccx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'dtyjkh',
  //       component: SecondLayout,
  //       name: 'dtyjkh',
  //       meta: { title: 'dtyjkh', noCache: true, icon: 'component' },
  //       children: [
  //         {
  //           path: 'dtyjkh_dwkh',
  //           component: SecondLayout,
  //           name: 'dtyjkh_dwkh',
  //           meta: { title: 'dtyjkh_dwkh', noCache: true, icon: 'setting' },
  //           children: [
  //             { path: 'ywgl_dtyjkh_dwkh_ndkhjg', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwkh_ndkhjg'), name: 'ywgl_dtyjkh_dwkh_ndkhjg', meta: { title: 'ywgl_dtyjkh_dwkh_ndkhjg', noCache: true, icon: 'yjdjpz' }},
  //             { path: 'ywgl_dtyjkh_dwkh_lskhjg', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwkh_lskhjg'), name: 'ywgl_dtyjkh_dwkh_lskhjg', meta: { title: 'ywgl_dtyjkh_dwkh_lskhjg', noCache: true, icon: 'yjdjpz' }}
  //           ]
  //         }, {
  //           path: 'dtyjkh_dwdtpm',
  //           component: SecondLayout,
  //           name: 'dtyjkh_dwdtpm',
  //           meta: { title: 'dtyjkh_dwdtpm', noCache: true, icon: 'setting' },
  //           children: [
  //             { path: 'ywgl_dtyjkh_dwdtpm_dxpm', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwdtpm_dxpm'), name: 'ywgl_dtyjkh_dwdtpm_dxpm', meta: { title: 'ywgl_dtyjkh_dwdtpm_dxpm', noCache: true, icon: 'yjdjpz' }},
  //             { path: 'ywgl_dtyjkh_dwdtpm_djjxtjypm', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwdtpm_djjxtjypm'), name: 'ywgl_dtyjkh_dwdtpm_djjxtjypm', meta: { title: 'ywgl_dtyjkh_dwdtpm_djjxtjypm', noCache: true, icon: 'yjdjpz' }}
  //           ]
  //         }, {
  //           path: 'dtyjkh_dwcx',
  //           component: SecondLayout,
  //           name: 'dtyjkh_dwcx',
  //           meta: { title: 'dtyjkh_dwcx', noCache: true, icon: 'setting' },
  //           children: [
  //             { path: 'ywgl_dtyjkh_dwcx_dwjcxx', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwcx_dwjcxx'), name: 'ywgl_dtyjkh_dwcx_dwjcxx', meta: { title: 'ywgl_dtyjkh_dwcx_dwjcxx', noCache: true, icon: 'yjdjpz' }},
  //             { path: 'ywgl_dtyjkh_dwcx_dwndgzl', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwcx_dwndgzl'), name: 'ywgl_dtyjkh_dwcx_dwndgzl', meta: { title: 'ywgl_dtyjkh_dwcx_dwndgzl', noCache: true, icon: 'yjdjpz' }},
  //             { path: 'ywgl_dtyjkh_dwcx_ycczls', component: _import('victorysoft/views/dtyjkh/ywgl_dtyjkh_dwcx_ycczls'), name: 'ywgl_dtyjkh_dwcx_ycczls', meta: { title: 'ywgl_dtyjkh_dwcx_ycczls', noCache: true, icon: 'yjdjpz' }}
  //           ]
  //         }
  //       ]
  //     },
  //
  //     {
  //       path: 'hsse',
  //       component: SecondLayout,
  //       name: 'hsse',
  //       meta: { title: 'hsse', noCache: true, icon: 'component' },
  //       children: [
  //         {
  //           path: 'ywgl_hsse_txwjgl',
  //           component: SecondLayout,
  //           name: 'ywgl_hsse_txwjgl',
  //           meta: { title: 'ywgl_hsse_txwjgl', noCache: true, icon: 'setting' },
  //           children: [
  //             { path: 'ywgl_hsse_flfg_index', component: _import('victorysoft/views/hsse/ywgl_hsse_flfg_index'), name: 'ywgl_hsse_flfg_index', meta: { title: 'ywgl_hsse_flfg_index', noCache: true, icon: 'yjdjpz' }},
  //             { path: 'ywgl_hsse_bzgf_index', component: _import('victorysoft/views/hsse/ywgl_hsse_bzgf_index'), name: 'ywgl_hsse_bzgf_index', meta: { title: 'ywgl_hsse_bzgf_index', noCache: true, icon: 'yjdjpz' }}
  //           ]
  //         },
  //         { path: 'ywgl_hsse_fxgl', component: _import('victorysoft/views/hsse/ywgl_hsse_fxgl'), name: 'ywgl_hsse_fxgl', meta: { title: 'ywgl_hsse_fxgl', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'ywgl_hsse_fxgl_cx', component: _import('victorysoft/views/hsse/ywgl_hsse_fxgl_cx'), name: 'ywgl_hsse_fxgl_cx', meta: { title: 'ywgl_hsse_fxgl_cx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'ywgl_hsse_zyzdsgl', component: _import('victorysoft/views/hsse/ywgl_hsse_zyzdsgl'), name: 'ywgl_hsse_zyzdsgl', meta: { title: 'ywgl_hsse_zyzdsgl', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'zjglxt',
  //       component: SecondLayout,
  //       name: 'zjglxt',
  //       meta: { title: 'zjglxt', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_zjglxt_zggsfbtj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zggsfbtj'), name: 'yyxtjc_zjglxt_zggsfbtj', meta: { title: 'yyxtjc_zjglxt_zggsfbtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zgjwfbtj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zgjwfbtj'), name: 'yyxtjc_zjglxt_zgjwfbtj', meta: { title: 'yyxtjc_zjglxt_zgjwfbtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zgztcx', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zgztcx'), name: 'yyxtjc_zjglxt_zgztcx', meta: { title: 'yyxtjc_zjglxt_zgztcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zjgsfbtj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zjgsfbtj'), name: 'yyxtjc_zjglxt_zjgsfbtj', meta: { title: 'yyxtjc_zjglxt_zjgsfbtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zjjwfbtj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zjjwfbtj'), name: 'yyxtjc_zjglxt_zjjwfbtj', meta: { title: 'yyxtjc_zjglxt_zjjwfbtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zjsyjlcx', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zjsyjlcx'), name: 'yyxtjc_zjglxt_zjsyjlcx', meta: { title: 'yyxtjc_zjglxt_zjsyjlcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_zjtynftj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_zjtynftj'), name: 'yyxtjc_zjglxt_zjtynftj', meta: { title: 'yyxtjc_zjglxt_zjtynftj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_gzqktjcx', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_gzqktjcx'), name: 'yyxtjc_zjglxt_gzqktjcx', meta: { title: 'yyxtjc_zjglxt_gzqktjcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_zjglxt_axybtj', component: _import('victorysoft/views/zjglxt/yyxtjc_zjglxt_axybtj'), name: 'yyxtjc_zjglxt_axybtj', meta: { title: 'yyxtjc_zjglxt_axybtj', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'jkglxt',
  //       component: SecondLayout,
  //       name: 'jkglxt',
  //       meta: { title: 'jkglxt', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_jkglxt_jkzzztcx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_jkzzztcx'), name: 'yyxtjc_jkglxt_jkzzztcx', meta: { title: 'yyxtjc_jkglxt_jkzzztcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_jxqkcx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_jxqkcx'), name: 'yyxtjc_jkglxt_jxqkcx', meta: { title: 'yyxtjc_jkglxt_jxqkcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_jxdqtx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_jxdqtx'), name: 'yyxtjc_jkglxt_jxdqtx', meta: { title: 'yyxtjc_jkglxt_jxdqtx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_djdacx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_djdacx'), name: 'yyxtjc_jkglxt_djdacx', meta: { title: 'yyxtjc_jkglxt_djdacx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_tybfglcx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_tybfglcx'), name: 'yyxtjc_jkglxt_tybfglcx', meta: { title: 'yyxtjc_jkglxt_tybfglcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_jkjdcx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_jkjdcx'), name: 'yyxtjc_jkglxt_jkjdcx', meta: { title: 'yyxtjc_jkglxt_jkjdcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_lssjtjfx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_lssjtjfx'), name: 'yyxtjc_jkglxt_lssjtjfx', meta: { title: 'yyxtjc_jkglxt_lssjtjfx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jkglxt_tzzysbcx', component: _import('victorysoft/views/jkglxt/yyxtjc_jkglxt_tzzysbcx'), name: 'yyxtjc_jkglxt_tzzysbcx', meta: { title: 'yyxtjc_jkglxt_tzzysbcx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'jdglpt',
  //       component: SecondLayout,
  //       name: 'jdglpt',
  //       meta: { title: 'jdglpt', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_jdglpt_gcgzltj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_gcgzltj'), name: 'yyxtjc_jdglpt_gcgzltj', meta: { title: 'yyxtjc_jdglpt_gcgzltj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_gcfztj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_gcfztj'), name: 'yyxtjc_jdglpt_gcfztj', meta: { title: 'yyxtjc_jdglpt_gcfztj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_dzkctj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_dzkctj'), name: 'yyxtjc_jdglpt_dzkctj', meta: { title: 'yyxtjc_jdglpt_dzkctj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_qxtj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_qxtj'), name: 'yyxtjc_jdglpt_qxtj', meta: { title: 'yyxtjc_jdglpt_qxtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_rjctj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_rjctj'), name: 'yyxtjc_jdglpt_rjctj', meta: { title: 'yyxtjc_jdglpt_rjctj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_djyxcxzjgc', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_djyxcxzjgc'), name: 'yyxtjc_jdglpt_djyxcxzjgc', meta: { title: 'yyxtjc_jdglpt_djyxcxzjgc', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_cbsyxtj', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_cbsyxtj'), name: 'yyxtjc_jdglpt_cbsyxtj', meta: { title: 'yyxtjc_jdglpt_cbsyxtj', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_dsfcx', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_dsfcx'), name: 'yyxtjc_jdglpt_dsfcx', meta: { title: 'yyxtjc_jdglpt_dsfcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_zjdzrb', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_zjdzrb'), name: 'yyxtjc_jdglpt_zjdzrb', meta: { title: 'yyxtjc_jdglpt_zjdzrb', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_djrb', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_djrb'), name: 'yyxtjc_jdglpt_djrb', meta: { title: 'yyxtjc_jdglpt_djrb', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_jdglpt_zdjscjb', component: _import('victorysoft/views/jdglpt/yyxtjc_jdglpt_zdjscjb'), name: 'yyxtjc_jdglpt_zdjscjb', meta: { title: 'yyxtjc_jdglpt_zdjscjb', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'sygcfagl',
  //       component: SecondLayout,
  //       name: 'sygcfagl',
  //       meta: { title: 'sygcfagl', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_sygcfagl_djzwjsjcx', component: _import('victorysoft/views/sygcfagl/yyxtjc_sygcfagl_djzwjsjcx'), name: 'yyxtjc_sygcfagl_djzwjsjcx', meta: { title: 'yyxtjc_sygcfagl_djzwjsjcx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'fgsgggl',
  //       component: SecondLayout,
  //       name: 'fgsgggl',
  //       meta: { title: 'fgsgggl', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_fgsgggl_ggkckshcx', component: _import('victorysoft/views/fgsgggl/yyxtjc_fgsgggl_ggkckshcx'), name: 'yyxtjc_fgsgggl_ggkckshcx', meta: { title: 'yyxtjc_fgsgggl_ggkckshcx', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'yyxtjc_fgsgggl_ggkcbhqxkshcx', component: _import('victorysoft/views/fgsgggl/yyxtjc_fgsgggl_ggkcbhqxkshcx'), name: 'yyxtjc_fgsgggl_ggkcbhqxkshcx', meta: { title: 'yyxtjc_fgsgggl_ggkcbhqxkshcx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //     {
  //       path: 'jkpx',
  //       component: SecondLayout,
  //       name: 'jkpx',
  //       meta: { title: 'jkpx', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'jkpx', component: _import('victorysoft/views/jkpx/jkpx'), name: 'jkpx', meta: { title: 'jkpx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //     {
  //       path: 'cyqjkgl',
  //       component: SecondLayout,
  //       name: 'cyqjkgl',
  //       meta: { title: 'cyqjkgl', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_cyqjkgl_cysxstkccx', component: _import('victorysoft/views/cyqjkgl/yyxtjc_cyqjkgl_cysxstkccx'), name: 'yyxtjc_cyqjkgl_cysxstkccx', meta: { title: 'yyxtjc_cyqjkgl_cysxstkccx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'zwjjkzztk',
  //       component: SecondLayout,
  //       name: 'zwjjkzztk',
  //       meta: { title: 'zwjjkzztk', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_zwjjkzztk_jkzztcx', component: _import('victorysoft/views/zwjjkzztk/yyxtjc_zwjjkzztk_jkzztcx'), name: 'yyxtjc_zwjjkzztk_jkzztcx', meta: { title: 'yyxtjc_zwjjkzztk_jkzztcx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'wcxmzxpd',
  //       component: SecondLayout,
  //       name: 'wcxmzxpd',
  //       meta: { title: 'wcxmzxpd', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'yyxtjc_wcxmzxpd_pdjgcx', component: _import('victorysoft/views/wcxmzxpd/yyxtjc_wcxmzxpd_pdjgcx'), name: 'yyxtjc_wcxmzxpd_pdjgcx', meta: { title: 'yyxtjc_wcxmzxpd_pdjgcx', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //     {
  //       path: 'video',
  //       component: SecondLayout,
  //       name: 'video',
  //       meta: { title: 'video', noCache: true, icon: 'component' },
  //       children: [
  //         { path: 'region_index', component: _import('victorysoft/views/video/region_index'), name: 'region_index', meta: { title: 'region_index', noCache: true, icon: 'yjdjpz' }},
  //         { path: 'video_index', component: _import('victorysoft/views/video/video_index'), name: 'video_index', meta: { title: 'video_index', noCache: true, icon: 'yjdjpz' }}
  //       ]
  //     },
  //
  //     {
  //       path: 'iframe',
  //       component: _import('victorysoft/views/iframe/index'),
  //       name: 'iframe',
  //       meta: { title: '其它界面集成', noCache: true, icon: 'component' }
  //     },
  //
  //     {
  //       path:'OilandGasWellHead',
  //       component:_import('victorysoft/views/PipeSystem/OilandGasWellhead'),
  //       name:'OilandGasWellHead',
  //       meta:{title:'OilandGasWellHead',noCache:true,icon:'component'}
  //     }
  //   ]
  // }

]

export default new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  { path: '*', redirect: '/404', hidden: true }
]
