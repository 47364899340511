var CryptoJS = require('crypto-js')

const CryptoUtil = {
    key: 'a1b2c3d4e5fghij6',
    // key: 'abcdefghijklmnopq1234',
    iv: '0123456789abcdef',
    encodeAes: function(content) {
        let key1 = ''
        key1 = CryptoJS.enc.Utf8.parse(this.key)
        let str = CryptoJS.enc.Utf8.parse(content)
        str = CryptoJS.AES.encrypt(str, key1, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
        return str.toString()
    },
    decodeAes: function(content) {
        const key = CryptoJS.enc.Utf8.parse(this.key)
        const decrypt = CryptoJS.AES.decrypt(content, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
        return CryptoJS.enc.Utf8.stringify(decrypt).toString()
    }
}
module.exports = CryptoUtil