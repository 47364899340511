import request from '@/framework/utils/request'
import axios from 'axios'

export default {
  loadUnitConfig() {
    return request({
      url: '/c/admin/api/config/unit',
      method: 'get'
    })
  },
  loadDimensionConfig() {
    return request({
      url: '/c/admin/api/config/dimension',
      method: 'get'
    })
  },
  loadFieldConfig() {
    return request({
      url: '/c/admin/api/config/field',
      method: 'get'
    })
  },
  loadPageConfig(pageId) {
    return request({
      url: '/c/admin/api/config/page/' + pageId,
      method: 'get'
    })
  },
  loadParamsConfig(pageId) {
    return request({
      url: '/c/admin/api/config/page/' + pageId,
      method: 'get'
    })
  },
  loadGlobalConfig() {
    return axios.all([this.loadFieldConfig(), this.loadDimensionConfig(), this.loadUnitConfig()])
  },
  loadLimitsConfig() {
    return request({
      url: '/c/admin/api/config/limits',
      method: 'get'
    })
  },
  savePageConfig(pageId, pageConfigData) {
    const data = {
      context: JSON.stringify(pageConfigData)
    }
    return request({
      url: '/c/admin/api/config/page/' + pageId,
      method: 'put',
      data: data
    })
  },
  saveParamsConfig(pageId, pageConfigData) {
    const data = {
      // userId: 'system',
      context: JSON.stringify(pageConfigData)
    }
    return request({
      url: '/c/admin/api/config/page/' + pageId,
      method: 'put',
      data: data
    })
  },
  saveDefineDimensionConfig(defineDimension) {
    return request({
      url: '/c/admin/api/config/dimension',
      method: 'put',
      data: defineDimension
    })
  }
}

