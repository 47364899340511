<template>
    <div id="global-uploader">
        <!-- 上传 -->
        <uploader
                ref="uploader"
                :file-status-text="statusText"
                :options="options"
                :autoStart="true"
                @file-added="onFileAdded"
                @file-success="onFileSuccess"
                @file-progress="onFileProgress"
                @file-error="onFileError"   
                class="uploader-app">
            <uploader-unsupport></uploader-unsupport>
            <uploader-btn id="global-uploader-btn" :attrs="attrs" ref="uploadBtn">选择文件</uploader-btn>
            <uploader-list v-show="panelShow">
                <div class="file-panel" slot-scope="props" :class="{'collapse': collapse}">
                    <div class="file-title">
                        <h2>文件列表</h2>
                        <div class="operate">
                           <el-button @click="fileListShow" type="text" :title="collapse? '展开':'折叠' ">
                              <i :class="collapse? 'el-icon-full-screen': 'el-icon-minus'"></i>
                            </el-button>
                            <el-button @click="close" type="text" title="关闭">
                              <i class="el-icon-close"></i>
                            </el-button>
                        </div>
                    </div>

                    <ul class="file-list">
                        <li v-for="file in props.fileList" :key="file.id">
                            <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true"></uploader-file>
                        </li>
                        <div class="no-file" v-if="!props.fileList.length"><i class="iconfont icon-empty-file"></i> 暂无待上传文件</div>
                    </ul>
                </div>
            </uploader-list>

        </uploader>

    </div>
</template>

<script>
    /**
     *   全局上传插件
     *   调用方法：Bus.$emit('openUploader', {}) 打开文件选择框，参数为需要传递的额外参数
     *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
     *            Bus.$on('fileSuccess', fn); 文件上传成功的回调
     */

import { ACCEPT_CONFIG } from '../assets/js/config'
import Bus from '../assets/js/bus'
import SparkMD5 from 'spark-md5'
import $ from 'jquery'
import { fileUpload } from '../apis/weboffice'
export default {
  props: {
    
  },
      data() {
        return {
          evenData: [],
          parentId: 'root',
          jobType: '',
          data: {},
          pm: undefined,
          isMoldel: '',
          isp: true,
          name: '',
          method: 'POST',
          options: {
            target: fileUpload(),
            withCredentials: true,
            progressCallbacksInterval: 100,
            chunkSize: '1073741824',
            testMethod: 'POST',
            headers: {
              Authorization: this.$store.getters.token
            },
            fileParameterName: 'file',
            maxChunkRetries: 3,
            simultaneousUploads: 100,
            checkChunkUploadedByResponse: function(chunk, message) {
              const objMessage = JSON.parse(message)
              if (objMessage.skipUpload) {
                return true
              }
              return (objMessage.uploaded || []).indexOf(chunk.offset + 1) >= 0
            },
            testChunks: true // 是否开启服务器分片校验
          },
          attrs: {
            accept: ACCEPT_CONFIG.getAll()
          },
          statusText: {
            success: '成功了',
            error: '出错了',
            uploading: '上传中',
            paused: '暂停中',
            waiting: '等待中'
          },
          panelShow: false, // 选择文件后，展示上传panel
          collapse: false
        }
      },
      mounted() {
        Bus.$on('openUploader', query => {
          this.parentId = query.Id
          this.name = query.name
          this.jobType = query.jobType
          if (this.$refs.uploadBtn) {
            $('#global-uploader-btn').click()
          }
        })
  },
      computed: {
        // Uploader实例
        uploader() {
          return this.$refs.uploader.uploader
        }
      },
      methods: {
        getUpload() {
          return fileUpload()
        },
        onFileAdded(file) {
          // debugger
          var type = file.name.substring(file.name.lastIndexOf('.') + 1)
          if (type !== 'xlsx' && type !== 'docx') {
            this.$message.error('上传文件格式只能是 xlsx格式或者docx格式')
            this.statusSet(file.id, 'failed')
            return false
          }
          this.OpenEven(file)
          this.panelShow = true
          Bus.$emit('fileAdded')
        },
        GetQ() {
          return this.pm
        },
        OpenEven(arr) {
          // debugger
          var pm = new FormData()
          this.$set(pm, 'jobType', this.jobType)
          this.$set(pm, 'parentId', this.parentId)
          this.$set(arr, 'pm', pm)
          this.computeMD5(arr)
        },
        onFileProgress(rootFile, file, chunk) {
          //   alert(file.progress())
          console.log(`上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${chunk.endByte / 1024 / 1024}`)
        },
        onFileSuccess(rootFile, file, response, chunk) {
          var arr = JSON.parse(response)
          // console.log(response.code)
          // alert(arr.code)
          if (arr.code === 1) {
            this.$message({ message: arr.msg, type: 'error' })
            this.statusSet(file.id, 'failed')
          } else {
            Bus.$emit('fileSuccess')
            Bus.$emit('flushFileList', { isp: this.isp })
            // window.console.log(response)
          }
        },
        onFileError(rootFile, file, response, chunk) {
          window.console.log(chunk)
          this.$message({
            message: response,
            type: 'error'
          })
        },

        /**
             * 计算md5，实现断点续传及秒传
             * @param file
             */
        computeMD5(file) {
          file.pause()
          this.statusSet(file.id, 'md5')
          const fileReader = new FileReader()
          const time = new Date().getTime()
          const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          let currentChunk = 0
          const chunkSize = 1024 * 1024 * 1000
          const chunks = Math.ceil(file.size / chunkSize)
          const spark = new SparkMD5.ArrayBuffer()

          // 文件状态设为"计算MD5"
    
          loadNext()

          fileReader.onload = e => {
            spark.append(e.target.result)

            if (currentChunk < chunks) {
              currentChunk++
              loadNext()

              // 实时展示MD5的计算进度
              this.$nextTick(() => {
                // alert((currentChunk / chunks) * 100)
                $(`.myStatus_${file.id}`).text('校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%')
              })
            } else {
              const md5 = spark.end()
              this.computeMD5Success(md5, file)
              window.console.log(`MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${new Date().getTime() - time} ms`)
              this.statusRemove(file.id)
              file.resume()
            }
          }

          fileReader.onerror = function() {
            this.error(`文件${file.name}读取出错，请检查该文件`)
            file.cancel()
          }

          function loadNext() {
            const start = currentChunk * chunkSize
            const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize

            fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
          }
        },
        computeMD5Success(md5, file) {
          Object.assign(this.uploader.opts, {
            query: {
              ...file.pm
            }
          })
          file.uniqueIdentifier = md5
        },

        fileListShow() {
          const $list = $('#global-uploader .file-list')

          if ($list.is(':visible')) {
            $list.slideUp()
            this.collapse = true
          } else {
            $list.slideDown()
            this.collapse = false
          }
        },
        close() {
          this.uploader.cancel()

          this.panelShow = false
        },

        /**
             * 新增的自定义的状态: 'md5'、'transcoding'、'failed'
             * @param id
             * @param status
             */
        statusSet(id, status) {
          const statusMap = {
            md5: {
              text: '校验MD5',
              bgc: '#fff'
            },
            merging: {
              text: '合并中',
              bgc: '#e2eeff'
            },
            transcoding: {
              text: '转码中',
              bgc: '#e2eeff'
            },
            failed: {
              text: '上传失败',
              bgc: '#e2eeff'
            },
            uploading: {
              text: '上传中',
              bgc: '#e2eeff'
            }
          }

          this.$nextTick(() => {
            $(`<p class="myStatus_${id}"></p>`).appendTo(`.file_${id} .uploader-file-status`).css({
              'position': 'absolute',
              'top': '0',
              'left': '0',
              'right': '0',
              'bottom': '0',
              'zIndex': '1',
              'backgroundColor': statusMap[status].bgc
            }).text(statusMap[status].text)
          })
        },
        statusRemove(id) {
          this.$nextTick(() => {
            $(`.myStatus_${id}`).remove()
          })
        },

        error(msg) {
          this.$notify({
            title: '错误',
            message: msg,
            type: 'error',
            duration: 2000
          })
        }
      },
      watch: {},
      destroyed() {
        Bus.$off('openUploader')
  },
      components: {}
    }
</script>

<style scoped lang="scss">
    #global-uploader {
        position: fixed;
        z-index:200;
        right: 15px;
        bottom: 15px;

        .uploader-app {
            width: 520px;
        }

        .file-panel {
              background-color: #fff;
              border: 1px solid #e2e2e2;
              border-radius: 7px 7px 0 0;
              box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            .file-title {
               display: flex;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                font-size: 14px;
                border-bottom: 1px solid #ddd;
                .operate {
                    flex: 1;
                    text-align: right;
                }
            }

            .file-list {
                position: relative;
                height: 240px;
                overflow-x: hidden;
                overflow-y: auto;
                background-color: #fff;

                > li {
                    background-color: #fff;
                }
            }

            &.collapse {
                .file-title {
                    background-color: #E7ECF2;
                }
            }
        }

        .no-file {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
        }

         .uploader-file-icon {
            &:before {
              content: '' !important;
            }
            &[icon=image] {
               background-image: url('../assets/file_icons.png');
                background-position: -596px -306px !important;
            }
            &[icon=video] {
                background-image: url('../assets/file_icons.png');
                 background-position: -596px -1630px !important;
            }
            &[icon=document] {
                background-image: url('../assets/file_icons.png');
                 background-position: -596px -1630px !important;
            }
        }

        .uploader-file-actions > span {
            margin-right: 6px;
        }
    }

    /* 隐藏上传按钮 */
    #global-uploader-btn {
        position: absolute;
        clip: rect(0, 0, 0, 0);
    }
   
</style>