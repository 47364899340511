<template>
  <div>
    <el-menu class="navbar" mode="horizontal">
      <img class="homeLogo" @click="goBackHome"  v-if="css === 'shengli'" src="../../../assets/logoDark.png">
      <img class="homeLogo" @click="goBackHome"  v-else src="../../../assets/logo.png">
      <!-- <div class="breadcrumb-container fontColor">{{this.title}}</div> -->
      <!-- <WellDate class="breadcrumb-container"></WellDate> -->
      <!-- <breadcrumb></breadcrumb> -->
      <div class="right-menu" v-if="$store.state.user.user.id !== '402067275265617920'">
        <!-- <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <span class="el-dropdown-link">{{$t('navbar.unit')}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><span @click="handleChangeUnit('M')">M</span></el-dropdown-item>
          <el-dropdown-item><span @click="handleChangeUnit('KM')">KM</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>-->
        <!-- 主题切换 -->
        <!-- <span @click="showEditConfig" class="goBack" v-show="false">
          <a style="font-size: 14px;margin-right: 8px;" class="el-dropdown-link fontColor">修改配置</a>
        </span> -->
        <span @click="goBack" class="goBack" v-show="hasGoBackBtn" v-if="$hasPermission('sysGoBackHome')">
          <span style="" class="el-dropdown-link fontColor navigation">导航页</span>
        </span>
        <span @click="downloadUserHandbook" class="downloadUserHandbook" v-if="$hasPermission('sysUserHandbook')">
          <a style="font-size: 14px;margin-right: 8px;" class="el-dropdown-link fontColor">{{$t('btn.userHandbook')}}</a>
        </span>
        <!-- <span @click="clickFeedback" class="feedback" v-if="$hasPermission('sysFeedback')">
          <svg-icon icon-class="feedback" class="feedback"></svg-icon>
          <a style="font-size: 14px;margin-right: 8px;" class="el-dropdown-link fontColor">{{$t('btn.feedback')}}</a>
        </span> -->
        <!-- <div :class="{'isClick': isClickThem}" style="display: inline-block;">
        <el-dropdown
          class="avatar-container right-menu-item"
          trigger="click"
          @command="handleChangeCSS"
          @visible-change="ischeckClickThem"
          placement="bottom-start"
        >
          <span class="el-dropdown-link fontColor">
            {{$t('navbar.theme')}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
            <el-dropdown-menu slot="dropdown" :class="themeStyle"  placement="bottom-start">
              <el-dropdown-item command="simple">主题风格 精简</el-dropdown-item>
              <el-dropdown-item command="classic">主题风格 经典</el-dropdown-item>
              <el-dropdown-item command="black">主题风格 简洁</el-dropdown-item>
              <el-dropdown-item command="blue">主题风格 科技蓝</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :class="{'isClick': isClickLan}" style="display: inline-block;">
          <el-dropdown
            class="avatar-container right-menu-item"
            trigger="click"
            @command="handleChangeLanguge"
            @visible-change="ischeckClicklan"
            placement="bottom-start"
          >
            <span class="el-dropdown-link fontColor">
              {{$t('navbar.language')}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" :class="themeStyle">
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </div>
      <div :class="{'isClick': isClickDimension}" style="display: inline-block;">
        <el-dropdown class="avatar-container right-menu-item" trigger="click" @command="handleChangeUnit"  @visible-change="ischeckClickDimension"  placement="bottom-start">
          <span class="el-dropdown-link fontColor">
            {{$t('navbar.dimension')}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" :class="themeStyle">
            <el-dropdown-item command="ms">公制</el-dropdown-item>
            <el-dropdown-item command="as">英制</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
        <!-- <error-log class="errLog-container right-menu-item"></error-log> -->
        <!-- <message ref="message"></message> -->
        <div style="display: inline-block;">
          <!-- <div class="badge" @click="showMessage">
          <el-badge :value="messageLength" :max="99" class="item" :hidden="messageLength === 0">
          </el-badge> -->
          <!-- <div class="badge">
            <img class="user-avatar" src="../../../assets/people.png">
          </div> -->
          <el-dropdown class="avatar-container right-menu-item userLogout" trigger="click" @visible-change="ischeckClickLogout">
            <div class="avatar-wrapper logoutImg">
              <img class="user-avatar" src="../../../assets/people.png">
              <span class="fontSize fontColor">{{name}}</span>
              <i class="el-icon-arrow-down el-icon--right fontColor"></i>
            </div>
            <el-dropdown-menu slot="dropdown" :class="themeStyle">
              <!-- <el-dropdown-item>
              <span @click="goBack" style="display:block;">{{$t('btn.backPortal')}}</span>
            </el-dropdown-item> -->
              <el-dropdown-item v-if="$hasPermission('sysChangeLang')">
                <div @mouseenter="(e) => {menuHover('language', true, e)}" @mouseleave="(e) => {menuHover('language', false, e)}">{{$t('navbar.language')}}</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$hasPermission('sysChangeTheme')">
                <div @mouseenter="(e) => {menuHover('theme', true, e)}" @mouseleave="(e) => {menuHover('theme', false, e)}">{{$t('navbar.theme')}}</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="$hasPermission('sysChangeDim')">
                <div @mouseenter="(e) => {menuHover('dimension', true, e)}" @mouseleave="(e) => {menuHover('dimension', false, e)}">{{$t('navbar.dimension')}}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="clickOwnspace" style="display:block;">{{$t('well.ownspace')}}</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="logout" style="display:block;">{{$t('navbar.logOut')}}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="childMenu" id="dropDownChildMenu" @mouseenter="childMenuHover(true)" @mouseleave="childMenuHover(false)">
            <ul>
              <li v-for="child in childMenu" :key="child.name" @click="child.action">{{child.name}}</li>
            </ul>
          </div>
        </div>
      </div>
      <iframe style="position: fixed;top: -100px;" width="10" height="10" :src="iframeSrc"></iframe>
    </el-menu>
    <el-dialog :close-on-click-modal="false" :title="$t('btn.feedback')" :visible.sync="feedbackDialogVisible" append-to-body v-dialogMove @open="feedbackDialogOpen" width="30%">
      <el-form :model="feedbackForm" :rules="feedbackRules" ref="feedbackForm" @submit.native.prevent>
        <el-form-item prop="menu">
          <el-cascader :placeholder="$t('btn.pleaseChooseMsg', { msg: $t('sys.moduleName') })" style="width: 100%" clearable filterable size="small" v-model="feedbackForm.moduleName" :options="menutree" :props="menuProps"></el-cascader>
        </el-form-item>
        <el-form-item prop="feedbackValue" class="feedbackFormItem">
          <el-input @keyup.enter.stop type="textarea" :autosize="{ minRows: 4, maxRows: 8}" :placeholder="$t('msg.feedbackPlaceholder')" v-model="feedbackForm.feedbackValue">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="feedbackDialogVisible = false">{{$t('btn.cancel')}}</el-button>
        <el-button type="primary" :loading="feedbackSaveBtnLoading" @click="clickFeedbackSureBtn">{{$t('btn.sure')}}</el-button>
      </span>
    </el-dialog>
    <EditConfig ref="editConfig"></EditConfig>
  </div>
</template>

<script>
import { curd } from '@/framework/api/index.js'
import { mapGetters } from 'vuex'
// import Breadcrumb from '@/framework/components/Breadcrumb'
// import message from "@/framework/components/Message"
import RocketClient from '@/framework/libs/im/RocketChatClient'
import EditConfig from './EditConfig'
export default {
  data () {
    return {
      title: process.env.VUE_APP_TITLE || '',
      comeInChildMenu: false,
      feedbackDialogVisible: false,
      feedbackSaveBtnLoading: false,
      feedbackForm: {
        feedbackValue: '',
        moduleName: []
      },
      feedbackRules: {
        feedbackValue: [
          { required: true, message: this.$t('msg.feedbackRule'), trigger: 'blur' },
          { min: 0, max: 200, message: this.$t('msg.feedbackRule'), trigger: 'blur' }
        ]
      },
      menuProps: {
        value: 'title',
        expandTrigger: 'hover',
        label: 'title',
        children: 'children'
      },
      menutree: window._USERMENUTREE,
      childMenu: [],
      childMenuOptions: {
        language: [
          { name: '简体中文', action: () => this.handleChangeLanguge('zh') },
          { name: 'English', action: () => this.handleChangeLanguge('en') }
        ],
        theme: [
          { name: '主题风格 精简', action: () => this.handleChangeCSS('simple') },
          { name: '主题风格 经典', action: () => this.handleChangeCSS('classic') },
          { name: '主题风格 科技蓝', action: () => this.handleChangeCSS('blue') },
          { name: '主题风格 胜利', action: () => this.handleChangeCSS('shengli') }
        ],
        dimension: [
          { name: '公制', action: () => this.handleChangeUnit('ms') },
          { name: '英制', action: () => this.handleChangeUnit('as') }
        ]
      },
      messageLength: 0,
      rocketClient: null,
      isClickThem: false,
      isClickLan: false,
      isClickDimension: false,
      isClickLogout: false,
      iframeSrc: '',
      goHomeButtonVisible: process.env.VUE_APP_GO_HOME_BUTTON_VISIBLE
    }
  },
  components: {
    // Breadcrumb,
    // message,
    EditConfig
  },
  props: {
    hasGoBackBtn: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.getMessageTotal()
    this.rocketClient = RocketClient.instance()
    this.rocketClient.register(
      RocketClient.RequestEvent.privateChat,
      this.rocketClientCallBack
    )
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'css']),
    themeStyle () {
      if (this.css === 'simple') {
        return 'simple'
      } else if (this.css === 'classic') {
        return 'classic'
      } else if (this.css === 'black') {
        return 'black'
      } else if (this.css === 'shengli') {
        return 'shengli'
      } else {
        return 'blue'
      }
    }

  },
  watch: {
  },
  beforeDestroy () {
    this.rocketClient.unRegister(
      RocketClient.RequestEvent.privateChat,
      this.rocketClientCallBack
    )
  },
  methods: {
    menuHover (name, active, e) {
      if (window.timer) {
        clearTimeout(window.timer)
      }
      window.e = e
      this.childMenu = this.childMenuOptions[name]
      // console.log(this.childMenu);
      if (active) {
        const position = e.target.parentElement.getBoundingClientRect()
        document.getElementById('dropDownChildMenu').style.display = ''
        document.getElementById('dropDownChildMenu').style.top = position.top + 'px'
        document.getElementById('dropDownChildMenu').style.left = position.left - 140 + 'px'
      } else {
        window.timer = setTimeout(() => {
          this.comeInChildMenu
          if (!this.comeInChildMenu) {
            document.getElementById('dropDownChildMenu').style.display = 'none'
          }
        }, 200)
      }
    },
    childMenuHover (active) {
      this.comeInChildMenu = active
    },
    ischeckClickLogout (value) {
      if (!value) {
        document.getElementById('dropDownChildMenu').style.display = 'none'
      }
      this.isClickLogout = value
    },
    ischeckClickDimension (target) {
      this.isClickDimension = target
    },
    ischeckClicklan (item) {
      this.isClickLan = item
    },
    ischeckClickThem (event) {
      this.isClickThem = event
    },
    logout () {
      this.iframeSrc = process.env.VUE_APP_LOGIN_OUT_REDIRECT
      setTimeout(() => {
        // win.close()
        this.$store.dispatch('LogOut').then(() => {
          location.reload()
        })
      }, 500)
    },
    handleChangeLanguge (language) {
      this.$store.dispatch('setLanguage', language)
    },
    handleChangeUnit (dimension) {
      this.$store.dispatch('setDimension', dimension)
    },
    handleChangeCSS (css) {
      this.$store.dispatch('setCSS', css)
    },
    // 下载用户手册
    downloadUserHandbook () {
      // window.location.href="../../../../../static/test.pdf"
      window.open('../../../../../static/userHandbook/' + process.env.VUE_APP_TITLE + '.pdf')
    },
    // 返回导航页面
    goBack () {
      this.$router.push({ path: '/portal' })
      document.title = process.env.VUE_APP_NAME
    },
    feedbackDialogOpen () {
      if (this.$refs['feedbackForm']) {
        this.$refs['feedbackForm'].clearValidate()
      }
    },
    clickFeedback () {
      this.feedbackDialogVisible = true
    },
    clickFeedbackSureBtn () {
      this.$refs['feedbackForm'].validate(valid => {
        if (valid) {
          const params = {
            advice: this.feedbackForm.feedbackValue,
            moduleName: this.feedbackForm.moduleName.join('-'),
            userName: this.$store.getters.name
          }
          this.feedbackSaveBtnLoading = true
          curd('/c/admin/api/sysUserAdvice', 'post', params).then(({ data }) => {
            this.$message({ type: 'warning', message: this.$t('msg.feedbackSaveSuccess') })
            this.feedbackSaveBtnLoading = false
            this.feedbackDialogVisible = false
            this.$refs['feedbackForm'].resetFields()
          }).catch(_ => {
            this.feedbackSaveBtnLoading = false
            this.$message({ type: 'warning', message: this.$t('msg.saveError') })
          })
        }
      })
    },
    goBackHome(){
      if(this.$hasPermission('sysGoBackHome')){
        this.goBack()
      }
    },
    showMessage () {
      this.$refs.message.showDialog()
    },
    rocketClientCallBack (res) {
      this.messageLength = res.data.num
    },
    getMessageTotal () {
      curd('/c/admin/api/notice/noReadNum', 'get').then(({ data }) => {
        this.messageLength = data
      })
    },
    clickOwnspace () {
      this.$router.push({ path: '/own' })
    },
    showEditConfig () {
      this.$refs.editConfig.showDialog()
    }
  },
  mounted () {
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.badge {
  // padding-right: 40px;
  user-select: none;
  // cursor: pointer;
  position: relative;
  display: inline-block;
  .item {
    z-index: 1;
    position: absolute;
    right: -9px;
    top: -20px;
  }
}
.userLogout {
  width: 100%;
  .logoutImg {
    width: 100%;
  }
}
.goBack {
  padding-left: 20px;
}
.feedback {
  color: #fff;
  cursor: pointer;
}
.feedbackFormItem {
  margin-bottom: 0;
}
.childMenu {
  position: fixed;
  left: 3px;
  top: 3px;
  width: 140px;
}
.childMenu ul {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.childMenu li {
  cursor: pointer;
  list-style: none;
  padding: 0 10px;
  font-size: 14px;
}
</style>
