import RocketClient from './RocketChatClient'
export function addRocketListener(userInfo) {
  const { rchatName, rchatPwd } = userInfo
  let url = ''
  let httpType = location.protocol
  if (httpType === 'https:') {
    url = 'wss://' + process.env.VUE_APP_SOCKET_HOST + '/websocket'
  } else {
    url = 'ws://' + process.env.VUE_APP_SOCKET_HOST + '/websocket'
  }
  // if (location.origin.indexOf('localhost') > -1) {
  //   if (config.dev.proxyTable['/c'].target.indexOf('10.230.38.77') > -1) {
  //     url = 'ws://10.230.38.87/websocket'
  //   } else {
  //     url = 'ws://117.78.7.8:10000/websocket'
  //   }
  // } else {
  //   let httpType = location.protocol
  //   if (httpType === 'https:') {
  //     url = 'wss://' + location.hostname + '/websocket'
  //   } else {
  //     url = 'ws://' + location.hostname + '/websocket'
  //   }
  // }
  let rocketClient = RocketClient.instance(url, rchatName, rchatPwd)
  // let rocketClient = RocketClient.instance('wss://10.230.38.87/websocket',userInfo.rchatName,userInfo.rchatPwd)
  rocketClient.startMessageListener()
  window.rocket = rocketClient
}
