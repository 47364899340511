// import '@/views/wellLog/js/backbone-min.js'
import '@/libs/GeoToolkit/geotoolkit.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.controls.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.widgets.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.report.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.data.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.welllog.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.welllog.las.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.welllog.widgets.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.welllog.widgets.ui.min.js'
// import '@/libs/GeoToolkit/geotoolkit.gauges.adv.js'
// import '@/libs/GeoToolkit/geotoolkit.schematics.adv.js'
import store from '@/common_store'

let UNIT
export function unitfactory() {
    UNIT = store.getters.SZGZ_unitConfig
    const factory = geotoolkit.util.UnitFactory.getInstance()
        // factory.clearUnits()
    for (const i of UNIT.unitS) {
        unitConvert(i, factory)
    }
    factory.addUnit('void', 'VOID', 'void', 'void', 0, 1, 1, 0, 'VOID.void')
    return factory
}

function unitConvert(unit, factory) {
    const arr = unit.msg.split('.')
    const name = arr[1]
    const type = arr[0]
    const enSymbol = unit.sign.replace('3', '³')
    const en = unit.sign
    const baseSymbol = UNIT.TYPE[type].baseUnit.replace('3', '³')
    const a = parseFloat(unit.factorA)
    const b = parseFloat(unit.factorB)
    const c = parseFloat(unit.factorC)
    const d = parseFloat(unit.factorD)
    const description = unit.id
    const cnSymbol = unit.name
    factory.addUnit(en, type, enSymbol, baseSymbol, a, b, c, d, description)
    factory.addUnit(cnSymbol, type, cnSymbol, baseSymbol, a, b, c, d, description)
}