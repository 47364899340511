export default {
    WorkBench: {
        navtest: '测试',
        mynavtitle: '我的工作台',
        tj: '探井',
        kfj: '开发井',
        qxz: '请选择',
        sjjd: '设计井段',
        szjd: '实钻井段',
        jdzh: '进度滞后',
        sjjs: '设计加深',
        zjjddb: '钻井进度对比',
        csyt: '测试油田',
        onec: '一厂',
        twoc: '二厂',
        threec: '三厂',
        fourc: '四厂',
        aytj: '按月统计',
        fltj: '分类统计',
        fzqkjsgqd: '复杂情况及故障清单',
        jth: '井简号',
        sgfzmc: '复杂故障名称',
        sgfzmx: '复杂故障明细',
        sgfssj: '复杂故障发生时间',
        sgjcsj: '复杂故障解除时间',
        js: '井深',
        ztjs: '钻头井深',
        cw: '层位',
        yx: '岩性',
        fgjc: '返工进尺',
        bfjc: '报废进尺',
        sgshsj: '损失时间',
        zjjjss: '直接经济损失',
        clgk: '处理概况',
        sszjcd: '损失钻具长度',
        bz: '备注',
        tjcs: '探井（次数）',
        kfjcs: '开发井（次数）',
        tjssts: '探井（损失台时）',
        kfjssts: '开发井（损失台时）',
        csq: '次数(起)',
        ssts: '损失台时',
        month: '月',
        tj: '探井',
        ts: '台时',
        zzjqd: '在钻井清单',
        kzwzjstj: '开钻完钻井数统计',
        jth: '井筒号',
        qk: '区块',
        jb: '井别',
        jx: '井型',
        sjsjjs: '目前/设计井深(m)',
        sjkc: '目前/设计开次',
        cmcw:'钻达/目的层位',
        rjc:'日进尺(m)',
        gcdt:'工程动态',
        kzrq: '开钻日期',
        // zjd: '钻井队',
        zjd: '钻井单位',
        zjddh: '钻井队电话',
        ljd: '录井队',
        ljddh: '录井队电话',
        xq: '详情',
        ndjhbsjs: '年度计划部署井数',
        ybsjs: '已部署井数',
        zxdt: '最新动态',
        dblb: '待办列表',
        dyxx: '待阅消息',
        yjxx: '预警消息',
        sdzl: '我收到的指令',
        fczl: '我发出的指令',
        zllb: '指令列表',
        zlbt: '指令标题',
        zlyq: '指令要求',
        yqwcsj: '要求完成时间',
        fqsj: '发起时间',
        fqr: '发起人',
        blr: '办理人',
        state: '状态',
        fqzl: '发起指令',
        zrjxzspm: '昨日机械钻速排名',
        fdcjxzspm: '分地层机械钻速排名',
        dzj: '待钻井',
        kztjs: '开钻探井数',
        wztjs: '完钻探井数',
        kzkfjs: '开钻开发井数',
        wzkfjs: '完钻开发井数',
        jxfz: '井下复杂',
        qt: '其他'
    },

}