import Cookies from 'js-cookie'
import i18n from '../../common_lang'

const app = {
    state: {
        sidebar: {
            opened: !+Cookies.get('sidebarStatus')
        },
        language: Cookies.get('language') || 'zh',
        dimension: 'ms',
        css: Cookies.get('css') || 'simple',
        hasMenu: true
    },
    mutations: {
        TOGGLE_SIDEBAR: state => {
            if (state.sidebar.opened) {
                Cookies.set('sidebarStatus', 1)
            } else {
                Cookies.set('sidebarStatus', 0)
            }
            state.sidebar.opened = !state.sidebar.opened
        },
        SET_LANGUAGE: (state, language) => {
            state.language = language
            i18n.locale = language
            Cookies.set('language', language)
        },
        SET_DIMESION: (state, dimension) => {
            state.dimension = dimension
        },
        SET_CSS: (state, css) => {
            state.css = css
            Cookies.set('css', css)
        },
        SET_HAS_MENU: (state, hasMenu) => {
            state.hasMenu = hasMenu
        }
    },
    actions: {
        toggleSideBar({ commit }) {
            commit('TOGGLE_SIDEBAR')
        },
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language)
        },
        setDimension({ commit }, dimension) {
            commit('SET_DIMESION', dimension)
        },
        setCSS({ commit }, css) {
            commit('SET_CSS', css)
        }
    }
}

export default app