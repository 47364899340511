const well = {
  namespaced: true,
  state: {
    wellNum: '',
    wellName: '',
  },
  mutations: {
    SET_WELLNAME(state, wellName) {
      state.wellName = wellName || ''
    },
    SET_WELLNUM(state, wellNum) {
      state.wellNum = wellNum || ''
    }
  },
  actions: {
    setWellName(state, wellName) {
      state.commit('SET_WELLNAME', wellName)
    },
    setWellNum(state, wellNum) {
      state.commit('SET_WELLNUM', wellNum)
    },
    // 获取管理单位list
    async GetGLDWList({ commit, state }) {
      try {
        if (!state.GLDWList.length) {
          const dataList = await Well.getDW()
          commit('SET_GLDWLIST', dataList)
          return dataList
        }
        return
      } catch (error) {
        return
        // console.log(error)
      }
    },
    //获取井筒号
    async GetWellName({ commit, state }, item) {
      try {
        // const dataList = await getWellList(form)
        commit('SET_WELLNAME', item)
        // return Promise.resolve(dataList)
      } catch (error) {
        // console.log(error)
        // return Promise.reject(error)
      }
    },
    //获取井号
    async GetWellNum({ commit, state }, item) {
      try {
        // const dataList = await getWellList(form)
        commit('SET_WELLNUM', item)
        // return Promise.resolve(dataList)
      } catch (error) {
        // console.log(error)
        // return Promise.reject(error)
      }
    }
  }
}

export default well