import { getToken } from '@/framework/utils/auth'
export const WebsocketMixin = {
  data() {
    return {
      message: [],
      msgType: '1',
      heartCheck: null,
      lockReconnect: false,
      msgTxtData: '',
      msgData: null,
      record: {}
    }
  },
  mounted() {
    // if (getToken()) {
    //   this.initWebSocket();
    //   this.heartCheckFun();
    // }
  },
  methods: {
    initWebSocket: function () {
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      var userId = window._TK_userId = this.$store.getters.user.username //当前登录的用户名
      // var userId = window._TK_userId = 'zsh002' //当前登录的用户名
      var localhost = `${process.env.VUE_APP_SmartHelmet_DOMAIN}/ycxz-api/system-api` //通讯服务地址
      var url = localhost.replace("https://", "wss://").replace("http://", "ws://") + "/websocket/" + userId + '_hj';
      this.websock = new WebSocket(url);
      this.websock.onopen = this.websocketOnopen;
      this.websock.onerror = this.websocketOnerror;
      this.websock.onmessage = this.websocketOnmessage;
      this.websock.onclose = this.websocketOnclose;
    },
    websocketOnopen: function () {
      //心跳检测重置
      this.heartCheck.reset().start();
    },
    websocketOnerror: function (e) {
      this.reconnect();
    },
    websocketOnmessage: function (e) {
      if (e.data !== 'HeartBeat') {
        var data = e.data ? eval("(" + e.data + ")") : ''; //解析对象
        if (data.cmd == "user") {
          //用户消息
          if (data.msgType == "1" || data.msgType == "2" || data.msgType == "9") {
            this.message.push(data);
          } else if (data.msgType == "4" || data.msgType == "10") {
            this.msgTxtData = JSON.parse(data.msgTxt)
            this.msgData = data
            this.userName = this.msgTxtData.userName
            this.roomId = this.msgTxtData.roomId
          }else if(data.msgType == "12"){
            this.record = JSON.stringify(data)
          }
        }
      }else{
      }
      //心跳检测重置
      this.heartCheck.reset().start();
    },
    websocketOnclose: function (e) {
      if (e) {
      }
      // this.reconnect();
    },
    websocketSend(text) { // 数据发送
      try {
        this.websock.send(text);
      } catch (err) {
      }
    },
    reconnect() {
      var that = this;
      if (that.lockReconnect) return;
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },

    // 心跳检测
    heartCheckFun() {
      var that = this;
      //心跳检测,每20s心跳一次
      that.heartCheck = {
        timeout: 15000,
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function () {
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          // console.log("------------清除定时器");
          return this;
        },
        start: function () {
          var self = this;
          this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            that.websocketSend("HeartBeat");
            self.serverTimeoutObj = setTimeout(function () {//如果超过一定时间还没重置，说明后端主动断开了
              that.websock.close();//如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, self.timeout)
          }, this.timeout)
        }
      }
    },
  },
  //关闭页面时断开连接
  destroyed() {
    this.websock.close();
  }

}
