import store from '@/common_store'
export const DEFAULTPATH = '/static/de/basic'
window.DEFAULTPATH = DEFAULTPATH


export async function getJTSJLRBaseData(useInfo) {
    try {
        // addRocketListener(useInfo)
        await store.dispatch('JTSJLR/loadGlobal')
        store.dispatch('JTSJLR/unitFactoryInit')
        // await Promise.all([store.dispatch("loadPage", "historyTrack"), store.dispatch("loadPage", "meterTrack"), store.dispatch("loadGKColors")]) // 曲线的必要资源，优化可将它移走
        return Promise.resolve()
    } catch (error) {
        return Promise.reject()
    }
}