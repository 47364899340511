import request from '@/framework/utils/request'
// GET
// /api/onlineDesign/file/oldesignFileLocal/getUserfileNode
// 获取个人文件节点
export function getUserfileNode(id, type) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/getUserfileNode?parent_id=' + id + '&type=' + type + ''
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/getTemp
// 获取模板
export function getTemp(lx) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/getTemp?jobType=' + lx + ''
  })
}
// POST
// /api/onlineDesign/file/oldesignFileLocal/addByTemplate
// 从模板新建
export function addByTemplate(query) {
  return request({
    url:
       '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/addByTemplate?tempId=' + query.tempId + '&name=' + query.fileName + '&parent_id=' + query.folderId + '&jth=' + query.jth + ''
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/copy
// 复制
export function copyFiles(id, name, isMoldel, wellName, parentId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/copy?id=' + id + '&name=' + name + '&parent_id=' + parentId + ''
  })
}
// POST
// /api/onlineDesign/file/oldesignFileLocal/createFolder
// 创建文件夹
// GET
export function createFile(id, fileName) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/createFolder?name=' + fileName + '&parent_id=' + id + ''
  })
}
// /api/onlineDesign/file/oldesignFileLocal/deleteFile
// 删除文件或文件夹
// GET
export function deleteFiles(fileIds) {
  return request({
    url:
       '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/deleteFile?id=' + fileIds + ''
  })
}
// /api/onlineDesign/file/oldesignFileLocal/downLoadFile
// 文件下载
// POST
export function downLoadFile(fileId, xh) {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/downLoadFile?fileId=' + fileId + '&xh=' + xh + ''
  return url
}
export function downLoadFile1() {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/downLoadFile'
  return url
}
// export function downLoadFile(id, xh) {
//   return request({
//     url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/downLoadFile?fileId=' + id + '&xh=' + xh + '',
//     method: 'get',
//     responseType: 'blob'
//   })
// }
// GET
// /api/onlineDesign/file/oldesignFileLocal/downLoadCommFile
// 公共文件下载
export function downLoadCommFile(fileId, xh) {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/downLoadCommFile?fileId=' + fileId + '&xh=' + xh + ''
  return url
}
export function downLoadSopt(fileId) {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/downLoadSopt?fileId=' + fileId + ''
  return url
}
// /api/onlineDesign/file/oldesignFileLocal/fileUpload
// 文件上传
// GET
export function fileUpload() {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/fileUpload'
  return url
}
// /api/onlineDesign/file/oldesignFileLocal/getOne
// 获取单个文件详情
// POST
// /api/onlineDesign/file/oldesignFileLocal/saveFileBy
// 文件保存
// GET
export function saveFileBy() {
  var url = '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/saveFileBy'
  return url
}
// /api/onlineDesign/file/oldesignFileLocal/submit
// 提交
// GET
export function submit(fileId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/submit?fileId=' + fileId
  })
}
// /api/onlineDesign/file/oldesignFileLocal/update
// 移动,重命名
export function update(id, name, parent_id) {
  return request({
    url:
       '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/update?id=' + id + '&name=' + name + '&parentId=' + parent_id
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/view
// 文件预览
// GET
export function view(query) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/view?fileId=' + query,
    method: 'get'
  })
}
// /api/onlineDesign/file/oldesignFileLocal/docSpotFile
// 文件识别
export function docuspot(query) {
  // alert(Qs.stringify(data))
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/docSpotFile',
    method: 'get',
    params: query
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/docSpotList
// 获取识别列表
export function docSpotList(id) {
  // alert(Qs.stringify(data))
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/docSpotList?fileId=' + id
  })
}

// GET
// /api/onlineDesign/file/oldesignFileLocal/docuspotSave
// 入库服务
export function docuspotSave(code, saveXhs, noSaveXhs) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/docuspotSave?code=' + code + '&saveXhs=' + saveXhs + '&noSaveXhs=' + noSaveXhs + ''
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/revoke
// 恢复初始
export function revoke(id) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/revoke?fileId=' + id + '' })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/addDruFile
// 在线设计从模板新建
export function addDruFile(query) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/addDruFile',
    method: 'get',
    params: query
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/findFilePlan
// 查询方案文件
export function findFilePlan(jth, planTypeId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/findFilePlan?jth=' + jth + '&planTypeId=' + planTypeId + '',
    method: 'get'

  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/detailview
// 文件详情预览
export function detailview(query) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/detailview?fileId=' + query,
    method: 'get'
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/deleteFileComm
// 文档管理删除
export function deleteFileComm(id) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/deleteFileComm?fileId=' + id,
    method: 'get'
  })
}

// GET
// /api/onlineDesign/file/oldesignFileLocal/getSportStatus
// 根据识别码获得识别状态
// spotId
export function getSportStatus(spotId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/getSportStatus?spotId=' + spotId + '',
    method: 'get'
  })
}
// 提交确认
// http://192.168.0.170:8015/api/onlineDesign/file/oldesignFileLocal/isRepeat?jth=ccc&tempId=7fd1210557af489286d1eb009eb4df33
export function isRepeat(jth, tempId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/isRepeat?jth=' + jth + '&tempId=' + tempId + '',
    method: 'get'
  })
}
// GET
// /api/onlineDesign/file/oldesignFileLocal/docSpotRepeat
// 文件覆盖提交
export function docSpotRepeat(jth, tempId, fileId) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignFileLocal/docSpotRepeat?jth=' + jth + '&tempId=' + tempId + '&fileId=' + fileId + '',
    method: 'get'
  })
}
// GET
// /api/onlineDesign/file/oldesignTempFile/findPageList
// 分页查询模板
export function oldesignTempFile(query) {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/oldesignTempFile/findPageList',
    method: 'get',
    params: query
  })
}
// GET
// /api/onlineDesign/file/wellbore/authorizationWellList
// 查询授权井
export function authorizationWellList() {
  return request({
    url: '/c/onlineDesign/api/onlineDesign/file/wellbore/authorizationWellList',
    method: 'get'
  })
}
