export default {
    artbag: {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        action: 'Operate',
        search: 'Search',
        file: 'File',
        yes: 'Yes',
        not: 'No',
        submit: 'Submit',
        jhlb: 'Well list', //井号列表
        jhwh: 'Well maintenance', //井号维护
        plcx: 'Comment query',//评论查询
        zljs: 'Data retrieval',//资料检索
        zlwh: 'Data maintenance',//资料维护
        plsc: 'Bulk delete',//批量删除
        tjwd: 'Add document',//添加文档
        tjfj: 'Add attachment',
        wxz: 'No files selected',
        filename: 'file name',
        filetype: 'file type',
        isAllowDownload: 'Download allowed',//是否允许下载
        summary: 'abstract',//摘要
        author: 'author',
        subdate: 'Release date',//发布日期
        comment: 'comment',
        bjwd: 'Edit document',//编辑文档
        pldx: 'Comment on', //评论对象
        plnr: 'Comments',//评论内容
        plsj: 'Comment time',//评论时间
        plr: 'Reviewer',
        gjc: 'key word',
        yhxm: 'User name',
        sryhxm: 'Please enter user name',
        xzrq: 'Select time',
        qsrgjc: 'Please enter keywords',
        xh: 'No',
        download: 'download',
        save: 'Save',//报错
        xzjh: 'Add Well',//新增井号
        qbpl: 'All comments',//全部评论
        tiao: 'item',//条
        xpl: 'New comment',//新评论
        zwpl: '',
        qxz: 'Please select',
        wdpl: 'My comments',//我的评论
        txpl: 'Review',//填写评论
        jhbd: 'Binding Well',
        xzjh: 'Add well',//新增井号
        yyjh: 'Have well', //已有井号
        xgfl: 'Edit catalog',
        scfl: 'Del catalog',
        tjzl: 'Add subcatalog',
        tjgfl: 'Add root catalog',
        xbqdk: 'New label on ',
        qd: 'determine',
        qx: 'cancel'

    },
}