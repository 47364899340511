<!--
 * @Author: fangshengchu
 * @Date: 2022-06-30 11:23:08
 * @LastEditTime: 2022-07-08 09:55:33
 * @LastEditors: fangshengchu
 * @Description: 
 * @FilePath: \sinopec-childd:\石化代码\前端合并代码-西北服务器\1.1\src\framework\views\layout\components\EditConfig\index.vue
-->
<template>
  <div>
    <el-dialog
      append-to-body
      title="修改配置"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户id">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>
        <el-form-item label="pageid">
          <el-input v-model="form.pageId"></el-input>
        </el-form-item>
        <el-form-item label="配置JSON">
          <el-input type="textarea" v-model="form.context"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getConfig">获取配置</el-button>
          <el-button type="success" @click="saveConfig">保存配置</el-button>
          <el-button @click="showDialog">取消</el-button>
          <el-button @click.stop="clickCopy">复制配置</el-button>
        </el-form-item>
        <el-form-item :label="'数据库的JSON' + (isSystem ? '(默认)' : '')">
          <div style="max-height: 200px;overflow: auto;">
            <p>{{context}}</p>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { postList, curd } from '../../../../api/index';
export default {
  data() {
    return {
      form: {
        userId: '',
        pageId: '',
        context: ''
      },
      context: '',
      isSystem: '',
      dialogVisible: false
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = !this.dialogVisible
    },
    clickCopy() {
      let text = this.context
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.setAttribute('value', text)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        console.log('复制成功')
      }
      document.body.removeChild(input)
    },
    onError() {

    },
    handleClose() {
      this.dialogVisible = false
    },
    async getConfig() {
      let { data } = await postList(`/c/admin/api/config/page/getSysPageConfig`, {
        userId: this.form.userId,
        page: this.form.pageId
      })
      this.context = data.context
      if (data.userId === "system") {
        this.isSystem = true
      } else {
        this.isSystem = false
      }
    },
    async saveConfig() {
      let { data } = await curd(`/c/admin/api/config/page/getSysPageConfig`, 'put', {
        userId: this.form.userId,
        page: this.form.pageId,
        context: this.form.context
      })
      console.log(data);
    }
  },
}
</script>

<style>

</style>