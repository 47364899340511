const indexedDB = window.indexedDB || window.webkitindexedDB || window.msIndexedDB || window.mozIndexedDB //兼容

export const dbInit = () => {
  return new Promise((resolve, reject) => {
    const connection = indexedDB.open('dataVerification', 2)
    connection.onblocked = (event) => {
      console.log(event);
      console.log('请关闭其他由该站点打开的页签！');
    }
    connection.addEventListener('success', (event) => {
      const db = event.target.result;
      db.addEventListener('versionchange', (event) => {
        console.log(event);
        console.log('The version of this database has changed');
      });

    });
    connection.onsuccess = function (event) {
      resolve(event.target.result)
    }
    connection.onupgradeneeded = function (event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains('dataSource')) {
        //为该数据库创建一个对象仓库-dataSource,主键为id，下面使用调用的时候主要对应主键
        //autoIncrement 标记会为该仓库开启键生成器。默认该设置是不开启的
        //使用键生成器，当你向对象仓库新增记录时键会自动生成。对象仓库生成的键往往从 1 开始，然后自动生成的新的键会在之前的键的基础上加 1。生成的键的值从来不会减小，除非数据库操作结果被回滚
        db.createObjectStore('dataSource',
          { keyPath: 'WELLBORENAME' },
          // { autoIncrement: true }
        )
      }
    }
    //失败回调
    connection.onerror = function (err) {
      reject(err)
    }
  })
}

const dbOperation = async (type, data) => {
  let typeList = ['add', 'get', 'getAll', 'delete', "put"]//方法名字
  //如果传的值不在这个列表中
  if (!typeList.includes(type)) {
    throw new Error(`操作类型错误, 仅支持: ${typeList.toString()} 方法`)
  }
  //事务模式有两个选项 readonly 或 readwrite ，默认为 readonly 模式，只读和写入数据
  const readType = type === 'add' || 'delete' || 'put' ? 'readwrite' : 'readonly'
  const res = await dbInit()
  const objectStore = res.transaction('dataSource', readType).objectStore('dataSource')

  const response = new Promise((resolve, reject) => {
    const request = objectStore[type](data)
    request.onsuccess = (res) => {
      resolve(res.target.result)
    }
    request.onerror = (err) => {
      reject(err)
    }
    request.oncomplete = (event) => {
      // alert("All done!");
      console.log(event);
    }

  })
  return response
}
// 保存更新数据
export const saveData = async (obj) => {
  try {
    const res = await dbOperation('get', obj.WELLBORENAME)
    if (res) {
      res.data[obj.name] = obj.data
      await dbOperation('put', res)
    } else {
      const params = {
        WELLBORENAME: obj.WELLBORENAME,
        data: {
          [obj.name]: obj.data
        }
      }
      await dbOperation('add', params)
    }
  } catch (error) {
    console.log(error);
  }
}

// 新增数据
export const addData = async (data) => {
  try {
    await dbOperation('add', data)
  } catch (error) {
    console.log(error);
  }
}
// 更新数据
export const updateData = async (name, data) => {
  try {
    const res = await dbOperation('get', name)
    res.data = data
    await dbOperation('put', res)
  } catch (error) {
    console.log(error);
  }
}
// 删除数据
export const deleteData = async (name) => {
  try {
    await dbOperation('delete', name)
  } catch (error) {
    console.log(error);
  }
}
// 查询数据
export const read = async (name) => {
  try {
    const res = await dbOperation('get', name)
    return res
  } catch (error) {
    console.log(error);
  }
}

export const deleteDb = async () => {
  var delReq = indexedDB.deleteDatabase('dataVerification')

  delReq.onerror = function (event) {
    console.log('删除数据库时出错.')
  }

  delReq.onblocked = function (event) {
    console.log('阻止删除数据库.')
  }

  delReq.onsuccess = function (event) {
    console.log('数据库删除成功')
    console.log(event.result) // undefined
  }
}
