<!--
 * @Author: fangshengchu
 * @Date: 2020-07-20 10:49:38
 * @LastEditTime: 2022-08-19 16:56:09
 * @LastEditors: fangshengchu
 * @Description: 
 * @FilePath: \sinopec-child\src\framework\views\layout\Layout.vue
-->
<template>
  <div class="app-wrapper">
    <div class="app-wrapper" :class="{hideSidebar:!sidebar.opened}" v-if="hasMenu">
      <sidebar class="sidebar-container"></sidebar>
      <div class="main-container">
        <div class="con-top">
          <navbar></navbar>
        </div>
        <tags-view></tags-view>
        <div class="con-bottom"> 
          <app-main></app-main>
        </div>
      </div>
    </div>
    <div class="app-wrapper" v-else>
      <app-main></app-main>
    </div>
    <!-- 智能头盔 -->
    <Smarthelmet />
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView } from "./components";
import Smarthelmet from './components/Smarthelmet'

export default {
  name: "layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
    Smarthelmet
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    hasMenu() {
      return this.$store.state.app.hasMenu;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/framework/styles/const.scss";
@import "src/framework/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  // width: 100%;
  background: $app-wrapper-background;
  // overflow: hidden;
	.con-top{
    height: $navbar-height;
    line-height: $navbar-height;
    border-radius: 0px !important;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 9;
  }
    @media all and (max-height: 780px) {
      .con-top{
        height: $navbar-height;
        line-height: $navbar-height;
      }
    }
	.con-bottom{
    color: $app-main-color;
    position: absolute;
    width: 100%;
    top: $navbar-height + $tags-height;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  @media all and (max-height: 780px) {
  .con-bottom{
    color: $app-main-color;
    position: absolute;
    width: 100%;
    top: $navbar-height + $tags-height;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}

}
</style>
