<template>
  <div style="position: relative" class="menuBox">
    <el-menu class="menuUl2" mode="horizontal" background-color="#1c6bd9" text-color="#fff" active-text-color="#0549b5">

      <template v-if="navList.length">
        <template v-for="(item, index) in navList">
          <!--生成一级导航，没有子导航-->
          <el-menu-item class="menu_Box menu_color" style="height:72px" v-if="!item.children.length" :index="index + ''" :key="index">
            <span class="link_title" @click="jump(item,item)">{{item.title}}</span>
          </el-menu-item>
          <!--生成一级导航，有子导航-->
          <el-submenu class="menu_Box menu_color" style="height:72px" v-else :index="index +1+'_'" :key="index">
            <template slot="title">
              <span class="link_title" @click="jump(item,item)">{{item.title}}</span>
            </template>

            <!-- 二级 没有子导航 -->
            <el-menu-item class="menu_Box" v-for="(val, i) in item.children" v-if="!val.children || !val.children.length" :key="i" :index="index + '-=' + i">

              <span class="link_title" v-if="val.path == 'engineeringmanagement'" @click="jump(val,item)"> {{val.title}}</span>
              <span class="link_title" v-else-if="val.path == 'wellcompletionManage'" @click="jump(val,item)"> {{val.title}}</span>
              <span class="link_title" v-else-if="val.path == 'teamAssessment'" @click="jump(val,item)"> {{val.title}}</span>

              <span class="link_title" v-else @click="jump(val,item,item.path)">{{val.title}}</span>

            </el-menu-item>
            <!-- 二级有子导航 -->
            <el-submenu class="menu_Box" v-else :index="index + '#' + i+item.path">
              <template slot="title">
                <span class="link_title">{{val.title}}</span>
              </template>
              <!-- 三级 没有子菜单 -->
              <el-menu-item class="menu_Box" v-for="(k, m) in val.children" :key="m" v-if="!k.children || !k.children.length" :index="i + '=#' + m+k.path">

                <span class="link_title" v-if="k.path == 'designChange'||k.path =='designTaskList'||k.path =='reviewTaskList'||k.path =='changeLaunch'||k.path =='changeApproval'" @click="jump(k,item,val.path)"> {{k.title}}</span>
                <!-- <span class="link_title" v-else-if="k.path == 'designTaskList'" @click="jump(k,item,val.path)"> {{k.title}}</span>
                <span class="link_title" v-else-if="k.path == 'reviewTaskList'" @click="jump(k,item,val.path)"> {{k.title}}</span> -->
                <span class="link_title" v-else @click="jump(k,item,item.path+'/'+val.path)">{{k.title}}</span>
              </el-menu-item>
              <!-- 三级 有子菜单 -->
              <el-submenu class="menu_Box" v-else :index="i + '+#' + k.id">
                <template slot="title">
                  <span class="link_title">{{k.title}}</span>
                </template>
                <!-- 四级 没有子菜单 -->
                <el-menu-item class="menu_Box" v-for="(j, z) in k.children" :key="z+k.id" v-if="!j.children || !j.children.length" :index="i + '+=#' + z+k.id">
                  <span class="link_title" @click="jump(j,item,item.path+'/'+val.path+'/'+k.path)">{{j.title}}</span>
                </el-menu-item>
              </el-submenu>
            </el-submenu>
          </el-submenu>
        </template>
      </template>

    </el-menu>
  </div>
</template>

<script>
import CryptoUtil from '@/framework/utils/CryptoUtil';

export default {
  name: "benchNavNew",
  props: ["navList", "token", "parentPath"],
  data() {
    return {
      navObj: {},
      pathIP: process.env.VUE_APP_MENU_API,
      cspz: "&language=zh&dimension=ms",

      certain:
        "http://114.116.225.164:3001/int/index.html#/gridlayout/grid_layout0_1/liebiaochaxun",
      path: "",
      childrenPath: ""
    };
  },
  methods: {
    empty(v) {
      if (v.title == "工程管理") {
        this.$router.push("/empty");
      }
    },
    completion() {
      let routeUrl = this.$router.resolve({
        path: "/wellcompletionManage"
      });
      window.open(routeUrl.href, "_blank");
    },
    jump(v, item, pPath) {
      if (pPath && pPath.indexOf("/") == 0) {
        pPath = pPath.substr(1, pPath.length - 1);
      }
      this.path = "";
      this.childrenPath = "";
      if (v.menuScope == "wellviewer") {
        //迅捷
        window.open(this.getPath(v) + "?token=" + this.token + "&username=" + encodeURIComponent(CryptoUtil.encodeAes(this.$store.getters.user.username)));
      } else {
        if (item.title == "工程管理") {
          if (v.path == "#") {
            this.$router.push("/empty");
          } else {
            if (pPath) {
              console.log(pPath);
              window.open(
                this.pathIP +
                  "/" +
                  pPath +
                  "/" +
                  this.getPath(v) +
                  "?token=" +
                  this.token
              );
            } else {
              window.open(
                this.pathIP + "/" + this.getPath(v) + "?token=" + this.token + "&username=" + encodeURIComponent(CryptoUtil.encodeAes(this.$store.getters.user.username))
              );
            }
          }
        } else {
          if (v.path == "#") {
            return;
          } else {
            if (pPath) {
              window.open(
                this.pathIP +
                  "/" +
                  pPath +
                  "/" +
                  this.getPath(v) +
                  "?token=" +
                  this.token + "&username=" + encodeURIComponent(CryptoUtil.encodeAes(this.$store.getters.user.username))
              );
            } else {
              window.open(
                this.pathIP + "/" + this.getPath(v) + "?token=" + this.token + "&username=" + encodeURIComponent(CryptoUtil.encodeAes(this.$store.getters.user.username))
              );
            }
          }
        }
      }
    },
    getPath(v) {
      if (v.menuScope == "wellviewer") {
        //迅捷，直接跳转，不用拼接
        if (!v.children || !v.children.length) {
          this.path = v.path;
        } else {
          this.getPath(v.children[0]);
        }
      } else {
        //华信
        if (v.path.substr(0,2).indexOf("/") > -1) {
          v.path = v.path.replace("/", "");
        }
        if (!v.children || !v.children.length) {
          this.path = this.childrenPath.length
            ? this.childrenPath + v.path
            : v.path;
        } else {
          this.childrenPath += v.path + "/";
          if (v.children[0].path == "#") {
            //如果没有path就往同级找
            let ind = 0;
            for (let i = 0; i < v.children.length; i++) {
              if (v.children[i].path != "#") {
                ind = i;
                break;
              }
            }
            this.getPath(v.children[ind]);
          } else {
            this.getPath(v.children[0]);
          }
        }
      }
      return this.path;
    }
  },
  mounted() {}
};
</script>

<style  lang="scss">
#simple .app-workbench-wrapper {
  .navbar {
    background: #1c6bd9 !important;
    // background: url(/static/topBG.png) 100% 100%!important;
  }
}


#simple .workbench-wrapper {
  .navbar {}
  ul,
  li {
    list-style: none;
  }
  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: auto !important;
  }
  .el-menu-demo {
    position: fixed;
    width: 100%;
    min-width: 1509px;
    overflow: auto;
    margin-left: -5px;
    z-index: 9999;
  }
  .menuUl2 {
  padding: 0 20px 0 154px !important;
  // z-index: 2001!important;
  overflow: hidden;
  height: 72px !important;
  line-height: 72px !important;
  position: fixed;
  // top: 62px !important;
  width: 100%;
  min-width: 1509px;
  z-index: 3;
  margin-top: -7px;
  li {
    float: left;
    padding: 10px 5px;
    a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .fontColor {
      color: #fff;
    }
  }
  .menu_color {
    .el-submenu__title {
      height: 61px !important;
      line-height: 61px !important;
    }
    &:hover {
      .el-submenu__title {
        background-color: #0549b5 !important;
        border-bottom: 2px solid #1c6bd9 !important;
      }
      transition: 0.3s;
      background-color: #0549b5 !important;
    }
  }
}
  .header-dropdown {
    width: 100%;
    min-width: 1509px;
    z-index: 9999;
    padding: 0 20px;
    height: auto;
    position: fixed;
    top: 100px;
    left: 0;
    color: #fff;
    background-color: #1c6bd9;
    .menuBox > ul > li > a {
      color: #6f7479;
    }
  }

  .el-icon-arrow-down:before {
    font-size: 12px;
  }
}
.menu_color {
  &:hover {
    .el-submenu__title {
      background-color: #0549b5 !important;
      // border-bottom: 2px solid #409eff !important;
      border-bottom: 2px solid #eddc79 !important;
    }
    transition: 0.3s;
    background-color: #0549b5 !important;
  }
}
.menu_Box {
  &:hover {
    // transition: 0.3s;
    background-color: #0549b5 !important;
    // color: #fff !important; //111 409eff
  }
  .el-submenu__title {
    i {
      color: #fff;
    }
    padding: 0 10px !important;
    // height: 39px !important;
    // line-height: 39px !important;
  }
  .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-icon-arrow-down,
  .el-icon-arrow-up {
    margin-left: 0px !important;
    top: 53%;
    right: -5px;
    font-size: 12px;
    color: #fff;
  }
}


.link_title {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff !important;
}
</style>
