const TokenKey = 'Authorization'
import Cookies from 'js-cookie'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, 'bearer' + token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getValidateCodeFlag() {
  return Cookies.get('ValidateCodeFlag') === 'true'
}
export function removeCookies() {
  for (let i in Cookies.get()) {
    if (i !== 'loginFlag') {
      Cookies.remove(i)
    }
  }
  return
}
