const SOMEDATA = {
    state: {
        someData: null
    },
    mutations: {
        SET_SOME_DATA(state, data) {
            state.someData = data;
        },
    },
    actions: {
        async someData({ commit }, newData) {
            // 这里可能是异步获取数据或处理数据
            commit('SET_SOME_DATA', newData);
        },
    }
}
export default SOMEDATA