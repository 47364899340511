export default {
  shwy: {
    btn: {
      cx: '查询',
      bc: '保存',
      fh: '返回',
      bzt: '饼状图',
      dc: '导出',
      qd: '确定',
      cancle: '取消',
      submit: '提交',
      confirm: '确定',
      lr: '录入',
      sh: '审核',
      sh_tg: '通过',
      sh_th: '退回',
      xz: '下载',
      xg: '修改',
      tb: '填报',
      sc: '删除',
      tj: '添加',
      cssz: '参数设置'
    },
    comment: {
      msg: {},
      table: {},
      btn: {},
      rule: {},
      form: {}
    },
    ytg: {
      ytg_tb: {},
      ytg_tj: {}
    },
    wj: {
      wjjc: {},
      wjfa: {}
    },
    zj: {
      zjjc: {
        table: {
          xh: '序号',
          cz: '操作'
        }
      },
      zjfa: {}
    },

    msg: {
      cwsj: '页面无数据',
      dataloading: '数据加载中，请稍后',
      sj: '实际结束日期必须大于开始日期',
      content: '请输入查询内容',
      xj: '双击取消星级',
      dataIsNotNull: '请选择数据',
      ts_del: '此操作将永久删除该条数据，是否继续？',
      ts: '提示',
      del_cancel: '取消删除'
    },
    menu: {},
    // 完井日报+生产进度
    wjrb: {
      table: {
        xh: '序号',
        csbfb: '超时百分比(%)',
        csyy: '超时原因',
        cz: '操作',
        dqgx: '当前工序',
        jb: '井类',
        jd: '井段',
        jhrq: '计划:',
        jjdw: '接井单位',
        jjsj: '接井时间',
        jth: '井筒号',
        kgsj: '开工时间',
        rq: '日期',
        sgdw: '施工队伍',
        sglx: '施工类型',
        sgqk: '施工情况',
        shejisj: '设计时间/h',
        shijisj: '实际时间/h',
        shzt: '审核状态',
        shzt_wsh: '未审核',
        shzt_ytg: '已通过',
        shzt_yth: '已退回',
        shzt_all: '全部',
        sj: '计划时间/h',
        sjjssj: '实际结束时间',
        sjkssj: '实际开始时间',
        sjrq: '实际:',
        sjtbsj: '实际填报时间',
        sjxfrq: '设计下发日期',
        sjzq: '设计周期',
        sjzq1: '实际周期',
        suanydw: '酸压队伍',
        suanyyh: '酸压用户',
        sydw: '试油队伍',
        syyh: '试油用户',
        tbrq: '填报日期',
        tbsj: '时间',
        tbsj_sw: '上午',
        tbsj_xw: '下午',
        tbzt: '填报状态',
        tczt: '投产状态',
        tczt_jc: '见产',
        tczt_jj: '交井',
        tczt_null: '无',
        tczt_tc: '投产',
        wgsj: '完工时间',
        wjcw: '完井层位',
        wjfs: '完井方式',
        wjjcxx: '作业类型',
        xbgx: '下步工序',
        xcglbm: '现场管理部门',
        xj: '星级',
        xm: '工序名称',
        yjtcrq: '预计投产日期',
        ywsj: '延误时间(h)',
        yxjc: '影响井次',
        yxqkgs: '影响情况概述',
        zhyy: '滞后原因',
        zjdw: '钻井队伍',
        zjyh: '钻井用户',
        zq: '周期',
        zybh: '作业编号',
        zybh_jth: '作业编号&井筒号',
        zydw: '作业单位',
        zylx: '作业类型',
        month: {
          kqb: '考勤表',
          sw: '上午',
          xw: '下午',
          zjdw: '钻井队伍',
          shiyoudw: '试油队伍',
          suanyadw: '酸压队伍',
          Sun: '星期天',
          Mon: '星期一',
          Tues: '星期二',
          Wed: '星期三',
          Thur: '星期四',
          Fri: '星期五',
          Sat: '星期六'
        }
      },
      form: {
        jth: '井筒号',
        jth_msg: '请选择井',
        zt: '状态',
        zt_op: {
          wkg: '未开工',
          jxz: '进行中',
          ywg: '已完工'
        },
        sglx: '施工类型',
        kssj: '开始时间',
        jssj: '结束时间',
        tbrq: '填报日期',
        to: '至',
        sj: '时间',
        sj_sw: '上午',
        sj_xw: '下午',
        shzt: '审核状态',
        shzt_all: '全部',
        shzt_yth: '已退回',
        shzt_wsh: '未审核',
        shzt_ytg: '已通过',
        rq: '日期',
        zybh: '作业编号',
        zylx: '作业类型',
        wjfs: '完井方式',
        zjdw: '钻井队伍',
        sydw: '试油队伍',
        suanydw: '酸压队伍'
      },
      title: {
        pie: { text: '影响时效因素饼状图' },
        zyjd: '作业进度',
        bzt: '饼状图',
        wjjcxx: '完井基础信息',
        day_info: '每日施工信息'
      },
      rule: {
        sglx: '施工类型不能为空',
        zybh_jth: '作业编号&井筒号不能空',
        jd: '起始井深用“~”隔开，井段之间“，”隔开',
        zybh: '请输入作业编号',
        jth: '请选取井筒号',
        wjcw: '请输入完井层位',
        wjfs: '请输入完井方式',
        jds: '请输入井段,井段用"~"隔开,多个井段之间用","隔开',
        zylxmc: '请选取作业类型',
        jb: '请选择井类',
        kgsj: '请选取开工时间',
        content: '请输入查询内容',
        rq: '填报日期不能为空',
        sj: '填报时间不能为空',
        xbgx: '下步工序不能为空',
        sgqk: '施工情况不能为空',
        dataIsNotNull: '请选择数据',
        zwsj: '暂无数据',
        xj: '双击取消星级'
      }
    },
    // 完井小时报
    xsb: {
      table: {
        zdmc: '字段名称',
        zddm: '字段代码'
      },
      form: {
        jth: '井筒号',
        jhMsg: '请选择井',
        lx: '类型',
        lxMsg: '请选择类型',
        qzrq: '起止日期',
        to: '至',
        query: '查询',
        export: '导出',
        set: '参数设置',
        mc: '名称',
        zd: '字段',
        cancel: '取消',
        confirm: '确定',
        lxCX: '掺稀',
        lxJD: '解堵',
        lxQJ: '气举',
        lxZP: '自喷',
        mcMsg: '请输入名称',
        msg: '提示',
        inputmsg: '文件夹名长度必须小于100',
        setSuccess: '参数设置成功'
      },
      title: {
        cssz: '参数设置'
      },
      rule: {}
    }
  }
}
