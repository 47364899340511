export default function getPageConfigBase(pageId) {
  const history = {
    "dataConfig": {
      "index": "depth",
      "logCols": [{
        "name": "RtLoggingEntity.depth",
        "type": "number",
        "unit": "m"
      }, {
        "name": "RtLoggingEntity.spm1",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.spm2",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.spm3",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit1",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit2",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit3",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit4",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit5",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit6",
        "type": "number",
        "unit": "m³"
      }]
    },
    "colors": ["red", "blue", "green", "black"],
    "tracks": [{
      "trackType": "log",
      "order": 1,
      "curves": [{
        "type": "LogCurve",
        "order": 0,
        "id": "RtLoggingEntity.spm1"
      }, {
        "type": "LogCurve",
        "order": 1,
        "id": "RtLoggingEntity.spm2"
      }, {
        "type": "LogCurve",
        "order": 2,
        "id": "RtLoggingEntity.spm3"
      }, {
        "type": "LogCurve",
        "order": 3,
        "id": "RtLoggingEntity.pit1"
      }]
    }, {
      "trackType": "log",
      "order": 2,
      "curves": [{
        "type": "LogCurve",
        "order": 3,
        "id": "RtLoggingEntity.pit3"
      }, {
        "type": "LogCurve",
        "order": 2,
        "id": "RtLoggingEntity.pit4"
      }, {
        "type": "LogCurve",
        "order": 1,
        "id": "RtLoggingEntity.pit5"
      }, {
        "type": "LogCurve",
        "order": 0,
        "id": "RtLoggingEntity.pit6"
      }]
    }, {
      "trackType": "gongkuang",
      "order": 3
    }]
  }

  const realTime = {
    header: {
      cols: [{
          id: 'RtLoggingEntity.gk',
          order: 0
        },
        {
          id: 'RtLoggingEntity.sjjs',
          order: 0
        },
        {
          id: 'RtLoggingEntity.depth',
          order: 1
        },
        {
          id: 'RtLoggingEntity.bitdep',
          order: 2
        },
        {
          id: 'RtLoggingEntity.zbc',
          order: 3
        }
      ]
    },
    wellDrilling: {
      isOpen: true,
      cols: [{
          id: 'RtLoggingEntity.depth',
          order: 0
        },
        {
          id: 'RtLoggingEntity.bitdep',
          order: 1
        }
      ]
    },
    water: {
      isOpen: true,
      cols: [{
          id: 'RtLoggingEntity.mfi',
          order: 0
        },
        {
          id: 'RtLoggingEntity.flowout',
          order: 1
        },
        {
          id: 'RtLoggingEntity.mwi',
          order: 2
        },
        {
          id: 'RtLoggingEntity.mwo',
          order: 3
        },
        {
          id: 'RtLoggingEntity.tpit',
          order: 4
        }, {
          id: 'RtLoggingEntity.gas',
          order: 5
        }
      ]
    },
    temperature: {
      isOpen: true,
      cols: [{
          id: 'RtLoggingEntity.mti',
          order: 0
        },
        {
          id: 'RtLoggingEntity.mto',
          order: 1
        }
      ]
    },
    realtime: {
      isOpen: true,
      cols: [{
          id: 'RtLoggingEntity.wob',
          order: 0
        },
        {
          id: 'RtLoggingEntity.rpm',
          order: 1
        },
        {
          id: 'RtLoggingEntity.hkload',
          order: 2
        },
        {
          id: 'RtLoggingEntity.spp',
          order: 3
        },
        {
          id: 'RtLoggingEntity.timedistInst',
          order: 4
        }, {
          id: 'RtLoggingEntity.cup',
          order: 5
        }
      ]
    },
    paramList: {
      isOpen: true,
      cols: [{
          id: 'engineering',
          order: 0,
          cols: [{
              id: 'RtLoggingEntity.spp',
              order: 0
            },
            {
              id: 'RtLoggingEntity.cup',
              order: 1
            }
          ]
        },
        {
          id: 'mud',
          order: 1,
          cols: [{
              id: 'RtLoggingEntity.pit1',
              order: 0
            },
            {
              id: 'RtLoggingEntity.pit2',
              order: 1
            }
          ]
        },
        {
          id: 'gas',
          order: 2,
          cols: [{
            id: 'RtLoggingEntity.zbc',
            order: 0
          }]
        },
        {
          id: 'cal',
          order: 3,
          cols: [{
            id: 'RtLoggingEntity.torque',
            order: 0
          }]
        }
      ]
    }
  }
  const multiwell = {
    cols: [{
        id: 'ProcDrillcrewEntity.ljd',
        order: 0
      },
      {
        id: 'ProcDrillcrewEntity.ljdxcdh',
        order: 1
      },
      {
        id: 'ProcDrillcrewEntity.zjd',
        order: 2
      },
      {
        id: 'ProcDrillcrewEntity.zjdxcdh',
        order: 3
      }
    ],
    orders: []
  }
  const meterData = {
    "dataConfig": {
      "index": "depth",
      "logCols": [{
        "name": "RtLoggingEntity.depth",
        "type": "number",
        "unit": "m"
      }, {
        "name": "RtLoggingEntity.spm1",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.spm2",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.spm3",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit1",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit2",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit3",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit4",
        "type": "number",
        "unit": "1/min"
      }, {
        "name": "RtLoggingEntity.pit5",
        "type": "number",
        "unit": "m³"
      }, {
        "name": "RtLoggingEntity.pit6",
        "type": "number",
        "unit": "m³"
      }]
    },
    "colors": ["red", "blue", "green", "black"],
    "tracks": [{
      "trackType": "yancheng",
      "order": 1
    }, {
      "trackType": "log",
      "order": 3,
      "curves": [{
        "type": "LogCurve",
        "order": 0,
        "id": "RtLoggingEntity.spm1"
      }, {
        "type": "LogCurve",
        "order": 1,
        "id": "RtLoggingEntity.spm2"
      }, {
        "type": "LogCurve",
        "order": 2,
        "id": "RtLoggingEntity.spm3"
      }, {
        "type": "LogCurve",
        "order": 3,
        "id": "RtLoggingEntity.pit1"
      }]
    }, {
      "trackType": "log",
      "order": 4,
      "curves": [{
        "type": "LogCurve",
        "order": 3,
        "id": "RtLoggingEntity.pit3"
      }, {
        "type": "LogCurve",
        "order": 2,
        "id": "RtLoggingEntity.pit4"
      }, {
        "type": "LogCurve",
        "order": 1,
        "id": "RtLoggingEntity.pit5"
      }, {
        "type": "LogCurve",
        "order": 0,
        "id": "RtLoggingEntity.pit6"
      }]
    }]
  }
  const pageConfig = {
    history,
    realTime,
    multiwell,
    meterData
  }
  return pageConfig[pageId]
}
