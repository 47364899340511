/**
 * @Author: Mr.Lin
 * @Date: 2024-03-01 11:46:57
 * @LastEditTime: 2024-03-18 16:55:41
 * @LastEditors: Mr.Lin
 * @Description:
 * @FilePath: \00-Frontend_PEAdviser\src\main.js
 */
import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import './common_icons' // icon
import './onlineDesign/plugins/utils'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/framework/styles/index.scss' // global css
import './SZGZ/plugins/index'
import './SZGZ' // 引入demo模块内容

import App from './App'
import router from './common_router'
import store from './common_store'
import i18n from './common_lang' // Internationalization
import './framework/assets/iconfont/iconfont.css'
import './permission' // permission control
import { hasPermission } from '@/framework/utils/index'
if (process.env.VUE_APP_ENV_CONFIG === 'dev') {
  require('./common_mock')
}
import './framework/public'
import './framework/utils/directives'
import './Workbench'
import './wellComp'
import './victorysoft'
import './prodSchedule'
import './onlineDesign'
//  2024-03-18 钻头图片预览，因2.6版本element ui 不支持 <el-image> 组件 引入v-viewer 实现
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Viewer.setDefaults({
  zIndex: 9999, // 图片查看器的层级
  navbar: false, // 隐藏导航栏
  toolbar: {
    rotateLeft: true,
    rotateRight: true,
    zoomIn: true,
    zoomOut: true
  }
})
Vue.use(Viewer)
Vue.use(Element, {
  size: 'medium', // set e  lement-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false
Vue.prototype.$hasPermission = hasPermission

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: { App }
})
