<template>
  <div
    class="service-handler-dialog-container"
    :class="{ show: show }"
    v-if="token"
    ref="wrapper"
  >
    <div class="head-bar">
      <span>{{$t('smarthelmet.remotePrompt')}}</span>
      <i class="el-icon-close" @click="slideDown"></i>
    </div>
    <div class="remind">
      <div class="user">{{userName}}</div>
      <div class="content">{{$t('smarthelmet.remoteMsg')}}</div>
      <div class='footer'>
        <div @click="slideDown">
          <img src="~@/framework/assets/smarthelmet/ic_rejected.png" alt="">
          <br />
          <span>{{$t('smarthelmet.hangUp')}}</span>
        </div>
        <div @click="redirect">
          <img src="~@/framework/assets/smarthelmet/ic_answer.png" alt="">
          <br />
          <span>{{$t('smarthelmet.answer')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { WebsocketMixin } from './WebsocketMixin'
import { getToken } from '@/framework/utils/auth'
export default {
  components: {
    // productSearch
  },
  name: 'smarthelmetDialog',
  mixins: [WebsocketMixin],
  watch: {
    msgData(val) {
      if (val.msgTxt == '2') {
        // this.$message.success('已接通！')
      } else if (val.msgTxt == '3') {
        this.$message.error(val.sendOutUserId + '拒接！')
      } else {
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 30000)
      }
    }
  },
  data () {
    return {
      show: false, // 弹窗显示隐藏
      userName: '',
      roomId: '',
      token: '',
      smarthelmetLoginRes: ''
    }
  },
  computed: {
  },
  methods: {
    init () {
      setTimeout(() => {
        this.slideUp()
      })
    },
    // 关闭
    slideDown() {
      this.show = false
    },
    // 加密
    encode(str) {
      if (str === undefined || str === '' || str === null) {
        return str
      }
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1)
        }))
    },
    /**
     * 跳转至指定页面（示例为我们得平台登录页面）
     */
    login() {
      const username = this.encode(this.$store.getters.user.username)
      const params = {
        username: username // 用户名
        // username: this.encode('zsh002')
      }
      let url = `${process.env.VUE_APP_SmartHelmet_DOMAIN}/ycxz-api/system-api/sys/login` // 登录接口地址
      if (process.env.VUE_APP_ENV_CONFIG === 'dev') {
        url = `/ycapi/ycxz-api/system-api/sys/login`
      }
      axios.post(url, params).then(res => {
        if (res.data.success) {
          this.smarthelmetLoginRes = res.data.result
          this.$emit("loginSuccess", res.data.result)
          window._TK_SmarthelmetLoginRes = this.smarthelmetLoginRes
          // this.redirect(res.data.result);
        }
      }).catch(error => {
        console.log('error',error)
      })
    },
    /**
     * 跳转至指定页面
     */
    redirect() {
      let res = this.smarthelmetLoginRes
      let token = res.token;
      let userInfo = res.userInfo;
      let url = `${process.env.VUE_APP_SmartHelmet_DOMAIN}/ms/multiVideo?roomId=${this.roomId}&peerId=${userInfo.username}&token=${token}&tenantId=${userInfo.relTenantId}&userId=${userInfo.id}&userName=${userInfo.username}`
      console.log('url',url);
      window.open(url, '_blank');
      this.slideDown()
    }
  },
  created() {
    if (getToken()) {
      this.token = getToken()
      this.login()
    }
  }
}
</script>

<style lang="scss" scoped>
.service-handler-dialog-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 832;
  min-width: 272px;
  min-height: 248px;
  background: #fff;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }

  .head-bar {
    height: 42px;
    padding-left: 10px;
    background: #1890ff;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    span {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 42px;
      padding: 0 9px 0 6px;
      display: inline-block;
      vertical-align: middle;
    }

    i {
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .remind {
    text-align: center;
    .user {
      font-size: 20px;
      padding: 10px;
    }
    .content {
      font-size: 18px;
      padding: 10px;
    }
    .footer {
      padding: 10px;
      display: flex;
      justify-content: space-evenly;
    }
    .footer > div{
      cursor:pointer
    }
  }
}
</style>
