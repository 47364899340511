// 频次统计Api文档
import request from '../../framework/utils/request'

// 点击菜单统计
export const clickMenu = (params) => {
  return request.post('/c/realtime/systemSetting/clickMenu',
    params
  ).then(res => {
    return Promise.resolve(res)
  })
}

// 获取菜单使用频次
export const getMenuStatistics = (params) => {
  return request.get('/c/realtime/systemSetting/selectPage',
    { params }
  ).then(res => {
    return Promise.resolve(res)
  })
}