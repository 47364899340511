const tagsView = {
  state: {
    // visitedViews: Cookies.get('visitedViews') ? JSON.parse(Cookies.get('visitedViews')) : [],
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    // 存入
    ADD_VISITED_VIEWS: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) {
        // 推进来的route已存在
        if (view.path === '/well/wellNew' || view.path === '/dp/realDataDp' || view.name === 'realtime' || view.name === 'TestRealData'|| view.name === 'realTimeWarning') {
          // 进来的是实时监测
          if (state.visitedViews.filter(item => item.path === '/well/wellNew'|| view.name === 'realTimeWarning' || item.path === '/dp/realDataDp' || item.name === 'realtime' || view.name === 'TestRealData').some(v => v.wellName === view.wellName)) {
            // 实时监测的井号存在，已查询
            return
          }
        } else {
          return
        }
      }
      state.visitedViews.push({
        name: view.name,
        path: view.path,
        title: view.meta.title || 'no-name',
        wellName: view.wellName ? view.wellName : '',
        wellNum: view.wellNum ? view.wellNum : ''
      })
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
      // Cookies.set('visitedViews', state.visitedViews)
    },
    DEL_VISITED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        // 实时监测页面特殊处理
        if (view.path === '/well/wellNew' || view.name === 'realTimeWarning' || view.path === '/dp/realDataDp' || view.name === 'realtime' || view.name === 'TestRealData') {
          if (v.path === view.path && v.wellNum === view.wellNum) {
            state.visitedViews.splice(i, 1)
            // Cookies.set('visitedViews', state.visitedViews)
            break
          }
        } else {
          if (v.path === view.path) {
            state.visitedViews.splice(i, 1)
            // Cookies.set('visitedViews', state.visitedViews)
            break
          }
        }
      }
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews.splice(index, index + 1)
          break
        }
      }
      if (state.visitedViews.filter(item=>{return item.name === 'wellNew'|| item.name ==='realTimeWarning'}).length === 0) {
        window._tabIndex = null
      }
    },
    DEL_OTHERS_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews = state.visitedViews.slice(i, i + 1)
          break
        }
      }
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews = state.cachedViews.slice(index, index + 1)
          break
        }
      }
      if (state.visitedViews.filter(item=>{return item.name === 'wellNew'|| item.name ==='realTimeWarning'}).length === 0) {
        window._tabIndex = null
      }
    },
    DEL_ALL_VIEWS: (state) => {
      state.visitedViews = []
      state.cachedViews = []
      if (state.visitedViews.filter(item=>{return item.name === 'wellNew'|| item.name ==='realTimeWarning'}).length === 0) {
        window._tabIndex = null
      }
    },
    UPDATED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews[i] = Object.assign(state.visitedViews[i], view)
          break
        }
      }
    }
  },
  actions: {
    // 存入
    addVisitedViews({ commit }, view) {
      commit('ADD_VISITED_VIEWS', view)
    },
    delVisitedViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_OTHERS_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
    delAllViews({ commit, state }) {
      return new Promise((resolve) => {
        commit('DEL_ALL_VIEWS')
        resolve([...state.visitedViews])
      })
    },
    updatedTagViews ({commit, state}, view) {
      commit('UPDATED_VIEWS', view)
    }
  }
}

export default tagsView
