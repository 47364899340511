// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = this.$te('route.' + title)
  const hasKey2 = this.$te('well.' + title)
  const translatedTitle = this.$t('route.' + title) // $t :this method from vue-i18n, inject in @/common_lang/index.js

  if (hasKey) {
    return translatedTitle
  }
  if (hasKey2) {
    return this.$t('well.' + title)
  }
  return title
}
