import request from '@/framework/utils/request'

export const getLoggingRealTime = (params) => {
  return request.get('/c/realtime/api/rtLogging/getLoggingRealTime', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}
/**
 * 井管理页面获取列表数据
 * @param {} params
 */
export const getJLBData = (params) => {
  return request.post('/c/admin/api/wellBore/page', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井管理页面获取列表数据，不分页
 * @param {} params
 */
export const getJLBDataNopage = (params) => {
  return request.post('/c/admin/api/wellBore/list', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井id后台获取
 * @param {} params
 */
export const getFindJId = () => {
  return request.get('/c/admin/api/wellBore/id').then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井号下拉选列表获取
 * @param {} params
 */
export const getJhOptions = () => {
  return request.post('/c/admin/api/well/options').then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 根据选择的井号关联出井所属单位，井别，井型，区块
 * @param {} params
 */
export const getFindById = (params) => {
  return request.post('/c/admin/api/jlb/findById', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 根据类型获取钻井状态、是否激活、管理单位、井别、井型、区块、井所属单位
 * @param {} params
 */
export const getFindOptions = (params) => {
  let url = "";
  let type = params.type;
  if (type === 'dw') {
    url = '/c/admin/api/oilCompany/options';
    params = '';
  } else if (type === 'jb') {
    url = '/c/admin/api/deployType/options';
    params = '';
  } else if (type === 'ylzt') {
    url = '/c/admin/api/fracStatus/options';
    params = '';
  } else if (type === 'jx') {
    url = '/c/admin/api/wellShape/options';
    params = '';
  } else if (type === 'qk') {
    url = '/c/admin/api/block/options';
    params = '';
  } else {
    url = '/c/admin/api/sysDict/findOptions';
  }
  return request.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井管理删除
 * @param {} params
 */
export const delJlb = (id) => {
  return request.delete(`/c/admin/api/wellBore/${id}`).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井管理新增保存
 * @param {} params
 */
export const addJlb = (params) => {
  return request.post(`/c/admin/api/wellBore/addWellbore`, params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井管理编辑保存
 * @param {} params
 */
export const editJlb = (params) => {
  return request.post(`/c/admin/api/wellBore/updateWellbore`, params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 井新增确认保存（过滤重复
 * @param {*} params
 */
export const addWJ = (params) => {
  return request.post('/c/admin/api/workgroup/addWWb', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 多井展示页面初始化获取井数据
 * @param {} params
 */
export const getMultijView = (params) => {
  return request.get('/c/realtime/api/sysWellBore/multiWellBore', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}
/**
 * 多井展示页面筛选井
 * @param {} params
 */
export const selectMultijList = (params) => {
  return request.get('/c/realtime/api/Jlb/selectAllList', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}
/**
 * 井筛选
 * @param {*} params
 */
export const getJhList = (params) => {
  return request.post('/c/admin/api/wellBore/sysWellBoresByUser', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 井筛选
 * @param {*} params
 */
export const getJhListByisAnalysisWell = (params) => {
  return request.post('/c/realtime/api/well/analysiswell/newList', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 趟钻对比数据
 * @param {*} params
 */
export const bitContrastData = (params) => {
  return request.post('/c/realtime/api/calculate/parameterAnalysisByMultiDepthRange', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取公司
 * @param {*} params
 */

export const getSelectGsList = (params) => {
  return request.post('/c/realtime/api/well/oilCompanyList', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取区块
 * @param {*} params
 */

export const getSelectQkList = (params) => {
  return request.post('/c/realtime/api/well/blockList', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 根据公司  区块名查询
 */

export const getSelectCountInfo = (params) => {
  return request.post('/c/realtime/api/well/wellStatistics', params).then(res => {
    return Promise.resolve(res)
  })
}

export const selectWellList = (params) => {
  return request.post('/c/realtime/api/well/list', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取全部数据
 */

export const getSelectWellPage = (params) => {
  return request.post('/c/realtime/api/well/selectWellPage', params).then(res => {
    return Promise.resolve(res)
  })
}

export const scheduleList = (params) => {
  return request.post('/c/realtime/api/schedule/baseList', params).then(res => {
    return Promise.resolve(res)
  })
}

export const scheduleInfo = (params) => {
  return request.post('/c/realtime/api/schedule/info', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 多井概览 井队信息
 */

export const proDrillcrew = (params) => {
  return request.get('/c/realtime/api/proDrillcrew/proDrillcrew', {
    params
  }).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 钻井日报
 */

export const procDailyrpt = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/procDailyrpt', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 钻井日报列表
 */

export const getDailyList = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/procDailyrptList', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 实时数据中 设计井深 日进尺
 */
export const realTimeStaticData = (params) => {
  return request.get('/c/realtime/api/sysWellBore/realTimeStaticData', {
    params
  }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 保存岩层数据
 */
export const proStrats = (params) => {
  return request.post('/c/realtime/api/strat/proStrats', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 岩石名称
 */
export const getZyysmcs = (params) => {
  return request.get('/c/realtime/api/strat/zyysmcs', {
    params
  }).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 颜色名称
 */
export const getYsmc = (params) => {
  return request.get('/c/realtime/api/strat/ysmc', {
    params
  }).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 井筒信息下拉选列表获取
 * @param {} params
 */
export const getWellBores = () => {
  return request.get('/c/realtime/api/debasic/wellBores').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 区块查询
 */

export const getQk = () => {
  return request.post('/c/admin/api/block/options').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 油田公司查询
 */
export const getOilCompany = () => {
  return request.post('/c/admin/api/oilCompany/options').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 井别
 */
export const getDeployType = () => {
  return request.post('/c/admin/api/deployType/options').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 井型
 */
export const getWellShape = () => {
  return request.post('/c/admin/api/wellShape/options').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 日志图片预览
 */
export const getImages = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/image', { params }).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 日志图片预览删除
 */
export const deleteImages = (params) => {
  return request.delete('/c/realtime/api/procDailyrpt/jthAndName/' + params.jth + "&" + params.fileName).then(res => {
    return Promise.resolve(res)
  })
}

export const nextImage = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/newImage', { params }).then(res => {
    return Promise.resolve(res)
  })
}

export const loadAccidents = (params) => {
  return request.get('/c/realtime/api/procAccident/dailyAccidents', { params }).then(res => {
    return Promise.resolve(res)
  })
}

export const uploadFile = (params) => {
  return request.post("/c/realtime/api/procDailyrpt/file", params, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
    return Promise.resolve(res)
  })
}

export const deleteFile = (params) => {
  return request.delete('/c/realtime/api/procDailyrpt/jthAndDate/' + params.jth + "&" + params.date).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取数据库最新数据时间
 */
export const getDataNewTime = (params) => {
  return request.get('/c/realtime/api/rtLogging/maxTime', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取数据库最新数据
 */
export const getDataNewTimeData = (params) => {
  return request.get('/c/realtime/api/rtLogging/maxTimeData', { params }).then(res => {
    return Promise.resolve(res)
  })
}


/**
 * 获取数据库最新数据深度
 */
export const fetchNewMeterDepth = (params) => {
  return request.get('/c/realtime/api/rtMtLogging/maxDepth', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取数据库米数据
 */
export const fetchMeterLog = (params) => {
  return request.get('/c/realtime/api/rtMtLogging/simple/listByLimit', { params }).then(res => {
  // return request.get('/c/realtime/api/rtMtLogging/listByLimit', { params }).then(res => {
    return Promise.resolve(res)
  })
}

export const deleteRow = (params) => {
  return request.put('/c/realtime/api/sysUserwell/del', params).then(res => {
    return Promise.resolve(res)
  })
}

export const addwellList = (params) => {
  return request.post('/c/realtime/api/sysUserwell/add', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 多井井删除
 * @param {} params
 */
export const delMultiwell = (id) => {
  return request.delete(`/c/realtime/api/sysUserwell/${id}`).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * @param {*} params
 */
export const dailyAPI = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/getRqList', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取工况颜色数据
 */
export const getGKColorsConfig = () => {
  return request.get('/c/realtime/api/rtLogging/gkAndColor').then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 根据公司  区块名查询
 */

export const getWellboreStatisticsList = (params) => {
  return request.post('/c/realtime/api/well/wellboreStatisticsList', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 报警
 */
export const getWarnDataList = (params) => {
  return request.get('/c/admin/api/warnRecord/getHistoryList', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 多井进度对比
 */

export const postScheduleLists = (params) => {
  return request.post('/c/realtime/api/schedule/lists', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 施工井管理list
 */
export const postSGwellList = (params) => {
  return request.post('/c/admin/api/wellBore/mulList', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 全井曲线查询接口
 * @param {*} params {"wellBore":"顺北11井", "resultType":"ECD"}
 */
export const getECDData = (params) => {
  return request.post('/c/realtime/api/calculate/calculateGet', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过地层获取钻速（m/min）
 */
export const getZsListByStrat = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getZsListByStrat', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过开次获取钻速（m/min）
 */
export const getZsListByKc = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getZsListByKc', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过地层获取非生产时间（m/min）
 */
export const getFscsjListByStrat = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getFscsjListByStrat', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过开次获取非生产时间（m/min）
 */
export const getFscsjListByKc = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getFscsjListByKc', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过开次获取钻头和钻具组合数据（m/min）
 */
export const getBitAndBhaByKc = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getBitAndBhaByKc', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过地层获取钻头和钻具组合数据（m/min）
 */
export const getBitAndBhaByStrat = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getBitAndBhaByStrat', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过地层获取非生产时间（min）和事故名称
 */
export const getAccidentByStrat = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getAccidentByStrat', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 通过开次获取非生产时间和事故名称
 */
export const getAccidentByKc = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getAccidentByKc', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取事故列表 accidentIds
 */
export const getAccidentInfo = (params) => {
  return request.post('/c/realtime/api/multiWellContrast/getAccidentInfo', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 同步数据启动
 */
export const synData = (params) => {
  return request.get('/c/admin/api/wellBore/sysWellBoreUpdate', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取一口井的邻井
 */
export const getLinWell = (params) => {
  return request.post('/c/admin/api/wellBore/multiWellList', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取邻井当前地层的警报
 */
export const getAccidentsList = (params) => {
  return request.post('/c/realtime/api/procAccident/accidentsListByWellBores', params).then(res => {
    return Promise.resolve(res)
  })
}

export const scheduleInfos = (params) => {
  return request.post('/c/realtime/api/schedule/warnList', params).then(res => {
    return Promise.resolve(res)
  })
}
export const scheduleInfosById = (params) => {
  return request.post('/c/realtime/api/schedule/warnListById', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 报警
 */
export const getHisDataList = (params) => {
  return request.get('/c/admin/api/warnRecord/getHistoryList', { params }).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 报警-全部数据
 */
export const getAllHisDataList = (params) => {
  return request.post('/c/admin/api/warnRecord/getHistoryListByEntity', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 *  录井道曲线数据
 *
 * @param {*} params
 * @returns
 */
export const getLogginCurveList = (params) => {
  return request.post('/c/realtime/api/rtMtLogging/getLogginCurveList', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 岩屑信息接口
 */
export const getMudlogCuttingprf = (params) => {
  return request.get('/c/realtime/api/rtMtLogging/getMudlogCuttingprf', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取一段时间的日报
 */
export const getDailyByRq = (params) => {
  return request.get('/c/realtime/api/procDailyrpt/getListByRq', { params }).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 修改远传状态
 * @param {} params
 */
export const postIstransitMudlog = (params) => {
  return request.post('/c/admin/api/wellBore/updateIstransitMudlog', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取关注井管理
 * @param {} params
 */
export const getConcernWellBore = (params) => {
  return request.post('/c/realtime/api/sysWellBore/concernWellBore',
    params
  ).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 查询单井信息
 * @param {} params
 */
export const getMultiWellBoreSingle = (params) => {
  return request.get('/c/realtime/api/sysWellBore/multiWellBoreSingle', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 查询单井设计阈值信息
 * @param {} params
 */
export const getFindDeFluids = (params) => {
  return request.get('/c/realtime/api/deBit/findDeBits', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 查询建设单位
 *
 */
export const getConstructionUnit = () => {
  return request.post('/c/admin/api/wellBore/getGldws').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 查施工公司
 *
 */
export const getBuilderUnit = () => {
  return request.post('/c/realtime/api/proDrillcrew/getZjgss').then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 关注井管理- 用户未关注井
 * @param {} params
 */
export const getUnFollow = (params) => {
  return request.post('/c/realtime/api/sysWellBore/userAllWellBore', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 关注井管理- 用户已关注井
 * @param {} params
 */
export const getFollow = (params) => {
  return request.post('/c/realtime/api/sysWellBore/multiWellBore', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 选井发送短信
 * @param {} params
 */
export const handSendWarnMessage = (params) => {
  return request.post('/c/admin/api/warnRecord/handSendWarnMessage', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 获取各业务是否推送
 * @param {} params
 */
export const getBusinessSwitch = (params) => {
  return request.get('/c/admin/api/warnHandBusinessSwitch/getBusinessSwitch', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 保存或修改各业务是否推送
 * @param {} params
 */
export const changeGetBusinessSwitch = (params) => {
  return request.post('/c/admin/api/warnHandBusinessSwitch/saveOrUpdateBusinessSwitch', params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 保存或修改井筒和人员关系
 * @param {} params
 */
export const saveOrUpdateWarnHandMessagePK = (params) => {
  return request.post('/c/admin/api/warnHandMessagePK/saveOrUpdateWarnHandMessagePK', params).then(res => {
    return Promise.resolve(res)
  })
}
/**
 * 获取各业务是否推送
 * @param {} params
 */
export const getWarnHandMessagePK = (params) => {
  return request.get('/c/admin/api/warnHandMessagePK/getWarnHandMessagePK', {
    params
  }).then(res => {
    // const { data } = res
    return Promise.resolve(res)
  })
}

/**
 * 全井时效分析事件标注--查询
 * @param {} params
 */
export const getprocScheduleEventList = (params) => {
  return request.get('/c/realtime/api/welllog/procScheduleEvent/list', {
    params
  }).then(res => {
    return Promise.resolve(res)
  })
}
