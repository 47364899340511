export default {
    completionManage: {
        fjck: '附件查看',
        sjrw: '设计任务',
        sjrwxq: '设计任务详情',
        fbsjrw: '发布设计任务',
        pc: '批次',
        yfbrw: '已发布任务',
        dfbjhlb: '待发布井号列表',
        releasePeople: '发布人',
        zjsjxd: '钻井设计修订',
        change: '选择',
        annual: '年度',
        qxz: '请选择',
        sjrwqdlb: '设计任务清单列表',
        ybsjh: '已部署井号',
        jbs: '井部署',
        jbspc: '井位部署批次',
        pleaseInput: '请输入',
        query: '查询',
        xzbsjpc: '新增井位部署批次',
        serialNumber: '序号',
        state: '状态',
        operator: '操作人',
        creationTime: '创建时间',
        wellDeployment: '井部署',
        operation: '操作',
        qsrgjzcx: '请输入关键字查询',
        editor: '编辑',
        toView: '查看',
        ps:'评审',
        taskName: '任务名称',
        organizePeople: '组织人',
        wellNumberList: '井号列表',
        wellboreName: '井号',
        deployType: '井别',
        wellType: '井型',
        manageDepartment: '管理单位',
        completionType: '完井类型',
        uploadTime: '上传时间',
        sjbgwdfj: '设计报告文档附件',
        zsyj: '主审意见',
        yjck: '意见查看',
        hyjlsmj: '会议结论扫描件',
        remark: '备注',
        zjsc: '完井审查',
        submit: '提交',
        save: '保存',
        saveSelection: '保存选择',
        cancel: '取消',
        shutDowm: '关闭',
        delete: '删除',
        add: '添加',
        typelx: '类型',
        audit: '审核',
        review: '审查',
        wjxdsh: '完井修订审核',
        wjxdshxq: '完井修订审核详情',
        bsyj: '部署依据',
        otherAccessory: '完井任务书',
        addWellboreName: '添加井号',
        wTo: '至',
        time: '时间',
        startDate: '起始日期',
        endDate: '结束日期',
        changePeople: '选择人员',
        submitTime: '提交时间',
        reviewTime: '审查时间',
        reviewResults: '审查结果',
        organizeReview: '组织审查',
        yscjlb: '已审查井列表',
        dzzscjlb: '待组织审查井列表',
        zzxssc: '组织线上审查',
        zzxsscxx: '组织线上审查详情',
        zzxxsc: '组织线下审查',
        zzxxscxx: '组织线下审查详情',
        bsjpc: '井位部署批次',
        wscwj: '未上传文件',
        designPeriod: '设计期限',
        Yes: '是',
        No: '否',
        sfyxfptj: '是否允许分批提交',
        qsrbzxx: '请输入备注信息',
        zsyj: '主审意见',
        sjbgwd: '设计报告文档',
        shsftg: '审核是否通过',
        experts: '专家',
        opinion: '意见',
        expertsOpinion: '专家意见',
        gwjs: '岗位角色',
        reviewConclusion: '审查结论',
        approvalOpinion: '审批意见',
        qtxjh: '请填写井号',
        qtxjb: '请填写井别',
        qxzjx: '请选择井型',
        qxzgldw: '请选择管理单位',
        qxzwjlx: '请选择完井类型',
        revision: '修订',
        sczj: '审查专家',
        shr: '审核人',
        designer:'设计人',
        zjsjxssczj: '完井设计线上专家审查',
        zjsjxssc: '完井设计线上主审审查',
        wjsjxxsc: '线下设计审查会议结论入库',
        zjsjxsscxq: '完井设计线上审查详情',
        wjsjxxscxq: '完井设计线下审查详情',
        zjxd: '完井修订',
        xdrq: '下达日期',
        xdjs: '下达井数',
        ysjjsl: '已设计井数量',
        wsjjsl: '未设计井数量',
        wtgsjjsl: '未通过设计井数量',
        submitSjbg:'提交设计报告',
        sjbgxq:'设计报告详情',
        wjsjbgxq:'完井设计报告详情',
        tjwjsjbglb:'提交完井设计报告列表',
        tjr:'提交人',
        jsl:'井数量',
        wjgcqdlb:'完井工程清单列表',
        wjrwpc:'完井任务批次',
        djhjym:'单井信息汇聚页面',
        wjgcpds:'完井工程评定书',
        noUpload: '未上传',
        txyj:'填写意见',
        wtx:'未填写',
        return:'返回',
        hyrq:'会议日期',
        chzj:'参会专家',
        zzsjscbz:'组织设计审查备注',
        zszjyj:'主审专家意见',
        sjbg: '设计报告',
        zszj:'主审专家',
        scqx:'审查期限',
        zszjytjzzps:'主审专家已提交最终评审结论！',
        xzwjlx:'选择完井类型',
        wjsjbgfj:'完井设计报告附件',
        sgxqlj:'施工详情链接',
        wjsjxd:'完井设计修订',
        wjsjxdxq:'完井设计修订详情',
        scsjbg:'上传设计报告',
        tjsjbgwd:'添加设计报告文档',
        ndwjrwpcqd:'年度完井任务批次清单',
        xpcrwxd:'新批次任务下达',
        pcTitle:'标题',
        xdjs:'下达井数',
    }
}