import request from '@/framework/utils/request'
export function fetchUserMenuTree(query) {
  return request({
    url: '/c/admin/api/sysUser/noPermission/userMenuTree',
    method: 'get',
    params: query
  })
}
export function checkUserIsExist(query) {
  return request({
    url: '/c/admin/api/register/noPermission/checkUserIsExist',
    method: 'GET',
    params: query
  })
}
