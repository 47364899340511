import { constantRouterMap, LayoutComponent, _import_, asyncRouterMap } from '@/common_router'

/**
 *  拼接第一个路径，用于模块路径重定向到子菜单
 *
 * @param {*} route 上级路由
 * @param {*} path 上级路径
 * @returns
 */
function findPath(route, path) {
  path = path + '/' + route[0].path
  if (route[0].children !== null && route[0].children.length > 0) {
    return findPath(route[0].children, path)
  } else {
    return path
  }
}
const iframeComponents = ['drillingWellAnalysis', 'drillingKPIAnalysis', 'drillingStatusAnalysis', 'drillingActivityStatistics',
  'bitDataBase', 'collarDataBase', 'pipeDataBase', 'heavypipeDataBase', 'mudmotorDataBase',
  'drillingSelection','drillingSelectionV2', 'bitStructureRecommend', 'realTimeWarning', 'manualWariningResults',
  'directionalMonitoring', 'ground3D', 'geologicalOrientation', 'drillingDesign',
  'geosteeringMonitoring', 'downholeWorkingConditionAnalysis', 'drillingRiskPrediction',
  'geomeChanicalParameter', 'drillingAnalysis', 'history', 'IvFracturingConstruction', 'IvFracturingConstruction',
  'hydraulicsConventAnalysis', 'hydraulicsHoleCleaningAnalysis', 'hydraulicsSwabAndSurgeAnalysis', 'drillingDownupAnalysis', 'drillParaAnalyzer', 'singleWellDataManagement'
]
/**
 * 通过递归的方式处理后端返回的路由列表
 * @param roles
 */
function filterRouter(roles) {
  let a
  try {
    roles.forEach(route => {
      a = route
      // console.log(route)
      route.meta = { title: route.name, icon: route.icon, scope: route.menuScope }
      if (route.children !== null && route.children.length > 0) {
        if (route.component === null) {
          route.componentName = 'LayoutComponent'
          route.component = LayoutComponent
          let path = route.path
          path = findPath(route.children, path)
          route.redirect = path
        } else {
          route.componentName = route.component
          try {
            route.component = _import_(route.component)
          } catch (error) {
            console.log(error)
            route.component = LayoutComponent
            route.redirect = '/login'
          }
          route.redirect = '/login'
        }
        filterRouter(route.children)
      } else {
        try {
          let cArray = route.component.split('/')
          let isIframe = false
          if (cArray.includes('SZGZ') || cArray.includes('JTSJLR') || cArray.includes('FRAC')) {
            iframeComponents.forEach(item => {
              cArray.forEach(i => {
                if (item === i) {
                  isIframe = true
                }
              })
            })
          }
          // if (component)
          if (isIframe) {
            route.iframeComponent = _import_(route.component)
            delete route.component
          } else {
            route.component = _import_(route.component)
          }
        } catch (error) {
          console.log(error)
          route.component = LayoutComponent
          route.redirect = '/login'
        }
      }
    })
  } catch (error) {
  }
  return roles
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const { roles } = data
        let consoleRoles = filterRouter(roles)
        consoleRoles = [...consoleRoles, ...asyncRouterMap]
        commit('SET_ROUTERS', consoleRoles)
        resolve()
      })
    }
  }
}

export default permission
