import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import getters from './getters'
import permission from './modules/permission'
import tagsView from './modules/tagsView'


import DEMO from '@/DEMO/store'
import JTSJLR from '@/JTSJLR/store'
import SZGZwell from "@/SZGZ/store"
import WJCSwell from "@/WJCS/store"
import SOMEDATA from "@/FRAC/store"


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        user,
        permission,
        tagsView,
        DEMO,
        JTSJLR,
        SZGZwell,
        WJCSwell,
        SOMEDATA
    },
    getters
})

export default store 