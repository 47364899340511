import { getJhOptions, getFindOptions, getSelectGsList, getSelectQkList, getWellBores, getQk, getOilCompany, getDeployType, getWellShape } from '../api/well'
import i18n from '@/common_lang'
class Well {
    /**
     * 获取井别
     */
    static async getJB() {
        const data = await getDeployType()
        var DeployTypes = data.data
        return DeployTypes
    }

    /**
     * 获取井型
     */
    static async getJX() {
        const data = await getWellShape()
        return data.data
    }

    /**
     * 获取所有油田公司
     */
    static async getOilCompany() {
            const data = await getOilCompany()
            var companylist = data.data
            return companylist
        }
        /**
         * 获取所有区块
         */
    static async getQk() {
            const data = await getSelectQkList()
            var qklist = []
            data.data.forEach(item => {
                qklist.push({
                    name: item.blockName,
                    code: item.blockId
                })
            })
            return qklist
        }
        /**
         * 获取所有井筒信息
         */
    static async getWellBores() {
            const data = await getWellBores()
            var wellBoreList = []
            data.data.forEach(item => {
                wellBoreList.push({
                    name: item.WELLBORE_NAME,
                    code: item.WELLBORE_ID
                })
            })
            return wellBoreList
        }
        /**
         * 获取所有井号
         */
    static async getJhOptions() {
            const data = await getJhOptions()
            var jhlist = []
            data.data.forEach(item => {
                jhlist.push({
                    name: item.name,
                    code: item.code
                })
            })
            return jhlist
        }
        /**
         * 获取所有钻井状态
         */
    static async getZJZT() {
            // const data = await getFindOptions({
            //   type: 'zjzt'
            // })
            const data = [{
                    name: i18n.t('drillState.finish'),
                    code: '1'
                },
                {
                    name: i18n.t('drillState.waiting'),
                    code: '2'
                },
                {
                    name: i18n.t('drillState.doing'),
                    code: '3'
                },
                {
                    name: i18n.t('drillState.completion'),
                    code: '4'
                },
                {
                    name: i18n.t('drillState.wellCompletion'),
                    code: '5'
                },
                {
                    name: i18n.t('drillState.testing'),
                    code: '6'
                },
                {
                    name: i18n.t('drillState.EndOfOilTest'),
                    code: '7'
                }
            ]
            return data
        }
        /**
         * 获取所有是否激活
         */
    static async getSF() {
            const data = [{
                    name: i18n.t('activate.yes'),
                    code: '1'
                },
                {
                    name: i18n.t('activate.no'),
                    code: '2'
                }
            ]
            return data
        }
        /**
         * 获取所有管理单位、井所属单位
         */
    static async getDW() {
        const data = await getSelectGsList()
        var dwlist = []
        data.data.forEach(item => {
            dwlist.push({
                name: item.oilcompanyName,
                code: item.oilcompanyId
            })
        })
        return dwlist
    }

    /**
     * 获取所有区块
     */
    static async getYLZT() {
            const data = await getFindOptions({
                type: 'ylzt'
            })
            var ylztlist = []
            data.data.forEach(item => {
                ylztlist.push({
                    name: item.name,
                    code: item.code
                })
            })
            return ylztlist
        }
        /**
         * 获取所有目的层
         */
    static async getMDC() {
            const data = await getFindOptions({
                type: 'mdc'
            })
            var mdclist = []
            data.data.forEach(item => {
                mdclist.push({
                    name: item.name,
                    code: item.code
                })
            })
            return mdclist
        }
        /**
         * 获取所有完井方式
         */
    static async getWJFF() {
            const data = await getFindOptions({
                type: 'wjff'
            })
            var wjfflist = []
            data.data.forEach(item => {
                wjfflist.push({
                    name: item.name,
                    code: item.code
                })
            })
            return wjfflist
        }
        /**
         * 表单验证
         */
    static async validate(data) {
        var msg = ''
        if (data.wellboreId === '') {
            msg = i18n.t('sheet.wellboreId') + i18n.t('msg.notNull')
            return msg
        } else if (data.wellboreName === '') {
            msg = i18n.t('sheet.wellboreName') + i18n.t('msg.notNull')
            return msg
        } else if (data.wellboreName.length > 15) {
            msg = i18n.t('sheet.wellboreName') + i18n.t('msg.LengthLimit')
            return msg
        }
        /*else if (data.suffixName === '') {
          msg = i18n.t('sheet.suffixName') + i18n.t('msg.notNull')
          return msg
        }else if(data.suffixName.length > 10){
          msg = i18n.t('sheet.suffixName') + i18n.t('msg.LengthLimit10')
          return msg
        }*/
        return msg
    }
}
export default Well