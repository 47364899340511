const demo = {
    namespaced: true,
    state: {
        wellName: ''
    },
    mutations: {
        SET_WELLNAME(state, wellName) {
            state.wellName = wellName
        },
    },
    actions: {
        setWellName(state, wellName) {
            state.commit('SET_WELLNAME', wellName)
        },
    }
}
export default demo