const getters = {
  SZGZ_wellName: state => state.SZGZwell.wellName,
  SZGZ_wellNum: state => state.SZGZwell.wellNum,
  SZGZ_unitConfig: state => state.SZGZwell.unitConfig,
  SZGZ_gkBaseColor: state => state.SZGZwell.gkBaseColor,
  SZGZ_globalConfig: state => state.SZGZwell.globalConfig,
  SZGZ_pageConfig: state => state.SZGZwell.pageConfig,
  SZGZ_dimConfig: state => state.SZGZwell.dimConfig,
  SZGZ_colsConfig: state => state.SZGZwell.colsConfig,
  SZGZ_loginConfig: state => state.SZGZwell.loginConfig,
  SZGZ_limitsConfig: state => state.SZGZwell.limitsConfig,
  SZGZ_unitFactory: state => state.SZGZwell.unitFactory,
  SZGZ_analysisWellNum: state => state.SZGZwell.analysisWellNum,
  SZGZ_analysisWellName: state => state.SZGZwell.analysisWellName
}

export default getters