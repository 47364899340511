import DEMOGetters from '@/DEMO/store/getters' // 引入demo中的getters
import JTSJLRGetters from "@/JTSJLR/store/getters"
import SZGZGetters from "@/SZGZ/store/getters"
import VICTORYSOFTGetters from '@/victorysoft/store/getters' //引入胜软的getters
import WORKBENCHGetters from '@/Workbench/store/getters' // 引入demo中的getters
import WJCSGetters from "@/WJCS/store/getters" // 完井测试getters

const getters = {
    ...WORKBENCHGetters,
    ...VICTORYSOFTGetters,
    ...DEMOGetters,
    ...JTSJLRGetters,
    ...SZGZGetters,
    ...WJCSGetters,
    sidebar: state => state.app.sidebar,
    language: state => state.app.language,
    css: state => state.app.css,
    dimension: state => state.app.dimension,
    token: state => state.user.token,
    user: state => state.user.user,
    name: state => state.user.name,
    roles: state => state.user.roles,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    permission_routers: state => state.permission.routers,
    addRouters: state => state.permission.addRouters,
}
export default getters