<template>
  <section class="app-main" v-cloak>
    <transition name="fade" mode="out-in">
      <!-- <keep-alive include="multiwell,gis,historyData,meterData,add,printQSExcel,realDataView,regionalComplex,wellTest,constructionWellManagement,gasHistoryData,multiWellContrast,drillingDesign,alarmHistory,formationPressure,specificEnergy,frictionTorque,gasrealLog,controlSystem"> -->
         <!-- 2024-02-29  zangle 要求打开 -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
    <!-- iframe页 -->
    <component
      v-for="item in hasOpenComponentsArr"
      :key="item.name"
      :is="item.name"
      v-show="$route.name === item.path"
    ></component>
  </section>
</template>

<script>
import Vue from 'vue'
export default {
  name: "AppMain",
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    // 实现懒加载，只渲染已经打开过（hasOpen:true）的iframe页
    hasOpenComponentsArr() {
      return this.componentsArr.filter(item => item.hasOpen);
    }
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  },
  created() {
    // 设置iframe页的数组对象
    const componentsArr = this.getComponentsArr();
    componentsArr.forEach(item => {
      Vue.component(item.name, item.component);
    });
    this.componentsArr = componentsArr;
    // 判断当前路由是否iframe页
    this.isOpenIframePage();
  },
  data() {
    return {
      componentsArr: [] // 含有iframe的页面
    };
  },
  watch: {
    $route() {
      // 判断当前路由是否iframe页
      this.isOpenIframePage();
    }
  },
  methods: {
    // 根据当前路由设置hasOpen
    isOpenIframePage() {
      const target = this.componentsArr.find(item => {
        return item.path === this.$route.name
      })
      if (target && !target.hasOpen) {
        target.hasOpen = true
      }
    },
    // 遍历路由的所有页面，把含有iframeComponent标识的收集起来
    getComponentsArr() {
      const routes = this.$store.getters.permission_routers

      function filterIframe(array, iframeArr) {
        array.forEach(item => {
          if (item.children && item.children.length) {
            filterIframe(item.children, iframeArr)
          } else {
            if (item.iframeComponent) {
              iframeArr.push(item)
            }
          }
        })
      }
      let iframeArr = []
      filterIframe(routes, iframeArr)
      // const iframeArr = routes.filter(item => item.iframeComponent)

      return iframeArr.map(item => {
        const name = item.name || item.path.replace("/", "")
        return {
          name: name,
          path: item.name,
          hasOpen: false, // 是否打开过，默认false
          component: item.iframeComponent // 组件文件的引用
        }
      })
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
[v-cloak] {
  display: none !important;
}
</style>
