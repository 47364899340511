import Vue from 'vue'

// 随钻时效
Vue.directive('dialogDrag', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')
    // console.log(binding.value);
    const width = binding.value.width
    const height = binding.value.height
    // window.a =dragDom.children[1]
    // dragDom.style.height = dragDom.children[1].offsetHeight
    //dialogHeaderEl.style.cursor = 'move';
    dialogHeaderEl.style.cssText += ';cursor:move;'
    dragDom.style.cssText += ';top:0px;width:' + width +'px;margin-top:15vh;height:' + height + 'px'
    //弹框可拉伸最小宽高
    let minWidth = 400

    let minHeight = 300

    //初始非全屏

    let isFullScreen = false;

    //当前宽高

    let nowWidth = 0;

    let nowHight = 0;

    //当前顶部高度

    let nowMarginTop = "15vh";

    //获取弹框头部（这部分可双击全屏）


    //弹窗



    //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；

    dragDom.style.overflow = "auto";

    //清除选择头部文字效果

    //dialogHeaderEl.onselectstart = new Function("return false");

    //头部加上可拖动cursor

    dialogHeaderEl.style.cursor = 'move';

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);


    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr]
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr]
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      const screenWidth = document.body.clientWidth// body当前宽度
      const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取) 

      const dragDomWidth = dragDom.offsetWidth // 对话框宽度
      const dragDomheight = dragDom.offsetHeight // 对话框高度

      const minDragDomLeft = dragDom.offsetLeft
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

      const minDragDomTop = dragDom.offsetTop
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight


      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left')
      let styT = sty(dragDom, 'top')

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
      } else {
        styL = +styL.replace(/\px/g, '')
        styT = +styT.replace(/\px/g, '')
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离 
        let left = e.clientX - disX
        let top = e.clientY - disY

        // 边界处理
        if (-(left) > minDragDomLeft) {
          left = -(minDragDomLeft)
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft
        }

        if (-(top) > minDragDomTop) {
          top = -(minDragDomTop)
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop
        }

        // 移动当前元素 
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
      }

      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
    // dialogHeaderEl.onmousedown = moveDown;

    //双击头部全屏效果

    dialogHeaderEl.ondblclick = (e) => {

      if (isFullScreen == true)
      //  {

      //     nowHight = dragDom.clientHeight;

      //     nowWidth = dragDom.clientWidth;

      //     nowMarginTop = dragDom.style.marginTop;

      //     dragDom.style.left = '5vw';

      //     dragDom.style.bottom = '5vh';

      //     dragDom.style.height ="10VH";

      //     dragDom.style.width = "10VW";

      //     isFullScreen = true;

      //     // dialogHeaderEl.style.cursor = 'initial';

      //     // dialogHeaderEl.onmousedown = null;

      // } else
      {

        dragDom.style.height = height + 'px';

        dragDom.style.width = width + 'px';

        // dragDom.style.marginTop = nowMarginTop;
        // console.log(dragDom.style);

        isFullScreen = false;

        dialogHeaderEl.style.cursor = 'move'

      }

    }

    dragDom.onmousemove = function (e) {

      let moveE = e;

      if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 || dragDom.offsetLeft + 10 > e.clientX) {

        dragDom.style.cursor = 'w-resize';

      } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {

        dragDom.style.cursor = 's-resize';

      } else {

        dragDom.style.cursor = 'default';

        dragDom.onmousedown = null;

      }

      dragDom.onmousedown = (e) => {

        const clientX = e.clientX;

        const clientY = e.clientY;

        let elW = dragDom.clientWidth;

        let elH = dragDom.clientHeight;

        let EloffsetLeft = dragDom.offsetLeft;

        let EloffsetTop = dragDom.offsetTop;

        dragDom.style.userSelect = 'none';

        let ELscrollTop = el.scrollTop;

        //判断点击的位置是不是为头部

        if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 100) {

          //如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;

        } else {

          document.onmousemove = function (e) {

            e.preventDefault(); // 移动时禁用默认事件
            const screenWidth = document.body.clientWidth// body当前宽度
            const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取) 
      
            const dragDomWidth = dragDom.offsetWidth // 对话框宽度
            const dragDomheight = dragDom.offsetHeight // 对话框高度
      
            const minDragDomLeft = dragDom.offsetLeft
            const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth
      
            const minDragDomTop = dragDom.offsetTop
            const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight
      

            //左侧鼠标拖拽位置

            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {

              //往左拖拽

              if (clientX > e.clientX) {
                isFullScreen = true
                let l = elW + (clientX - e.clientX) * 2
                if (l < screenWidth) {
                  dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px';
                }


              }

              //往右拖拽

              if (clientX < e.clientX) {
                isFullScreen = true
                if (dragDom.clientWidth < minWidth) {

                } else {

                  dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px';

                }

              }

            }

            //右侧鼠标拖拽位置

            if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW) {

              //往左拖拽
              
              if (clientX > e.clientX) {
                isFullScreen = true
                if (dragDom.clientWidth < minWidth) {

                } else {

                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';


                }

              }

              //往右拖拽
              
              if (clientX < e.clientX) {
                isFullScreen = true
                let q =  elW - (clientX - e.clientX) * 2
                if (q < screenWidth) {
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                }

              }

            }

            //底部鼠标拖拽位置

            if (ELscrollTop + clientY > EloffsetTop + elH - 20 && ELscrollTop + clientY < EloffsetTop + elH) {

              //往上拖拽

              if (clientY > e.clientY) {
                isFullScreen = true
                if (dragDom.clientHeight < minHeight) {

                } else {

                  dragDom.style.height = elH - (clientY - e.clientY) * 2 + 'px';

                }

              }

              //往下拖拽

              if (clientY < e.clientY) {
                isFullScreen = true
                let a =  elH + (e.clientY - clientY) * 2
                if (a < screenHeight - minDragDomTop) {
                  dragDom.style.height = elH + (e.clientY - clientY) * 2 + 'px';
                }


              }

            }

          };

          //拉伸结束

          document.onmouseup = function (e) {

            document.onmousemove = null;

            document.onmouseup = null;

          };

        }

      }

    }

  }

})

// 纯移动
Vue.directive('dialogMove', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')
    //dialogHeaderEl.style.cursor = 'move';
    dialogHeaderEl.style.cssText += ';cursor:move;'
    dragDom.style.cssText += ';top:0px;'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr]
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr]
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      const screenWidth = document.body.clientWidth// body当前宽度
      const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取) 

      const dragDomWidth = dragDom.offsetWidth // 对话框宽度
      const dragDomheight = dragDom.offsetHeight // 对话框高度

      const minDragDomLeft = dragDom.offsetLeft
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

      const minDragDomTop = dragDom.offsetTop
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight


      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left')
      let styT = sty(dragDom, 'top')

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
      } else {
        styL = +styL.replace(/\px/g, '')
        styT = +styT.replace(/\px/g, '')
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离 
        let left = e.clientX - disX
        let top = e.clientY - disY

        // 边界处理
        if (-(left) > minDragDomLeft) {
          left = -(minDragDomLeft)
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft
        }

        if (-(top) > minDragDomTop) {
          top = -(minDragDomTop)
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop
        }

        // 移动当前元素 
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
      }

      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})
//ECD
Vue.directive('dialogCanvas', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')

    //dialogHeaderEl.style.cursor = 'move';
    dialogHeaderEl.style.cssText += ';cursor:move;'
    dragDom.style.cssText += ';top:0px;height:90vh;margin-top:5vh'
    //弹框可拉伸最小宽高

    let minWidth = 400

    let minHeight = 300

    //初始非全屏

    let isFullScreen = false;

    //当前宽高

    let nowWidth = 0;

    let nowHight = 0;

    //当前顶部高度

    let nowMarginTop = "5vh";

    //获取弹框头部（这部分可双击全屏）


    //弹窗



    //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；

    dragDom.style.overflow = "auto";

    //清除选择头部文字效果

    //dialogHeaderEl.onselectstart = new Function("return false");

    //头部加上可拖动cursor

    dialogHeaderEl.style.cursor = 'move';

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);


    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr]
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr]
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      const screenWidth = document.body.clientWidth// body当前宽度
      const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取) 

      const dragDomWidth = dragDom.offsetWidth // 对话框宽度
      const dragDomheight = dragDom.offsetHeight // 对话框高度

      const minDragDomLeft = dragDom.offsetLeft
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

      const minDragDomTop = dragDom.offsetTop
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight


      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left')
      let styT = sty(dragDom, 'top')

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
      } else {
        styL = +styL.replace(/\px/g, '')
        styT = +styT.replace(/\px/g, '')
      }

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离 
        let left = e.clientX - disX
        let top = e.clientY - disY

        // 边界处理
        if (-(left) > minDragDomLeft) {
          left = -(minDragDomLeft)
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft
        }

        if (-(top) > minDragDomTop) {
          top = -(minDragDomTop)
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop
        }

        // 移动当前元素 
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
      }

      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
    // dialogHeaderEl.onmousedown = moveDown;

    //双击头部全屏效果

    dialogHeaderEl.ondblclick = (e) => {

      if (isFullScreen == true)
      //    {

      //       nowHight = dragDom.clientHeight;

      //       nowWidth = dragDom.clientWidth;

      //       nowMarginTop = dragDom.style.marginTop;

      //       dragDom.style.left = '5vw';

      //       dragDom.style.bottom = '5vh';

      //       dragDom.style.height ="10VH";

      //       dragDom.style.width = "10VW";
      //       dragDom.children[1].children[0].style.width = 'calc(10vw - 40px)'
      //       dragDom.children[1].children[1].style.width =  'calc(10vw - 40px)'
      //       dragDom.children[1].children[1].children[0].style.width = 'calc(10vw - 40px)'
      //     //   dragDom.children[1].children[0].style.height = 'calc(10vh - 140px)'
      //       dragDom.children[1].children[1].style.height = '50px'
      //       dragDom.children[1].children[1].children[0].style.height = '50px'       
      //       isFullScreen = true;

      //       // dialogHeaderEl.style.cursor = 'initial';

      //       // dialogHeaderEl.onmousedown = null;

      //   } else 
      {

        dragDom.style.height = "90vh";

        dragDom.style.width = '600px';
        dragDom.children[1].children[0].style.width = '560px'
        dragDom.children[1].children[1].style.width = '560px'
        dragDom.children[1].children[1].children[0].style.width = '560px'
        //   dragDom.children[1].children[0].style.height = 'calc(90vh - 140px)'
        dragDom.children[1].children[1].style.height = 'calc(90vh - 140px)'
        dragDom.children[1].children[1].children[0].style.height = 'calc(90vh - 140px)'
        dragDom.style.marginTop = nowMarginTop;
        isFullScreen = false;

        dialogHeaderEl.style.cursor = 'move'

      }

    }

    dragDom.onmousemove = function (e) {

      let moveE = e;

      if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 || dragDom.offsetLeft + 10 > e.clientX) {

        dragDom.style.cursor = 'w-resize';

      } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {

        dragDom.style.cursor = 's-resize';

      } else {

        dragDom.style.cursor = 'default';

        dragDom.onmousedown = null;

      }

      dragDom.onmousedown = (e) => {

        const clientX = e.clientX;

        const clientY = e.clientY;

        let elW = dragDom.clientWidth;

        let elH = dragDom.clientHeight;

        let EloffsetLeft = dragDom.offsetLeft;

        let EloffsetTop = dragDom.offsetTop;

        dragDom.style.userSelect = 'none';

        let ELscrollTop = el.scrollTop;

        //判断点击的位置是不是为头部

        if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 100) {

          //如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;

        } else {

          document.onmousemove = function (e) {

            e.preventDefault(); // 移动时禁用默认事件

            //左侧鼠标拖拽位置

            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {

              //往左拖拽

              if (clientX > e.clientX) {

                let l = elW + (clientX - e.clientX) * 2
                if (l < 600) {
                  dragDom.children[1].children[0].style.width = elW + (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].style.width = elW + (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].children[0].style.width = elW + (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px';
                }


              }

              //往右拖拽

              if (clientX < e.clientX) {
                isFullScreen = true
                if (dragDom.clientWidth < minWidth) {

                } else {
                  dragDom.children[1].children[0].style.width = elW - (e.clientX - clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].style.width = elW - (e.clientX - clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].children[0].style.width = elW - (e.clientX - clientX) * 2 - 40 + 'px'
                  dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px';

                }

              }

            }

            //右侧鼠标拖拽位置

            if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW) {

              //往左拖拽

              if (clientX > e.clientX) {
                isFullScreen = true
                if (dragDom.clientWidth < minWidth) {

                } else {
                  // console.log(elW - (clientX - e.clientX) * 2 );

                  dragDom.children[1].children[0].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].children[0].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';

                }

              }

              //往右拖拽

              if (clientX < e.clientX) {
                let q = elW - (clientX - e.clientX) * 2
                if (q < 600) {
                  dragDom.children[1].children[0].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.children[1].children[1].children[0].style.width = elW - (clientX - e.clientX) * 2 - 40 + 'px'
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                }

              }

            }

            //底部鼠标拖拽位置

            if (ELscrollTop + clientY > EloffsetTop + elH - 20 && ELscrollTop + clientY < EloffsetTop + elH) {

              //往上拖拽

              if (clientY > e.clientY) {
                isFullScreen = true
                if (dragDom.clientHeight < minHeight) {

                } else {

                  dragDom.children[1].children[1].style.height = elH - (clientY - e.clientY) * 2 - 140 + 'px'
                  dragDom.children[1].children[1].children[0].style.height = elH - (clientY - e.clientY) * 2 - 140 + 'px'
                  dragDom.style.height = elH - (clientY - e.clientY) * 2 + 'px';

                }

              }

              //往下拖拽

              if (clientY < e.clientY) {
                let a = elH + (e.clientY - clientY) * 2
                if (a <= 0.9 * (document.documentElement.clientHeight)) {
                  dragDom.children[1].children[1].style.height = elH - (clientY - e.clientY) * 2 - 140 + 'px'
                  dragDom.children[1].children[1].children[0].style.height = elH - (clientY - e.clientY) * 2 - 140 + 'px'
                  dragDom.style.height = elH + (e.clientY - clientY) * 2 + 'px';
                  // dragDom.children[1].style.height =  elH + (e.clientY - clientY) * 2 -56-56-10+ 'px'
                }


              }

            }

          };

          //拉伸结束

          document.onmouseup = function (e) {

            document.onmousemove = null;

            document.onmouseup = null;

          };

        }

      }

    }

  }

})

