import request from '@/framework/utils/request'
import CryptoUtil from '@/framework/utils/CryptoUtil'
import axios from 'axios'
import Qs from 'qs'
//import Mock from '../libs/mock'
// let url = 'http://10.230.38.87'
let url = 'http://117.78.7.8'
//2.8 获取管理单位列表
export function getManageDepartments () {
  const url = '/c/management/workbench/list/getManageDepartments'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}
//获取钻井数量概况
export function getPeriodWellDeploymentSummary (data) {
  /*   const data = {
      period: pram
    } */
  let wellNumUrl = '/c/management/workbench/statistics/periodWellDeploymentSummary'
  return request.post(wellNumUrl, data).then(res => {
    return Promise.resolve(res)
  })
}
//获取进尺统计
export function getDrillDepth (data) {
  /*   const data = {
      period: data
    } */
  let DrillDepthUrl = '/c/management/workbench/statistics/periodDrillDepthSummary'
  return request.post(DrillDepthUrl, data).then(res => {
    return Promise.resolve(res)
  })
}
//获取开钻完钻井统计
export function getDrillStatus (data) {
  /*   const data = {
      period: 'year'
      year:''
    } */
  let DrillStatusUrl = '/c/management/workbench/statistics/periodDrillStatusSummary'
  return request.post(DrillStatusUrl, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取平均钻井周期
export function getAvarageDrillCycle () {
  const data = {
    period: 'year',
    showYear: 5
  }
  let DrillStatusUrl = '/c/management/workbench/statistics/periodAvarageDrillCycleSummary'
  return request.post(DrillStatusUrl, data).then(res => {
    return Promise.resolve(res)
  })
}
// 故障清单列表
export function getBreakDownList (data) {
  let url = '/c/management/workbench/list/periodDrillAccidents'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//钻井故障次数统计
export function getDrillAccident (data) {
  /*  const data = {
     "period": "month",
     "year": '2019'
   } */
  let DrillAccident = '/c/management/workbench/statistics/periodDrillAccidentSummary'
  return request.post(DrillAccident, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取钻井故障年份
export function getDrillAccidentYears () {
  let DrillAccident = '/c/management/workbench/list/getDrillAccidentYears'
  return request.get(DrillAccident).then(res => {
    return Promise.resolve(res)
  })
}

//钻机状态统计
export function getDrillMachineStatus (data) {
  let url = '/c/management/workbench/statistics/periodDrillMachineStatusSummary'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取菜单
/* export function getMenuTree() {
  return request.get('../../../static/menuTree.json').then(res => {
    return Promise.resolve(res)
  })
} */
export function getMenuTree () {
  return request.get('/c/admin/api/sysUser/noPermission/userMenuTree').then(res => {
    return Promise.resolve(res)
  })
}


// 开钻完井数统计图表的点击列表页
export function getFinishedDrillList (data) {
  let url = '/c/management/workbench/list/periodDrillStatus'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
// 钻井数量列表
export function getWellNumList (data) {
  let url = '/c/management/workbench/list/periodWellDeployments'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

// 钻机状态
export function getDrillStateStatisticsList (data) {
  let url = '/c/management/workbench/list/periodDrillMachineStatus'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//机械钻速排行榜列表
export function GetMechanicalDrillingList (data) {
  const url = '/c/management/workbench/statistics/periodMachineDrillSpeedRankingSummary'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//钻井进度列表
export function DrillWellProgresspost (data) {
  const url = '/c/management/workbench/statistics/periodDrillWellProgressCompareSummary'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取地层
export function getStrata () {
  const url = '/c/management/workbench/list/getStrata'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//用户在钻井清单列表
/* export function getDrillMachineWellBore() {
  const url = '/c/admin/api/sysUser/noPermission/wellBore'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}
 */
//用户在钻井清单列表
export function getDrillMachineWellBore (data) {
  const url = '/c/management/workbench/statistics/periodDrillingListSummary'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

// 工程概况
export function getDrillingOperation (params) {
  const url = `/c/management/workbench/statistics/getDrillingOperation?${Qs.stringify(params)}`
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//按需获取组件
export function getviews () {
  return request.get('../../../static/views.json').then(res => {
    return Promise.resolve(res)
  })
}

//获取用户配置
export function getWorkBench () {
  const url = '/c/admin/api/config/page/workBench'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//修改用户配置
export function putWorkBench (data) {
  const url = '/c/admin/api/config/page/workBench'
  return request.put(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//新增用户配置
export function postWorkBench (data) {
  const url = '/c/admin/api/config/page'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取待办，待阅，已办，已阅数量
export function getNumByauthMark (params) {
  const url = `/c/management/messageCenter/Message/getNumByauthMark?${Qs.stringify(params)}`
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}
//获取待办，待阅，预警信息列表
export function getTodoWorks (data) {
  const url = '/c/management/messageCenter/todoWork/getTodoWorks'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//获取已办已阅信息列表
export function getDoneWorks (data) {
  const url = '/c/management/messageCenter/DoneWork/getDoneWorks'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//获取已办已阅信息列表
export function getLatestNews (data) {
  const url = '/c/management/workbench/statistics/getLatestNews'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
// 获取登录人信息
export function getuserRoleFuncInfo () {
  return request({
    url: '/c/admin/api/sysUser/noPermission/userRoleFuncInfo',
    method: 'get',
    beforeSend: function (request) {
      request.setRequestHeader("Authorization", this.$store.getters.token);
    }
  })
}
// 获取登录人信息
export function getUserInfo () {
  return request({
    url: '/c/admin/api/sysUser/noPermission/userInfo',
    method: 'get'
  })
}

//设置指令正在处理中
export function setInstructionInProcess (data) {
  const url = '/c/management/flowCenter/workInstruction/setInstructionInProcess'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//工作指令统计
export function periodWorkInstructionSummary (data) {
  const url = '/c/management/flowCenter/workInstruction/periodWorkInstructionSummary'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//工作指令清单列表
export function periodWorkInstructions (data) {
  const url = '/c/management/flowCenter/workInstruction/periodWorkInstructions'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}

//获取在钻井清单详情
export function getDrillWellStatus (data) {
  const url = '/c/management/workbench/details/getDrillWellStatus'
  return request.post(url, data).then(res => {
    return Promise.resolve(res)
  })
}
//获取井号
export function getWellboreList () {
  const url = `/c/management/workbench/list/getWellboreList`
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}
