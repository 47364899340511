
import Vue from 'vue'
const errorHandler = (error, vm) => {
  console.error(error)
}

Vue.config.errorHandler = errorHandler
Vue.prototype.$throw = (error) => errorHandler(error, this)
import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
// import VueI18n from 'vxe-i18n'
// import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// import enUS from 'vxe-table/lib/locale/lang/en-US'
import FilterInput from './FilterInput.vue'
import FilterContent from './FilterContent.vue'
Vue.component(FilterInput.name, FilterInput)
Vue.component(FilterContent.name, FilterContent)
// 创建一个简单的输入框筛选
VXETable.renderer.add('FilterInput', {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <filter-input params={ params }></filter-input>
    ]
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = XEUtils.get(row, column.property)
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }
})

// 创建一个支持列内容的筛选
VXETable.renderer.add('FilterContent', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <filter-content params={ params }></filter-content>
    ]
  },
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach(option => {
      option.data = { vals: [], sVal: '' }
    })
  },
  // 筛选数据方法
  filterMethod({ option, row, column }) {
    const { vals } = option.data
    const cellValue = XEUtils.get(row, column.property) + ''
      // debugger
    return vals.some(val => val === cellValue)
  }
})

import 'vxe-table/lib/index.css'
import uploader from 'vue-simple-uploader'// 文件上传
// import { VTable, VPagination } from 'vue-easytable' // 生产调度 静态页面组件调用
import highcharts from 'highcharts' // 生产调度 图形
// import VueHighCharts from 'vue-highcharts' // 生产调度 图形
import highcharts3d from 'highcharts/highcharts-3d' // 生产调度 3d图形
// import 'vue-easytable/libs/themes-base/index.css'
import VueCookies from 'vue-cookies'
import plTable from 'pl-table'
import 'pl-table/themes/index.css' // 引入样式（必须引入)，vuecli3.0不需要配置，cli2.0请查看webpack是否配置了url-loader对woff，ttf文件的引用,不配置会报错哦
// import 'pl-table/themes/plTableStyle.css' // 默认表格样式很丑 引入这个样式就可以好看啦（如果你不喜欢这个样式，就不要引入，不引入就跟ele表格样式一样）
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)
Vue.use(VueCookies)
Vue.use(plTable)
Vue.use(XEUtils)
highcharts3d(highcharts)
// Vue.use(VueHighCharts)
Vue.use(VXETable)
// Vue.component(VTable.name, VTable)
// Vue.component(VPagination.name, VPagination)
Vue.use(uploader)
Vue.use(VXETable)
Vue.prototype.$XModal = VXETable.modal
VXETable.renderer.add('FilterInput', {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <filter-input params={ params }></filter-input>
    ]
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = XEUtils.get(row, column.property)
    return XEUtils.toString(cellValue).indexOf(data) > -1
  }
})
