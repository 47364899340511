export default {
  zxbj_form: {
    xzr: '协助人',
    mbmc: '模板名称',
    mc: '名称',
    jth: '井筒号',
    sjj: '设计井',
    wjmc: '文件名称',
    refJth: '参考井',
    bgmc: '报告名称',
    mblx: '模板类型',
    mblx_op: {
      bz: '标准',
      zdy: '自定义'
    },
    mbfl: '模板分类',
    mbfl_xsb: '小时报',
    mbfl_zj: '钻井',
    mbfl_wj: '完井',
    mbfl_ytg: '油套管',
    ssmb: '所属模板',
    scwj: '上传文件',
    scwj_msg1: '将文件拖到此处，或',
    scwj_msg2: '点击上传',
    scwj_msg3: '上传文件只能是  xlsx,docx 格式!,不支持Strict open XML文档（*.docx）',
    mbms: '模板描述',
    gjz: '关键字',
    gjz_msg: '关键字，多关键字使用,隔开,如 a,b,c'
  },
  zxbj_rule: {
    jth: '井筒号不能为空',
    tempId: '模板不能为空',
    fileName: '文件名称不能为空',
    plantypeid: '类型不能为空'

  },
  zxbj_message: {

    delMsg: '确定删除吗',
    timeout: '请求数据超时',
    sbz: '正在识别中，请稍后',
    sbjs: '识别完成，正在打开识别列表',
    ts: '提示',
    gml: '已经在根目录',
    fileerror: '该文件异常，无法识别',
    info: '此操作将之前的井的数据覆盖, 是否继续?',
    gjz: '请输入关键词，多个关键字空格隔开',
    p1: '1、安装Office2016/2013/2010/2007/2003/或WPS,最好在Win10/Win8以上的计算计上运行。',
    p2: '2、下载安装weboffice插件，',
    p3: '点此下载',
    p4: '控件。下载完请解压文件，查看安装教程，安装完毕后，打开文档提示是与否时，选择是按钮，才能正常访问。',
    p5: '重要提示：',
    p6: '1:带',
    p7: '标识行无法放弃入库',
    p8: '2:未勾选的数据表示放弃入库',
    p9: '是否放弃这些表(',
    p10: ')入库吗',
    p11: '确认信息',
    dyy: '已经在第一页',
    zhy: '已经在最后页',
    jysb: '',
    saving: '数据正在保存中',
    saveSuccess: '数据保存成功',
    loading: '数据正在加载中',
    NoData: '没有更多数据了！',
    delTsMsg: '此操作将永久删除该文件, 是否继续?',
    dataValite: '数据校验失败，请填写完整',
    faIsNull: '请选择方案',
    sbz_loading: '正在识别中，请稍后',
    init_loading: '恢复初试中，请稍后',
    srmc: '请输入名称'

  },
  zxbj_title: {
    sbz: '识别中',
    zzrk: '正在入库',
    xz: '协助',
    newFile: '从模板创建',
    sbjg: '识别结果',
    bgzs: '表格总数',
    ysb: '已识别',
    wsb: '未识别',
    xsbrk: '小时报入库',
    wdsj: '我的设计',
    wdfx: '我的分享',
    cyxt: '参与协同',
    rkxz: '入库选择',
    cxrk: '重新入库',
    yxrk: '已选入库',
    fqrk: '放弃入库',
    rkjg: '入库结果',
    bcrkcg: '本次入库成功',
    bcrksb: '本次入库失败',
    zg: '总共',
    yrk: '已入库',
    wrk: '未入库'
  },
  zxbj_table: {
    xh: '序号',
    jth: '井筒号',
    bgmc1: '报告名称',
    rkData: '入库数据',
    rksj: '入库时间',
    ckbg: '查看表格',
    name: '名称',
    mbmc: '模板名称',
    ssmb: '所属模板',
    gjz: '关键字',
    mbms: '模板描述',
    gxsj: '更新时间',
    fbzt: '发布状态',
    fbzt_wfb: '未发布',
    fbzt_yfb: '已发布',
    size: '大小',
    date: '日期',
    state: '状态',
    rkhs: '入库行数',
    hs: '行数',
    state_op: {
      error: '文件异常',
      xzz: '文件协助中',
      wsb: '未识别',
      wrk: '未入库',
      rksb: '入库失败',
      yrk: '已入库',
      zzsb: '正在识别',
      ysb: '已识别',
      zzrk: '正在入库',
      cxrk: '重新入库',
      ytj: '已提交',
      yqtj: '取消提交'

    },
    bgmc: '表格名称',
    sbhs: '识别行数',
    sbxx: '识别信息',
    fxz: '分享者',
    xzz: '协助者',
    dqzt: '当前状态',
    dqzt_op: {
      yrk: '已入库',
      wrk: '未入库',
      yfq: '已放弃',
      ysb: '已识别',
      wsb: '未识别'
    },
    rksm: '入库说明',
    sm: '说明',
    option: '操作',
    op: '操作'
  },
  zxbj_btn: {
    bc: '保存',
    syy: '上一页',
    xyy: '下一页',
    ll: '浏览',

    add: '添加',
    sx: '刷新',
    ren: '重命名',
    download: '下载',
    del: '删除',
    move: '移动',
    copy: '复制',
    upload: '上传',
    refresh: '刷新',
    new: '新建',
    new_op: {
      newFile: '从模板新建',
      newFolder: '创建文件夹'
    },

    downHelp: '下载帮助',
    downHelp_op: {
      mbzx: '模板中心',
      kj: 'weboffice控件'
    },
    back: '返回上级',
    sb: '识别',
    ytj: '已提交',
    fx: '分享',
    qxfx: '取消分享',
    zzsb: '正在识别',
    ysb: '已识别',
    zzrk: '正在入库',
    yrk: '已入库',
    cxrk: '重新入库',
    tj: '提交',
    rk: '入库',
    init: '恢复初始',
    confirm: '确定',
    cancel: '取消',
    edit: '编辑',
    zbrk: '准备入库',
    ksrk: '开始入库',
    query: '查询',
    close: '关闭',
    gjz: '关键字',
    fb: '发布',
    ch: '撤回',
    xz: '下载'
  },
  zxbj: {
    rename: '重命名',
    download: '下载',
    del: '删除',
    move: '移动到',
    copy: '复制到',
    upload: '上传文件',
    create: '新建',
    cfolder: '新建文件夹',
    cfile: '模版中新建文件',
    back: '返回上一级',
    uncomit: '未提交',
    comit: '提交',
    comited: '已提交',
    sb: '识别',
    ysb: '已识别',
    wsb: '未识别',
    rk: '入库',
    yrk: '已入库',
    rk_error: '入库失败',
    init: '恢复初始',
    update: '编辑',
    cancel: '取消',
    confirm: '确定',
    name: '文件名',
    size: '大小',
    date: '更新日期',
    status: '状态',
    tempName: '模版名称',
    tempNameMsg: '请选择模板',
    createTempTitle: '从模板中新建',
    well: '井',
    wellMsg: '请选择井',
    fileName: '文档名称',
    fileNameMsg: '请输入名称',
    op: '操作',
    jh_msg: '所属井不能为空',
    lx_msg: '所属类型不能为空',
    mb_msg: '模板不能为空',
    initSuccess: '恢复初始成功',
    initError: '恢复初始失败',
    comitSuccess: '提交成功',
    comitError: '数据提交失败',
    select: '请勾选数据',
    distinError: '获取识别列表失败',
    sbError: '识别失败',
    sbing: '努力识别中……',
    initing: '恢复中……',
    nameMsg: '请输入名称',
    msg: '提示',
    nameErrorMsg: '名称开头不能为空格',
    addError: '创建失败',
    addSuccess: '创建成功',
    renameSuccess: '重命名成功',
    delMsg: '确定删除文件？',
    file: '文件',
    delSuccess: '删除成功',
    delError: '删除失败',
    selectMsg: '必须选择一个目标文件夹',
    moveSuccess: '移动文件成功',
    copySuccess: '复制文件成功',
    rukuSuccess: '入库成功',
    wpsVilMsg: 'WPS验证失败，是否重新验证?',
    wpsTitle: 'WPS验证'
  },
  xsb: {
    jth: '井筒号',
    jhMsg: '请选择井',
    lx: '类型',
    lxMsg: '请选择类型',
    qzrq: '起止日期',
    to: '至',
    query: '查询',
    export: '导出',
    set: '参数设置',
    mc: '名称',
    zd: '字段',
    cancel: '取消',
    confirm: '确定',
    lxCX: '掺稀',
    lxJD: '解堵',
    lxQJ: '气举',
    lxZP: '自喷',
    mcMsg: '请输入名称',
    msg: '提示',
    inputmsg: '文件夹名长度必须小于100',
    setSuccess: '参数设置成功'
  },
  ytg: {
    jhmc: '计划名称',
    bgmc: '报告名称',
    date: '时间',
    btn: {
      query: '查询',
      add: '添加',
      xz: '下载',
      sc: '删除',
      jhbj: '计划编辑',
      jhtj: '计划统计',
      jhtb: '计划填报',
      qx: '取消',
      qd: '确定',
      ll: '浏览'
    },
    dialog: {
      title: '新建油套管计划设计',
      jhmb: '计划模板',
      jhmb_msg: '请选择类型',
      jhmc: '计划名称',
      jhjj: '计划简介',
      dqcr: '读取插入'
    },
    xh: '序号',
    tb: '填报',
    jj: '简介',
    tj: '统计',
    bj: '编辑',
    op: '操作',
    msg: {
      delMsg: '此操作将永久删除该文件, 是否继续?',
      ts: '提示',
      delSuccess: '删除成功',
      delError: '删除失败',
      addSuccess: '添加成功',
      addError: '添加失败',
      ckj: '请填写参考井',
      sjj: '请填写设计井',
      dataIsNotNull: '请勾选数据',
      selectPlan: '请选择计划'
    },
    fa: {
      tb: '填报',
      tongji: '统计',
      bc: '保存',
      tj: '提交',
      fh: '返回',
      syy: '上一页',
      xyy: '下一页',
      kfjbsap: '开发井部署安排',
      tgxq: '套管需求',
      dwdjxq: '定位短节需求',
      bkbjdjxq: '变扣变径短节需求',
      ldjxq: '联顶节需求',
      tgtxq: '套管头需求',
      ygjjkxxq: '油管及井口需求',
      tgxqzb: '套管需求总表',
      ygjfjxqhz: '油管及附件需求汇总',
      jkxqzb: '井口需求汇总表',
      dwdjxqzb: '定位短节需求总表',
      bkbjdjxqzb: '变扣变径短节需求总表',
      ldjxqzb: '联顶节需求总表',
      tgtxqzb: '套管头需求总表',
      fatb: {
        btn: {
          cr: '插入',
          dqcr: '读取插入',
          bj: '编辑',
          sc: '删除',
          tj: '添加',
          gb: '关闭',
          cx: '查询',
          qx: '取消',
          qd: '确定',
          ljdq: '邻井读取',
          dr: '导入',
          js: '计算'
        },
        form: {
          sjj: '设计井',
          ckj: '参考井',
          sjly: '数据来源',
          sjly_op: {
            tgqdsjb: '套管强度数据表（常量库）',
            tgqdjhb: '套管强度校核表（邻井方案）',
            tgxqjh: '套管需求计划表（邻井预估）'
          },
          gg: '规格',
          mc: '名称',
          sl: '数量',
          fl: '分类',
          lx: '类型',
          tgt: '套管头'
        },
        table: {
          bh: '壁厚mm',
          bk: '变扣',
          bz: '备注',
          cclx: '储层类型',
          cclx_p: '储层类型(碳酸盐岩/碎屑岩)',
          cd: '长度m',
          cd1: '产地',
          cyslx: '采油树类型',
          dz: '单重',
          ext_cysgg: '采油树/采气树规格',
          ext_jxgjgg: '井下工具',
          ext_stgg: '四通规格',
          flxhhyljb: '法兰型号和压力级别',
          gg: '规格',
          gj: '钢级',
          gtqf: '管体屈服',
          gzjg: '管柱结构',
          jgwj: '接箍外径',
          jsjg: '井身结构',
          jth: '井筒号',
          jtljqd: '接头连接强度',
          jtzxznqfqd: '接头最小内屈服强度',
          jxjg: '井型结构',
          jxjg_p: '井型结构(例：三级结构(偏梯扣))',
          kccc: '开窗尺寸mm',
          kjqd: '抗挤强度',
          kx: '扣型',
          lx: '类型',
          mdc: '目的层',
          mmzl: '每米重量kg/m',
          nj: '内径',
          op: '操作',
          qk: '区块',
          qsjs: '起始井深',
          qssd: '井段-顶深',
          sjjm: '设计井名',
          sl: '数量(根)',
          stlx: '四通类型',
          sxtgcd: '所需套管长度m',
          tgcc: '套管尺寸mm',
          tgcc_p: '套管尺寸mm(外径)',
          tglx: '套管类型',
          tgtcc: '套管头尺寸',
          tgtcc_p: '套管头尺寸(下/上)',
          tj: '通径',
          wj: '外径',
          wjbh: '外径&壁厚',
          wjgy: '完井工艺',
          xcgsl: '需采购数量',
          xh: '序号',
          xn: '性能',
          xz: '线重',
          yqc: '油气藏',
          yqc_qc: '气藏',
          yqc_yc: '油藏',
          zc: '总长m',
          zlLjz: '重量-累计重t',
          zxnqfqd: '最小内屈服强度',
          zzjs: '终止井深',
          zzl: '总重量t',
          zzsd: '井段-底深'
        },
        rule: {
          jth_msg: '井筒号不能为空',
          qsjs_msg: '起始井深不能为空',
          zzjs_msg: '终止井深不能为空',
          tgcc_msg: '套管尺寸不能为空',
          cd_msg: '长度不能为空',
          gj_msg: '钢级不能为空',
          bh_msg: '壁厚不能为空',
          kx_msg: '扣型不能为空',
          jsjg_msg: '井身结构不能为空',
          wjgy_msg: '完井工艺不能为空',
          gzjg_msg: '管柱结构不能为空',
          ext_cysgg_msg: '采油树/采气树规格不能为空',
          ext_stgg_msg: '四通规格不能为空',
          ext_jxgjgg_msg: '井下工具不能为空',
          sxtgcd_msg: '所需套管长度不能为空',
          mmzl_msg: '每米重量不能为空',
          cclx_msg: '储层类型不能为空',
          mdc_msg: '目的层不能为空',
          qk_msg: '区块不能为空',
          jxjg_msg: '井型结构不能为空'
        }
      }
    }
  },
  zjzxbj: {
    title: {
      xjzjfa: '新建钻井方案',
      zjzxsj: '钻井在线设计',
      tgqdjcsjb: '套管强度基础数据表',
      tgqdjhb: '套管强度校核表',
      yk: '一开',
      ek: '二开',
      sk: '三开',
      sik: '四开',
      wk: '五开',
      zjyjcpf: '钻井液配方基础库'
    },
    rule: {
      bxg: '补心高不能为空',
      sjjs: '设计井深不能为空',
      ztcc: '钻头尺寸不能为空',
      qsjs: '起始井深不能为空',
      zzjs: '终止井深不能为空',
      xrjs: '下入井深不能为空',
      qcjs: '起出井深不能为空',
      kzcx: '开钻次序不能为空',
      jylx: '井眼类型不能为空',
      tgcx: '套管程序不能为空',
      zzsd: '底深不能为空',
      qssd: '顶深不能为空',
      tgcc: '套管外径不能为空',
      js: '井深不能为空',
      fdh: '分段号不能为空'

    },
    jth: '井筒号',
    jthRef: '参考井名',
    falx: '方案类型',
    fabj: '方案编辑',
    zxbj: '在线设计',
    mc: '名称',
    lj: '路径',
    sj: '时间',
    cz: '操作',
    xjzjfa: '斜井钻井方案',
    zjzjfa: '直井钻井方案',
    ykjzjfa: '一开井钻井方案',
    ekjzjfa: '二开井钻井方案',
    jcsj: {
      zjk: '钻具库',
      zjypfk: '钻井液配方库',
      tgk: '套管库',
      snjpfk: '水泥浆配方库',
      gjfjk: '固井附件库',
      tgcjgk: '套管串结构库'
    },
    form: {
      jth: '井筒号',
      falx: '方案类型',
      lx: '类型',
      lx_msg: '请选择类型',
      sjj: '设计井',
      czj: '参考井',
      czj_msg: '请选择参考井'

    },
    zj: {
      jcsjb: '基础数据表',
      jszlyq: '井身质量要求',
      gdsj: '轨道设计',
      bdsj: '靶点数据',
      gdfdcs: '轨道分段参数',
      gdgdsj: '轨道各点数据',
      jsjgsjb: '井身结构设计表',
      zjzh: '钻具组合',
      zzqdjh: '钻柱强度校核',
      zjysj: '钻井液设计',
      tjjs: '体积计算',
      jcsj: '基础数据',
      jzjs: '加重计算',
      zjyxn: '钻井液性能',
      zjyjcpf: '钻井液基本配方',
      zjyjbsj: '钻井液基本数据',
      zjyclhz: '钻井液材料汇总',
      zjyclcc: '钻井液材料储存',
      ztxxjzjcssj: '钻头选型及钻井参数设计',
      ztxx: '钻头选型',
      slcssj: '水力参数设计',
      tgzsj: '套管柱设计',
      tgqdsjb: '套管强度数据表',
      tgqdjh: '套管强度校核',
      snjpf: '水泥浆配方',
      zjzqsj: '钻井周期设计',
      zjsdyc: '钻井速度预测',
      sgjdap: '施工进度安排',
      djzhzb: '单井综合指标',
      zydcjb: '钻遇地层简表',
      gdsjb: '轨道设计表',
      dcylyc: '地层压力预测',
      zjytx: '钻井液体系',
      tgjcsjb: '套管基础数据表',
      gcctggjzyfj: '各层次套管固井主要附件',
      gcctgcjgsjb: '各层次套管串结构数据表',
      fzqafwzjsl: '扶正器安放位置及数量',
      gjsj: '固井设计',
    },
    wj: {

    },
    table: {
      apilbhdsx: 'API滤饼厚度上限,mm',
      apilbhdxx: 'API滤饼厚度下限,mm',
      apilslsx: 'API滤失量上限,mL',
      apilslxx: 'API滤失量下限,mL',
      bdbhj: '靶点闭合距',
      bdcs: '靶点垂深',
      bdfwj: '靶点方位角',
      bdhzb: '靶点横坐标',
      bdmc: '靶点名称',
      bdzzb: '靶点纵坐标',
      bfyq: '避缝要求',
      bh: '壁厚mm',
      bhfw: '闭合方位,°',
      bhj: '闭合距',
      bsgl: '比水功率',
      bssd: '喷射速度',
      bxg: '补心高',
      by1: '泵压',
      bysx: '泵压上限',
      byxx: '泵压下限',
      bz: '备注',
      bzmj: '喷嘴面积',
      bzzh: '喷嘴组合',
      calCcyl: '储层压力',
      calDcyl: '地层压力',
      calFlxs: '浮力系数',
      calJgwj: '接箍外径(mm)',
      calJtljqd: '接头连接强度(KN)',
      calKjqd: '套管抗挤强度(Mpa)',
      calKlqd: '套管抗拉强度(KN)',
      calKnyqd: '抗内压强度(Mpa)',
      calTj: '通径(mm)',
      calYqhhwmd: '油气混合物密度(g/cm³)',
      calYsmd: '盐水密度(g/cm³)',
      calYymd: '原油密度',
      cbtjxs: '储备体积系数',
      cd: '长度m',
      cdxssx: '稠度系数上限',
      cdxsxx: '稠度系数下限',
      cjl: '冲击力',
      clhlsx: 'Cl-含量上限，mg/L',
      clhlxx: 'Cl-含量下限，mg/L',
      clmc: '材料名称',
      clxh: '材料类型',
      clzj: '材料总价(元)',
      cldj: '材料单价(元/吨)',
      clzz: '材料总重',
      cs: '厂商',
      chuis: '垂深',
      cw: '层位',
      cxwz: '测线位置',
      czl: '纯钻效率',
      czsj: '纯钻时间h',
      czsx: '纯钻时效%',
      czts: '纯钻台时',
      dc: '段长m',
      dcdh: '地层代号',
      dcmc: '地层名称',
      dcyldlmdsx: '地层压力当量密度上限,g/cm³',
      dcyldlmdxx: '地层压力当量密度下限,g/cm³',
      dj: '单价',
      djsd1: '顶界深度m',
      djsd2: '底界深度m',
      dlwz: '地理位置',
      dmhb: '地面海拔(m)',
      dmxhl: '地面循环量',
      dqlsx: '动切力上限,Pa',
      dqlxx: '动切力下限,Pa',
      duan: '段',
      dw: '单位',
      dwzl1: '单位重量1(kg/m)',
      dwzl2: '单位重量2(N/m)',
      dxdm: '地形地貌及交通情况',
      dz: '套管单重(kg/m)',
      ezb: 'E坐标',
      fdh: '分段号',
      fl: '分类',
      fwj: '方位角,°',
      fxts: '风险提示',
      fzqlx: '扶正器类型',
      fzqjj: '扶正器间距',
      fzqsl: '扶正器数量',
      gcmd: '工程目的',
      gj: '钢级',
      gjyl: '固井用量/t',
      gjgy: '固件工艺',
      gtqf: '套管管体屈服(KN)',
      gxhlsx: '固相含量上限，%',
      gxhlxx: '固相含量下限，%',
      gzwz: '构造位置',
      hkfs: '环空返速',
      hslsx: '含砂量上限，%',
      hslxx: '含砂量下限，%',
      hthplbhdsx: 'HTHP滤饼厚度上限,mm',
      hthplbhdxx: 'HTHP滤饼厚度下限,mm',
      hthplslsx: 'HTHP滤失量上限,mL',
      hthplslxx: 'HTHP滤失量下限,mL',
      jb: '井别',
      jdds1: '顶界深度,m',
      jdds2: '底界深度,m',
      jdwy: '井底位移(m)',
      jglx: '套管类型',
      jgnj: '接箍内径mm',
      jgwj: '接箍外径(mm)',
      jhsgts: '计划施工天数,d',
      jie: '界',
      jjkdl: '井径扩大率%',
      jjzq: '建井周期d',
      jkhzb: '井口坐标-横坐标',
      jkqk: '井口情况',
      jkzzb: '井口坐标-纵坐标',
      jl: '加重，kg/m³',
      jlClmcjdh: '材料名称及代号',
      jldw: '计量单位',
      jlsx: '上限',
      jlxx: '下限',
      jlzdy: '自定义',
      jql10fsx: '静切力10m上限,Pa',
      jql10fxx: '静切力10m下限,Pa',
      jql10msx: '静切力10s上限,Pa',
      jql10mxx: '静切力10s下限,Pa',
      js: '井深,m',
      jsjgTgwj: '井身结构-套管外径×下深',
      jsjgZtzj: '井身结构-钻头直径×钻深',
      jth: '井号(井代码)',
      jth_zh: '井名(中文)',
      jtljqd: '接头连接强度(KN)',
      jtrj: '井筒容积m³',
      jtrj_msg: '井筒容积(上层套管容积)m³',
      jtzxnqfqd: '接头最小内屈服强度(MPa)',
      jx: '井型',
      jxj: '井斜角,°',
      jhmc: '简化名称',
      wlmx: '物料描述',
      jxzs: '机械钻速,m/h',
      jylx: '井眼类型',
      jylx_msg: '井眼类型，裸眼、套管',
      jyzj: '井眼直径',
      jzhmd: '加重后密度g/cm³',
      jzhtj: '加重后体积m³',
      jzjcb: '加重剂储备/t',
      jzjmd: '加重剂密度g/cm³',
      jzjyl: '加重剂用量/t',
      jzqmd: '加重前密度g/cm³',
      kc: '开次',
      khlsx: '钾离子含量上限',
      khlxx: '钾离子含量下限',
      kjqd: '抗挤强度(Mpa)',
      klAqxs: '抗拉-安全系数',
      klqd: '抗拉强度(KN)',
      klZdzh: '抗拉-最大载荷kN',
      knqd: '抗扭强度(N.)',
      knyAqxs: '抗内压-安全系数',
      knyqd: '抗内压强度',
      knyZdzh: '抗内压-最大载荷MPa',
      kqLb: '类别',
      kqXkzh: '许可证号',
      kqXmmc: '项目名称',
      kqYxqq: '有效期起',
      kqYxqz: '有效期止',
      kwjAqxs: '抗外挤-安全系数',
      kwjZdzh: '抗外挤-最大载荷MPa',
      kx: '扣型',
      kzcx: '开钻次序',
      kzcx_msg: '开钻次序,一开，二开',
      ldndsx: '漏斗黏度上限,s',
      ldndxx: '漏斗黏度下限,s',
      ljsgts: '累计施工天数,d',
      lxzssx: '流性指数上限',
      lxzsxx: '流性指数下限',
      lyrj: '裸眼容积',
      mdc: '完钻层位',
      mmzl: '每米单重',
      nbnzxssx: '泥饼粘滞系数上限',
      nbnzxsxx: '泥饼粘滞系数下限',
      nj: '内径mm',
      nzb: 'N坐标',
      op: '操作',
      phsx: 'pH值上限',
      phxx: 'pH值下限',
      pl: '排量',
      plsx: '排量上限,L/s',
      plxx: '排量下限,L/s',
      plyldlmdsx: '破裂压力当量密度上限,g/cm³',
      plyldlmdxx: '破裂压力当量密度下限,g/cm³',
      prtsx: '膨润土上限，kg/m³',
      prtxx: '膨润土下限，kg/m³',
      qcjs: '起出井深,m',
      qjbhl: '全角变化率',
      qsjs: '起始井深,m',
      qssd: '顶深',
      qun: '群',
      qxzl: '气象资料',
      remarks: '备注',
      sh: '视厚',
      shl2: '损耗量',
      sjcs: '设计垂深(m)',
      sjjs: '设计井深(m)',
      sl: '数量，t',
      slsx: '上限',
      slxx: '下限',
      slzdy: '自定义',
      sm: '说明',
      snfgd: '水泥封固段',
      snjmd: '水泥浆密度',
      snjpf: '水泥浆配方',
      snjtx: '水泥浆体系',
      sort: '分类',
      spwy: '水平位移',
      sxndsx: '塑性黏度上限,mPa.s',
      sxndxx: '塑性黏度下限,mPa.s',
      tgbh: '套管壁厚(mm)',
      tgcc: '套管外径,mm',
      tgcx: '套管程序',
      tgds: '套管顶深,m',
      tggj: '套管钢级',
      tgkx: '套管扣型',
      tgnj: '套管内径mm',
      tgrj: '套管容积',
      tgwj: '外径(mm)',
      tgxs: '套管下深,m',
      tgcjg: '套管串结构',
      tiji: '体积',
      tj: '通径(mm)',
      tong: '统',
      ttyldlmdsx: '坍塌压力当量密度上限,g/cm³',
      ttyldlmdxx: '坍塌压力当量密度下限,g/cm³',
      tyxl: '台月效率m/mon',
      wj: '外径mm',
      wzyz: '终孔原则',
      xh: '序号',
      xhyj: '循环压降',
      xi: '系',
      xrjs: '下入井深,m',
      xzyj: '选择依据',
      yd: '亚段',
      ysdm: '颜色代码',
      yxms: '岩性描述',
      zdjxj: '井斜',
      zgcc: '钻杆尺寸',
      zjdzrw: '钻井地质任务',
      ytytj: '用途与条件',
      zjsyl: '重晶石用量/t',
      zjts: '钻井台时',
      zjts1: '钻井天数',
      zjylx: '钻井液类型',
      zjymd: '钻井液密度',
      zjymdsx: '钻井液密度上限,g/cm³',
      zjymdxx: '钻井液密度下限,g/cm³',
      zjytx: '钻井液体系',
      zjzh: '钻具组合',
      zjzq: '钻井周期d',
      zjzyxm: '作业项目',
      zl: '总量',
      zlLjz: '重量-累计重t',
      zlMmzl: '每米重量',
      zq: '周期/d',
      zs: '机械钻速',
      zssx: '转速上限,r/min',
      zsxx: '转速下限,r/min',
      ztcc: '钻头尺寸,mm',
      ztj: '总体积m³',
      ztxhtj1: '钻头型号',
      ztyj: '钻头压降',
      ztyl: '钻头用量',
      ztzj: '钻头直径,mm',
      zu: '组',
      zxdjs1: '造斜点井深',
      zxnqfqd: '套管最小内屈服强度(Mpa)',
      zxyl: '总需要量m³',
      zysx: '钻压上限,kN',
      zyxx: '钻压下限,kN',
      zyysmc: '主要岩石名称',
      zzjs: '终止井深,m',
      zzmbbhfw: '闭合方位°',
      zzmbbhj: '闭合距m',
      zzmbcs: '垂深m',
      zzsd: '底深',
      wlms: '物料描述'
    },
    btn: {
      cx: '查询',
      tj: '添加',
      kqbjms: '开启编辑模式',
      bj: '编辑',
      cr: '插入',
      sc: '删除',
      gb: '关闭',
      bc: '保存',
      tj1: '提交',
      zxsj: '在线设计',
      fabj: '方案编辑',
      cancel: '取消',
      confirm: '确定',
      dqcr: '读取插入',
      hy: '还原'

    },
    msg: {
      saving: '数据正在保存中',
      saveSuccess: '数据保存成功',
      dataValite: '数据校验失败，请填写完整',
      oneData: '请选取一条数据',
      dkf: '待开发'
    }
  },
  wjzxbj: {
    table: {
      jth: '井筒号',
      jthRef: '参考井名',
      falx: '方案类型',
      fabj: '方案编辑',
      zxbj: '在线设计',
      mc: '名称',
      lj: '路径',
      sj: '时间',
      cz: '操作',
      gjmc: '工具名称',
      dw: '单位',
      sl: '数量',
      wj: '外径',
      nj: '内径',
      gg: '规格',
      jtlx: '井筒类型',
      tgcc: '套管尺寸',
      tgbh: '套管壁厚',
      ygwj: '油管外径',
      ygbh: '油管壁厚',
      lycc: '裸眼尺寸',
      dj: '顶界',
      xs: '下深',
      dwnrj: '单位内容积',
      tg_ygcd: '套管/油管长度',
      tg_ygrj: '套管/油管容积',
      op: '操作',
      jc: '基础'
    },
    form: {
      lx: '类型',
      lx_msg: '请选择类型',
      sjj: '设计井',
      czj: '参考井',
      czj_msg: '请选择参考井',
      jth: '井筒号'

    },
    title: {
      xjwjfa: '新建完井方案',
      wjzxsj: '完井在线设计',
      gzjgjcb: '管柱结构基础表',
      ljgzjgb: '邻井管柱结构表'
    },
    btn: {
      sc: '删除',
      cx: '查询',
      zxsj: '在线设计',
      fabj: '方案编辑',
      xz: '新增',
      zjcr: '中间插入',
      mwcr: '末尾插入',
      scxz: '删除选中',
      bc: '保存',
      js: '计算',
      cr: '插入',
      tj: '添加',
      dr: '导入',
      dqcr: '读取插入',
      cancel: '取消',
      confirm: '确定'
    },
    msg: {
      oneData: '只能选取一条数据',
      selectData: '请选中数据'
    },
    jcsj: {
      menu: {
        gzjgjcsj: '管柱结构基础数据'
      }
    },
    wjfa: {
      menu: {
        jcsjb: '基础数据表',
        zydcjb: '钻遇地层简表',
        njlsqktjb: '泥浆漏失情况统计表',
        jtrjb: '井筒容积表',
        ljdcylcgb: '邻井地层压力成果表',
        gzjgb: '管柱结构表',
        qqjksyqk: '前期井口试压情况'
      },
      title: '完井方案',
      msg: {
        warning1: '数据填写不完整，无法计算'
      }
    }
  },
  vxe: {
    error: {
      groupFixed: '如果使用分组表头，固定列必须按组设置',
      groupMouseRange: '分组表头与 "{{0}}" 不能同时使用，这可能會出現错误',
      scrollErrProp: '启用虚拟滚动后不支持该参数 "{{0}}"',
      scrollXNotGroup: '横向虚拟滚动不支持分组表头，请修改正确 "scroll-x.gt" 的参数，否则可能会导致出现错误',
      errConflicts: '参数 "{{0}}" 与 "{{1}}" 有冲突',
      unableInsert: '无法插入到指定位置，请检查参数是否正确',
      useErr: '安装 "{{0}}" 模块时发生错误，可能顺序不正确，依赖的模块需要在 Table 之前安装',
      barUnableLink: '工具栏无法关联表格',
      expandContent: '展开行的插槽应该是 "content"，请检查是否正确',
      reqModule: '缺少 "{{0}}" 模块',
      reqProp: '缺少必要的 "{{0}}" 参数，这可能会导致出现错误',
      emptyProp: '参数 "{{0}}" 不允许为空',
      errProp: '不支持的参数 "{{0}}"，可能为 "{{1}}"',
      fieldRepet: 'column.{{0}}="{{1}}" 重复了，这可能会导致某些功能无法使用',
      notFunc: '方法 "{{0}}" 不存在',
      notSlot: '插槽 "{{0}}" 不存在',
      noTree: '树结构不支持 "{{0}}"',
      notProp: '不支持的参数 "{{0}}"',
      delFunc: '方法 "{{0}}" 已废弃，请使用 "{{1}}"',
      delProp: '参数 "{{0}}" 已废弃，请使用 "{{1}}"',
      delEvent: '事件 "{{0}}" 已废弃，请使用 "{{1}}"',
      removeProp: '参数 "{{0}}" 已废弃，不建议使用，这可能会导致出现错误',
      errFormat: '全局的格式化内容应该使用 "VXETable.formats" 定义，挂载 "formatter={{0}}" 的方式已不建议使用',
      notType: '不支持的文件类型 "{{0}}"',
      notExp: '该浏览器不支持导入/导出功能',
      impFields: '导入失败，请检查字段名和数据格式是否正确',
      treeNotImp: '树表格不支持导入'
    },
    renderer: {
      search: '搜索',
      cases: {
        equal: '等于',
        unequal: '不等于',
        gt: '大于',
        ge: '大于或等于',
        lt: '小于',
        le: '小于或等于',
        begin: '开头是',
        notbegin: '开头不是',
        endin: '结尾是',
        notendin: '结尾不是',
        include: '包含',
        exclude: '不包含',
        between: '介于',
        custom: '自定义筛选',
        insensitive: '不区分大小写'
      },
      combination: {
        menus: {
          sortAsc: '升序',
          sortDesc: '降序',
          fixedColumn: '锁定列',
          fixedGroup: '锁定组',
          cancelFixed: '取消锁定',
          fixedLeft: '锁定左侧',
          fixedRight: '锁定右侧',
          clearFilter: '清除筛选',
          textOption: '文本筛选',
          numberOption: '数值筛选'
        },
        popup: {
          title: '自定义筛选的方式',
          currColumnTitle: '当前列：',
          and: '与',
          or: '或',
          describeHtml: '可用 ? 代表单个字符<br/>用 * 代表任意多个字符'
        },
        empty: '(空白)',
        notData: '无匹配项'
      }
    },
    pro: {
      area: {
        mergeErr: '无法对合并单元格进行该操作',
        multiErr: '无法对多重选择区域进行该操作'
      },
      fnr: {
        title: '查找和替换',
        findLabel: '查找',
        replaceLabel: '替换',
        findTitle: '查找内容：',
        replaceTitle: '替换为：',
        tabs: {
          find: '查找',
          replace: '替换'
        },
        btns: {
          findNext: '查找下一个',
          findAll: '查找全部',
          replace: '替换',
          replaceAll: '替换全部',
          cancel: '取消'
        },
        header: {
          seq: '#',
          cell: '单元格',
          value: '值'
        },
        empty: '(空值)',
        recordCount: '已找到 {{0}} 个单元格',
        notCell: '找不到匹配的单元格',
        replaceSuccess: '成功替换 {{0}} 个单元格'
      }
    },
    table: {
      emptyText: '暂无数据',
      allTitle: '全选/取消',
      seqTitle: '#',
      confirmFilter: '筛选',
      resetFilter: '重置',
      allFilter: '全部',
      sortAsc: '升序：最低到最高',
      sortDesc: '降序：最高到最低',
      filter: '对所选的列启用筛选',
      impSuccess: '成功导入 {{0}} 条记录',
      expLoading: '正在导出中',
      expSuccess: '导出成功',
      expFilename: '导出_{{0}}',
      expOriginFilename: '导出_源_{{0}}',
      customTitle: '列设置',
      customAll: '全部',
      customConfirm: '确认',
      customRestore: '还原'
    },
    grid: {
      selectOneRecord: '请至少选择一条记录！',
      deleteSelectRecord: '您确定要删除所选记录吗？',
      removeSelectRecord: '您确定要移除所选记录吗？',
      dataUnchanged: '数据未改动！',
      delSuccess: '成功删除所选记录！',
      saveSuccess: '保存成功！',
      operError: '发生错误，操作失败！'
    },
    pager: {
      goto: '前往',
      pagesize: '{{0}}条/页',
      total: '共 {{0}} 条记录',
      pageClassifier: '页',
      prevPage: '上一页',
      nextPage: '下一页',
      prevJump: '向上跳页',
      nextJump: '向下跳页'
    },
    alert: {
      title: '消息提示'
    },
    button: {
      confirm: '确认',
      cancel: '取消'
    },
    import: {
      modes: {
        covering: '覆盖',
        insert: '新增'
      },
      impTitle: '导入参数设置',
      impFile: '文件名',
      impSelect: '选择文件',
      impType: '文件类型',
      impOpts: '导入选项',
      impConfirm: '导入'
    },
    export: {
      types: {
        csv: 'CSV (逗号分隔)(*.csv)',
        html: '网页(*.html)',
        xml: 'XML 数据(*.xml)',
        txt: '文本文件(制表符分隔)(*.txt)',
        xlsx: 'Excel 工作簿(*.xlsx)',
        pdf: 'PDF (*.pdf)'
      },
      modes: {
        current: '当前数据（当前页的数据）',
        selected: '选中数据（当前页选中的数据）',
        all: '全量数据（包括所有分页的数据）'
      },
      expTitle: '导出参数设置',
      expName: '文件名',
      expNamePlaceholder: '请输入文件名',
      expSheetName: '标题',
      expSheetNamePlaceholder: '请输入标题',
      expType: '保存类型',
      expMode: '要导出的数据',
      expCurrentColumn: '全部字段',
      expColumn: '要导出的字段',
      expOpts: '导出选项',
      expOptHeader: '表头',
      expHeaderTitle: '是否需要导出表头',
      expOptFooter: '表尾',
      expFooterTitle: '是否需要导出表尾',
      expOptOriginal: '源数据',
      expOriginalTitle: '是否需要导出源数据，如果勾上则支持导入到表格中',
      expPrint: '打印',
      expConfirm: '导出'
    },
    modal: {
      zoomIn: '最大化',
      zoomOut: '还原',
      close: '关闭'
    },
    form: {
      folding: '收起',
      unfolding: '展开'
    },
    toolbar: {
      import: '导入',
      export: '导出',
      print: '打印',
      refresh: '刷新',
      zoomIn: '全屏',
      zoomOut: '还原',
      custom: '列设置',
      customAll: '全部',
      customConfirm: '确认',
      customRestore: '还原'
    },
    input: {
      date: {
        m1: '01 月',
        m2: '02 月',
        m3: '03 月',
        m4: '04 月',
        m5: '05 月',
        m6: '06 月',
        m7: '07 月',
        m8: '08 月',
        m9: '09 月',
        m10: '10 月',
        m11: '11 月',
        m12: '12 月',
        monthLabel: '{{0}} 年',
        dayLabel: '{{0}} 年 {{1}}',
        labelFormat: {
          date: 'yyyy-MM-dd',
          datetime: 'yyyy-MM-dd HH:mm:ss',
          week: 'yyyy 年第 WW 周',
          month: 'yyyy-MM',
          year: 'yyyy'
        },
        weeks: {
          w: '周',
          w0: '周日',
          w1: '周一',
          w2: '周二',
          w3: '周三',
          w4: '周四',
          w5: '周五',
          w6: '周六'
        },
        months: {
          m0: '一月',
          m1: '二月',
          m2: '三月',
          m3: '四月',
          m4: '五月',
          m5: '六月',
          m6: '七月',
          m7: '八月',
          m8: '九月',
          m9: '十月',
          m10: '十一月',
          m11: '十二月'
        }
      }
    }
  }
}
