import router from './common_router'
import store from './common_store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken } from '@/framework/utils/auth' // getToken from cookie
import { addRocketListener } from '@/framework/libs/im/Rocket'
import { getBaseData } from '@/SZGZ'
import { getJTSJLRBaseData } from '@/JTSJLR'
import { getJhList } from './SZGZ/api/well'
import { Message } from 'element-ui'
import { deleteDb } from './SZGZ/views/dataVerification/utils/indexDB'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

// permissiom judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

const whiteList = ['/mainHome', '/libray', '/authredirect', '/sinopecAuth/redirect', '/sinopecAuth/callback', '/register', '/sinopecAuth/registerRedirect', '/sinopecAuth/registerCallback'] // no redirect whitelist

/**
 * 快速登录截取url参数
 *
 * @returns
 */
function GetRequest() {
  var url = decodeURI(location.search) //获取url中"?"符后的字串
  var theRequest = new Object()
  if (url.indexOf("?") != -1) {
    var str = url.substr(1)
    var strs = str.split("&")
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1])
    }
  }
  return theRequest
}

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  let toArr = to.path.split('/')
  if (toArr[1] === 'quickLogin') {
    var Request = undefined
    Request = GetRequest()
    var id = Request["id"] || ''
    var name = Request["name"] || ''
    var username = Request["username"] || 'testShow'
    let admin = {
      username: username,
      password: 'sripe!23$',
      loginUserType: 'test'
    }
    store.dispatch('LoginByUsername', admin).then(() => {
      store.dispatch('GetUserInfo').then(res => { // 拉取user_info
        addRocketListener(res)
        store.dispatch('LoadUserMenuTree').then(data => {
          const roles = data || []
          // store.dispatch('LoadUserMenuTree', {}) // 获取全部可看井列表
          store.dispatch('GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            store.dispatch('SZGZwell/loadGlobal')
              .then(() => {
                store.dispatch('SZGZwell/unitFactoryInit')
                Promise.all([store.dispatch("SZGZwell/loadPage", "historyTrack"), store.dispatch("SZGZwell/loadPage", "meterTrack"), store.dispatch("SZGZwell/loadGKColors")]).then(() => { // 曲线的必要资源，优化可将它移走
                  if (name) {
                    getJhList({
                      jth: '',
                      blockId: '',
                      istransitMudlog: 1,
                      drillstatusId: '正钻,完钻,完井中',
                      oilcompanyId: ''
                    })
                      .then(({ data }) => {
                        data.forEach(element => {
                          if (element.WELLBORENAME === name) {
                            id = element.WELLBOREID
                          }
                        })
                        if (id === '') {
                          name = ''
                          id = ''
                          Message({
                            message: '请输入一个正确的正钻井筒号！',
                            type: 'error'
                          })
                        }
                        store.dispatch('SZGZwell/GetWellName', name)
                        store.dispatch('SZGZwell/GetWellNum', id)
                        if (!toArr[2]) {
                          next({ path: '/dp', replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                        } else {
                          next({ path: '/dp' + '/' + toArr[2], replace: true })
                        }
                      })
                  } else {
                    if (!toArr[2]) {
                      next({ path: '/dp', replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                    } else {
                      next({ path: '/dp' + '/' + toArr[2], replace: true })
                    }
                  }
                })
              })
          })
        })
      })
    })
    return
  }
  // 胜利免登录
  if (toArr[1] === 'shengLiDrill') {
    let token = GetRequest().token || null
    let noMenu = GetRequest().noMenu || null
    let toPath = '/' + toArr.splice(2, toArr.length - 1).join('/')
    if (Number(noMenu)) {
      store.commit('SET_HAS_MENU', false)
    }
    if (token) {
      setToken(token)
      store.commit('SET_TOKEN', getToken())
      next({ path: toPath, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
    } else {
      next('/mainHome') // 否则全部重定向到登录页
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
    return
  }
  // 勘探院系统免登录
  if (toArr[1] === 'kantanDrill') {
    Request = GetRequest()
    var id = Request["id"] || ''
    var name = Request["name"] || ''
    let token = GetRequest().token || null
    let noMenu = GetRequest().noMenu || null
    let toPath = '/' + toArr.splice(2, toArr.length - 1).join('/')
    setToken(token)
    store.commit('SET_TOKEN', getToken())
    if (Number(noMenu)) {
      store.commit('SET_HAS_MENU', false)
    }
    if (name) {
      getJhList({
        jth: '',
        blockId: '',
        istransitMudlog: 1,
        drillstatusId: '正钻,完钻,完井中',
        oilcompanyId: ''
      }).then(({ data }) => {
        data.forEach(element => {
          if (element.WELLBORENAME === name) {
            id = element.WELLBOREID
          }
        })
        if (id === '') {
          name = ''
          id = ''
        }
        store.dispatch('SZGZwell/GetWellName', name)
        store.dispatch('SZGZwell/GetWellNum', id)
        if (token) {
          next({ path: toPath, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        } else {
          next('/mainHome') // 否则全部重定向到登录页
          NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
        }
      })
    } else {
      if (token) {
        next({ path: toPath, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
      } else {
        next('/mainHome') // 否则全部重定向到登录页
        NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
    return
  }
  if (getToken()) { // determine if there has token
    /* has token*/
    if (to.path === '/mainHome') {
      next({ name: 'portal' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (store.getters.user === '') { // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetUserInfo').then(res => { // 拉取user_info
          try {
            addRocketListener(res)
          } catch (error) {
          }
          store.dispatch('LoadUserMenuTree').then(data => {
            const roles = data || []
            store.dispatch('GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
              router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
              // next({...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              // 进入业务模块
              try {
                if (to.path.split('/')[1] === 'optimizationanalysis' || to.path.split('/')[1] === 'well' || to.path.split('/')[1] === 'gasManage' || to.path.split('/')[1] === 'zjfxfk') {
                  // 随钻跟踪业务模块初始化数据
                  getBaseData(res)
                    .then(() => {
                      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                    })
                    .catch((err) => {
                      // console.log(err)
                    })
                } else if (to.path.split('/')[1] === 'staticBase') {
                  getJTSJLRBaseData(res)
                    .then(() => {
                      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                    })
                    .catch((err) => {
                      // console.log(err)
                    })
                } else {
                  next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                }
                return
              } catch (error) {
                console.log('error=>', error);
              }

            })
          })
        }).catch((err) => {
          store.dispatch('FedLogOut').then(() => {
            // Message.error('Verification failed, please login again')
            next({ path: '/mainHome' })
          })
        })
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(store.getters.roles, to.meta.roles)) {
          next() //
        } else {
          next({ path: '/401', replace: true, query: { noGoBack: true } })
        }
        // 可删 ↑
      }
    }
  } else {
    deleteDb()
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next('/mainHome') // 否则全部重定向到登录页
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
