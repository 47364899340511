<template>
  <div class="tags-view-container">
    <scroll-pane class='tags-view-wrapper' ref='scrollPane'>
      <router-link ref='tag' @click.native="selectTag(tag)" class="tags-view-item" :class="isActive(tag) ? 'active' : ''"
        v-for="tag in Array.from(visitedViews)" :to="tag.path" :key="tag.path + tag.wellName"
        @contextmenu.prevent.native="openMenu(tag, $event)">
        {{ tags.includes(tag.title) ? tag.wellName ? `${generateTitle(tag.title)}-${tag.wellName}` :
          generateTitle(tag.title) : generateTitle(tag.title) }}
        <span class='closebtn' @click.prevent.stop='closeSelectedTag(tag)'></span>
      </router-link>
      <!-- <Button class="btn">x</Button> -->
    </scroll-pane>
    <ul class='contextmenu' v-show="visible" :style="{ left: left + 'px', top: top + 'px' }">
      <li @click="closeSelectedTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags">关闭全部</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/framework/components/ScrollPane'
import { generateTitle } from '@/framework/utils/i18n'
import { mapGetters } from 'vuex';
export default {
  components: {
    ScrollPane
  },
  data() {
    return {
      tags: ['wellNew', 'realtime', 'historyData', 'schedul', 'daily', 'timeliness', 'meterData', 'TestRealData', 'realTimeWarning'],
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    }
  },
  computed: {
    ...mapGetters(['SZGZ_wellName', 'SZGZ_wellNum', 'WJCS_wellName']),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    }
  },
  watch: {
    $route() {
      this.addViewTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        window.addEventListener('click', this.closeMenu)
      } else {
        window.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.addViewTags()
  },
  methods: {
    generateTitle, // generateTitle by vue-i18n
    generateRoute() {
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      if (route.name === 'wellNew' && this.$route.name === 'wellNew' || route.name === 'realDataDp' || route.name === 'realtime' && this.$route.name === 'realtime' || route.name === 'realTimeWarning' && this.$route.name === 'realTimeWarning') {
        if (this.SZGZ_wellName) {
          return route.wellName === this.SZGZ_wellName
        } else {
          return true
        }
      } else if (route.name === 'TestRealData' && this.$route.name === 'TestRealData') {
        if (this.WJCS_wellName) {
          return route.wellName === this.WJCS_wellName
        } else {
          return true
        }
      } else {
        return route.path === this.$route.path
      }
    },
    addViewTags() {
      const route = this.generateRoute()
      if (!route) {
        return false
      }
      const routes = Object.assign({}, route)
      // if (this.$route.name === 'wellNew') {
      //   }
      if (this.$route.name !== 'TestRealData') {
        routes['wellName'] = this.SZGZ_wellName
        routes['wellNum'] = this.SZGZ_wellNum
      }
      this.$store.dispatch('addVisitedViews', routes)
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag.$el)
            break
          }
        }
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('delVisitedViews', view).then((views) => {
        if (this.isActive(view)) {
          const latestView = views.slice(-1)[0]
          if (latestView) {
            this.$router.push(latestView.path)
            // 实时监测页面特殊处理
            if (latestView.path === '/well/wellNew' || latestView.path === '/dp/realDataDp' || latestView.name === 'realtime' || latestView.name === 'realTimeWarning') {
              this.selectedTag = latestView
              if (!this.$store.state.SZGZwell.wellNum) {
                this.$store.commit('SZGZwell/SET_WELLNUM', latestView.wellNum)
                this.$store.commit('SZGZwell/SET_WELLNAME', latestView.wellName);
              }
              if (this.$store.state.SZGZwell.wellNum !== latestView.wellNum) {
                this.$store.commit('SZGZwell/SET_WELLNUM', latestView.wellNum)
                this.$store.commit('SZGZwell/SET_WELLNAME', latestView.wellName);
              }
            }
            // 完测实时曲线页面特殊处理
            if (latestView.name === 'TestRealData') {
              this.selectedTag = latestView
              if (!this.$store.state.WJCSwell.wellNum) {
                this.$store.commit('WJCSwell/SET_WELLNUM', latestView.wellNum)
                this.$store.commit('WJCSwell/SET_WELLNAME', latestView.wellName);
              }
              if (this.$store.state.WJCSwell.wellNum !== latestView.wellNum) {
                this.$store.commit('WJCSwell/SET_WELLNUM', latestView.wellNum)
                this.$store.commit('WJCSwell/SET_WELLNAME', latestView.wellName);
              }
            }
          } else {
            this.$router.push('/')
            if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
              window.location.href = "about:blank";
              window.close();
            } else {
              window.opener = null;
              window.open("", "_self");
              window.close();
            }
          }
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag.path)
      this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags() {
      this.$store.dispatch('')
      this.$router.push('/')
      if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
    openMenu(tag, e) {
      this.visible = true
      this.selectedTag = tag
      this.left = e.clientX - 219
      this.top = e.clientY - 60
    },
    closeMenu() {
      this.visible = false
    },
    // 切换tag标签
    selectTag(tag) {
      this.selectedTag = tag
      if (tag.path === '/well/wellNew' || tag.path === '/dp/realDataDp' || tag.name === 'realtime' || tag.name === 'realTimeWarning') {
        this.$store.commit('SZGZwell/SET_WELLNUM', tag.wellNum)
        this.$store.commit('SZGZwell/SET_WELLNAME', tag.wellName)
      }
      if (tag.name === 'TestRealData') {
        this.$store.commit('WJCSwell/SET_WELLNUM', tag.wellNum)
        this.$store.commit('WJCSwell/SET_WELLNAME', tag.wellName)
      }
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/framework/styles/variables.scss";
@import "src/framework/styles/const.scss";

.tags-view-container {
  // position: fixed;
  top: $navbar-height;
  width: 100%;
  position: relative;

  .btn {
    float: right;
  }

  .tags-view-wrapper {
    height: $tags-height !important;
    -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

    .tags-view-item {
      display: inline-block;
      position: relative;
      height: $tags-height - 8px;
      line-height: $tags-height - 8px;
      // border: 1px solid #d9d9d9;
      color: #a7abba;
      background: #FFF;
      padding: 0 20px;
      font-size: 14px;

      // margin-left: 5px;
      &:first-of-type {}

      .closebtn {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('../../../assets/close.png');
        background-size: 100% 100%;
        vertical-align: middle;
        margin-left: 10px;
      }

      &.active {
        // background-color: #fff;
        color: #3297ff;
        font-weight: bold;

        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }

        .closebtn {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url('../../../assets/active_close.png');
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }

    @media all and (max-height: 780px) {
      .tags-view-item {
        height: $tags-height - 8px;
        line-height: $tags-height - 8px;
      }
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 1000;
    position: absolute;
    top: 20px;
    left: 26px;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

    li {
      margin: 0;
      padding: 0 22px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }

  @media all and (max-height: 780px) {
    .tags-view-wrapper {
      height: $tags-height !important;
    }
  }
}

@media all and (max-height: 780px) {
  .tags-view-container {
    top: $navbar-height !important;
  }

}
</style>

<style rel="stylesheet/scss" lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transform-origin: 100% 50%;

      &:before {
        transform: scale(.6);
        display: inline-block;
        vertical-align: -3px;
      }

      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
