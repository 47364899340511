// TODO 有没用的代码未删除
import configLoader from '../api/configApi.js'
import axios from 'axios'
// import { getGKColorsConfig } from '../api/well';
// import CryptoUtil from '@/utils/CryptoUtil'
import { selectionformatter } from '../utils'
import getPageConfigBase from '../../framework/public/pageConfigBase.js'
import { unitfactory } from '../libs/unitFactory'

const well = {
    namespaced:true,
    state: {
        unitFactory: {},
        wellName: '',
        wellNum: '',
        pageConfig: {},
        globalConfig: {},
        unitConfig: {},
        dimConfig: {},
        colsConfig: [],
        loginConfig: {},
        limitsConfig: {},
        unitList: [],
        colsList: [],
        paramsConfig: {},
        gkBaseColor: {}
    },
    mutations: {
        SET_UNIT_FACTORY: function(state, config) {
            state.unitFactory = config
        },
        SET_GLOBAL: function(state, config) {
            state.globalConfig = config
        },
        SET_UNIT: function(state, config) {
            state.unitConfig = config
        },
        SET_DIM: function(state, config) {
            state.dimConfig = config
        },
        SET_COLS: function(state, config) {
            state.colsConfig = config
        },

        SET_BORE_COLS: function(state, config) {},

        SET_LITHOLOGY_COLS: function(state, config) {},

        SET_LOG_COLS: function(state, config) {

        },
        SET_PAGEC: function(state, { pageId, data }) {
            state.pageConfig[pageId] = data
        },
        SET_PARAMS: function(state, { pageId, data }) {
            state.paramsConfig[pageId] = data
        },
        SET_LOGIN_CONFIG: function(state, config) {
            state.loginConfig = config
        },
        SET_LIMITS_CONFIG: function(state, config) {
            state.limitsConfig = config
        },
        SET_UNIT_LIST: function(state, unitList) {
            state.unitList = unitList
        },
        SET_COLS_LIST: function(state, colsList) {
            state.colsList = colsList
        },
        SET_GK_COLORS: function(state, colors) {
            state.gkBaseColor = colors
        },
        SET_WELLNAME(state, wellName) {
            state.wellName = wellName
        },
        SET_WELLNUM(state, wellNum) {
            state.wellNum = wellNum
        }
    },
    actions: {
        unitFactoryInit({ commit, state }) {
            return new Promise((resolve, reject) => {
                const data = unitfactory()
                commit('SET_UNIT_FACTORY', data)
                resolve(data)
            })
        },
        setWellName(state, wellName) {
            state.commit('SET_WELLNAME', wellName)
        },
        setWellNum(state, wellNum) {
            state.commit('SET_WELLNUM', wellNum)
        },
        loadGlobal({ commit, state }) {
            if (state.colsConfig.length && state.unitConfig &&
                state.dimConfig && state.unitList.length && state.colsList.length) {
                return Promise.resolve()
            }
            return new Promise((resolve, reject) => {
                configLoader.loadGlobalConfig()
                    .then(axios.spread(function(field, dimentsion, unit) {
                        commit('SET_COLS', field.data)
                        commit('SET_COLS_LIST', selectionformatter(field.data))
                        commit('SET_DIM', dimentsion.data)
                        commit('SET_UNIT', unit.data)
                        commit('SET_UNIT_LIST', selectionformatter(unit.data.unitS))
                        resolve()
                    }))
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        loadPage({ commit, state }, pageId) {
            if (state.pageConfig[pageId]) {
                return Promise.resolve(state.pageConfig[pageId])
            }
            return new Promise((resolve, reject) => {
                configLoader.loadPageConfig(pageId).then(({ data }) => {
                    data = JSON.parse(data.context)
                    commit('SET_PAGEC', { pageId, data })
                    resolve(data)
                }).catch(error => {
                    const data = getPageConfigBase(pageId)
                    commit('SET_PAGEC', { pageId, data })
                    resolve(error)
                })
            })
        },
        loadParams({ commit, state }, pageId) {
            // if (state.paramsConfig[pageId]) {
            //   return Promise.resolve(state.paramsConfig[pageId])
            // }
            return new Promise((resolve, reject) => {
                configLoader.loadParamsConfig(pageId).then(({ data }) => {
                    data = JSON.parse(data.context)
                        // data = data.context
                    commit('SET_PARAMS', { pageId, data })
                    resolve(data)
                }).catch(error => {
                    const data = getPageConfigBase(pageId)
                    commit('SET_PARAMS', { pageId, data })
                    resolve(error)
                })
            })
        },
        savePageConfig({ commit }, { pageId, data }) {
            return new Promise((resolve, reject) => {
                configLoader.savePageConfig(pageId, data).then((res) => {
                    commit('SET_PAGEC', { pageId, data })
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveParamsConfig({ commit }, { pageId, data }) {
            return new Promise((resolve, reject) => {
                configLoader.saveParamsConfig(pageId, data).then((res) => {
                    commit('SET_PARAMS', { pageId, data })
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveDefineDimensionConfig({ commit }, { defineDimension }) {
            return new Promise((resolve, reject) => {
                configLoader.saveDefineDimensionConfig(defineDimension).then(({ data }) => {
                    commit('SET_DIM', data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // loadGKColors({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         getGKColorsConfig().then(({ data }) => {
        //             let arr = {}
        //             data.forEach(item => {
        //                 arr[item.gk] = { color: item.color }
        //             })
        //             commit('SET_GK_COLORS', arr)
        //             resolve()
        //         }).catch(error => {
        //             const BaseGKColors = {
        //                 '钻头离井': { color: '#C4BD97' },
        //                 '回填': { color: '#4A452A' },
        //                 '滑动钻进': { color: '#C3D69B' },
        //                 '倒划眼': { color: '#C00000' },
        //                 '悬停': { color: '#B3A2C7' },
        //                 '接单根': { color: '#E46C0A' },
        //                 '划眼': { color: '#FF0000' },
        //                 '循环': { color: '#558ED5' },
        //                 '下钻': { color: '#7F7F7F' },
        //                 '旋转钻进': { color: '#00B050' },
        //                 '钻进': { color: '#77933C' },
        //                 '起钻': { color: '#D9D9D9' },
        //                 '井底循环': { color: '#C6D9F1' },
        //                 '停钻': { color: '#604A7B' },
        //                 '完钻': { color: '#7FFF00' },
        //                 '待钻': { color: '#FFB90F' },
        //                 '正钻': { color: '#4682B4' },
        //             }
        //             commit('SET_GK_COLORS', BaseGKColors)
        //             resolve()
        //         })
        //     })
        // }
    }
}

export default well