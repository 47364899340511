export default {
  route: {
    dashboard: 'Dashboard',
    systemAdmin: 'systemAdmin',
    systemAdminUser: 'systemAdminUser',
    systemAdminMenu: 'systemAdminMenu',
    systemAdminRole: 'systemAdminRole',
    systemAdminUserGroup: 'systemAdminUserGroup',
    systemAdminResource: 'systemAdminResource',
    systemAdminPermission: 'systemAdminPermission',
    dataDictionary: 'dataDictionary',
    wellManage: 'wellManage',
    wellJK: 'wellMonitor',
    wellRealtime: 'wellRealtime',
    excel: 'excel',
    groupManage: 'GroupManage',
    wellNew: 'wellNew',
    GisShow: 'Well Position',
    WellLogRealTime: 'WellLogRalTime',
    meterData: 'MeterData',
    historyData: 'HistoryData',
    schedul: 'schedul',
    daily: 'daily',
    timeliness: 'timeliness',
    RealtimeMonitoring: 'Realtime Monitoring',
    dimension: 'Dimension',
    fieldDefine: 'Field Define',
    unitDefine: 'Unit Define',
    unitType: 'Unit Type',
    fieldUnitDefine: 'Field Unit Define',
    systemManage: 'user/roleManage',
    otherManage: 'Constant dictionary management',
    businessManage: 'wellManage',
    dimensionManage: 'dimensionManage',
    regionalComplexStatistics: 'Regional complex statistics',
    StaticDataEntry: 'Design data management',
    constructionDataEntry: 'Static data entry',
    StaticDataManage: 'construction data manage',
    mapShow: 'Map display',
    wellCom: 'Contrast of Drilling Progress',
    mulCom: 'Multi well contrast',
    multiWellContrast: 'Adjacent well correlation',
    wellBoreManage: 'wellBore Manage',
    zjgcsjjcsj: 'Basic data of drilling engineering design',
    dcylycsj: 'Formation pressure prediction data',
    sjdcfcsj: 'Design stratigraphic stratification data',
    dxjgdsjfdsj: 'Track design data of directional well',
    jsjgsjsj: 'Well structure design data',
    zjzhsysjsj: 'Bha design data',
    zjyxnsjsj: 'Drilling fluid performance design data',
    szjcsj: 'Basic drilling data',
    szjygj: 'Well trajectory',
    szzjmx: 'Drilling tool details',
    ztsyjhjzjcssjsj: 'Bit usage plan and drilling parameter design data',
    zjgcjdjhsj: 'Drilling engineering schedule data',
    szdcjssj: 'Interpretation data from drilled formations',
    szjsjgsj: 'Real well construction data',
    szzjzhsyqksj: 'Real drill assembly usage data',
    szztsyqksj: 'Actual bit usage',
    zjyqyxncssj: 'Drilling fluid sampling performance test data',
    jdxxsj: 'Well crew information data',
    meettingManage: 'MeettingsManagement',
    surveillance: 'Video surveillance',
    warnGroup: 'Warn Group',
    probabilityLevel: 'Probability Level',
    warnConfig: 'Warn Config',
    warnGroupUser: 'Early warning rules',
    warnManage: 'Warn Manage',
    noticeManage: 'Notice Manage',
    sendNoticeManage: 'Send Notice Manage',
    realtime: 'Well completion monitoring',
    wellTest: 'Test monitoring',
    GisShowTest: 'Gis display',
    gasHistoryData: 'History',
    dailyOver: 'Drills daily',
    usageOfCoring: 'Usage of coring', // 取心使用情况
    constructionWellManagement: 'Remote monitoring',
    szsjdb: 'Comparison of actual drilling design',
    dxjc: 'Directional monitoring',
    ycjc: 'Remote monitoring',
    zjsj: 'Drilling data',
    zjzh: 'Drilling assembly',
    wjsj: 'Completion data',
    wjsjsj: 'Completion design data',
    wjsgsj: 'Completion construction data',
    ljsj: 'Mud logging data',
    cjsj: 'Logging data',
    dzsj: 'geological data',
    sjsj: 'Data audit',
    wjgz: 'Well completion tracking',
    sgjd: 'Construction progress',
    ywfx: 'Delay analysis',
    csgz: 'Test tracking',
    bbgl: 'Report management',
    yjcl: 'Early warning processing',
    ownspace: 'ownspace',
    geologicalOrientation: 'Geological orientation',
    controlSystem: 'Control system diagram',
    realLog: 'Real time curve',
    formationPressure: 'Formation pressure',
    ROPAndSpecificEnergy: 'ROP and specific energy',
    frictionTorqueAnalysis: 'Friction torque analysis',
    functionManagement: 'Function configuration',
    functionalRoleAssociation: 'Functional role Association',
	  wellComp:'Well Completion',
    wellCompList:"Report Filling",
    wellCompAuditor:"Report Verification",
    wellCompView:"Report List",
    xsbtb:"Hour Rpt Fill",
    xsbll:"Hour Rpt Scan",
    wjsxfx:'完井时效分析',
    scjddtgz:'施工进度',
    djrz:'单井日志',
    djscrz:'单井生产日志',
    wjsxyxystj:'延误分析',
    wellCompInfo:'完井施工信息',
    wellCompFill:'完井日报填报',
    wellCompDayReport:'当日完井日报',
    wellCompMonthReport:'完井日报汇总',
    // 工程管理菜单项 start
    // 胜软部分 start
    gcgl:"工程管理",
    gcgk:"工程概况",
    zjgcgl:"钻井工程管理",
    jwbs:"井位部署",
    sjxtsp:"设计协同审批",
    zjjdcx:"钻井监督查询",
    gcgztj:"工程故障统计",
    gcfztj:"工程复杂统计",
    dzkctj:"地质卡层统计",
    qxtj:"取心统计",
    rjctj:"日进尺统计",
    djyxcx:"单井运行查询（钻井工程）",
    cbsyxtj:"承包商运行统计",
    dsfcx:"第三方测斜",
    djrb:"单井日报",
    zdjscjb:"重点井生产简报",
    zjdzrb:"钻井地质日报",
    wjgcgl:"完井工程管理",
    jkgl:"井控管理",
    jkzzztcx:"井控装置状态查询",
    jkzztcx:"井控装置图查询",
    jxqkcx:"检修情况查询",
    jxdqtx:"检修到期提醒",
    djdacx:"单井档案查询",
    tybfglcx:"停用报废查询",
    jkjdcx:"井控监督查询",
    lssjtjfx:"历史数据统计分析",
    tzzysbcx:"特种作业设备查询",
    hssegl:"HSSE管理",
    txwjgl:"体系文件管理",
    flfg:"法律法规",
    bzgf:"标准规范",
    fxyscx:"风险因素查询",
    fxysgl:"风险因素管理",
    zyzds:"作业指导书",
    dtyjkh:"动态业绩考核",
    dwkh:"队伍考核",
    ndkhjg:"年度考核结果",
    lskhjg:"历史考核结果",
    dwdtpm:"队伍动态排名",
    dxpm:"单项排名",
    djjxtjypm:"多井绩效统计与排名",
    dwcx:"队伍查询",
    dwjcxx:"队伍基础信息",
    dwndgzl:"队伍年度工作量",
    ycczls:"异常处置历史",
    mmswzcx:"MMS物资查询",
    gckccx:"管材库存查询",
    djdbgccx:"单井调拨管材查询",
    wzdhgk:"物资订货概况",
    wzck:"物资查询",
    zjglcx:"钻具管理查询",
    zggsfbtj:"钻杆归属分布统计",
    zgjwfbtj:"钻杆井位分布统计",
    zgztcx:"钻杆状态查询",
    zjgsfbtj:"钻具归属分布统计",
    zjjwfbtj:"钻具井位分布统计",
    zjsyjlcx:"钻具使用记录查询",
    zjtynftj:"钻具投用年份统计",
    gzqktjcx:"故障情况统计查询",
    axybtj:"按旬/月报统计",
    regionSet:"Region Config",
    // 胜软部分 end
    // 亚太部分 start
    wjrwxd:"完井任务下达",
    tjwjsj:"提交完井设计",
    zzwjsjsc:"组织完井设计审查",
    wjsjsc:"完井设计审查",
    wjsjxd:"完井设计修订",
    wjsjxdsh:"完井设计修订审核",
    ndgzl:"年度工作量",
    jwbspc:"井位部署批次",
    tjzjsj:"提交钻井设计",
    zzsjsc:"组织设计审查",
    zjsjsc:"钻井设计审查",
    zjsjxd:"钻井设计修订",
    zjsjxdsh:"钻井设计修订审核",
    gcjdtb:"工程节点填报",
    ldhqgl:"流动红旗管理",
    gcpds:"工程评定书",
    rfzwdck:"文档查看",
    rfzwdgl:"文档管理",
    dailyFeeManage:"Daily Fee Manage",
    // 亚太部分 end
    // 工程管理菜单项 end
    // 在线设计菜单项 start
    zjzxsj: "Drill Plan Design",
    zjfatb: "Drill Plan Fill",
    zjfa: "Drill Plan",
    xjzxsj: "New Drill Plan",
    zxsjll: "Drill Plan Review",
    zxsjgl: "Drill Plan Mng",
    wjzxsj: "Comp Plan Design",
    xjwjzxsj: 'New Completion Plan',
    zxwjsjll: 'Completion Plan Review',
    zxwjsjgl: 'Completion Plan Mng',
    ytgjhzxsj: 'Casing&OilPipe Plan',
    ytgjhbz: 'New Casing&OilPipe Plan',
    ytgjhll: 'Casing&OilPipe Plan Review',
    ytgjhgl: 'Casing&OilPipe Plan Mng'
    // 在线设计菜单项 end
  },
  
  // 盛华模块国际化********************START
  wjrb: {
    jh: '井号',
    ksrq: '开始日期',
    jsrq: '结束日期',
    qzrq: '起止日期',
    sj: '时间',
    to: '至',
    zt: '状态',
    xh: '序号',
    shzt: '审核状态',
    sgdw: '施工队伍',
    jl: '井类',
    zylx: '作业类型',
    xj: '星级',
    sydw: '酸压队伍',
    yjtcrq: '预计投产日期',
    wjcw: '完井层位',
    wjfs: '完井方式',
    jd: '井段',
    sjzq: '设计周期/实际周期',
    sgqk: '施工情况',
    xbgx: '下步工序',
    csyy: '超时原因',
    tbrq: '填表日期',
    gxrq: '更新日期',
    cz: '操作',
    zt_qb: '全部',
    zt_yth: '已退回',
    zt_wsh: '未审核',
    zt_ytg: '已通过'
  },
  wjrb_btn: {
    cx: '查询',
    tb: '填报',
    xz: '下载',
    xg: '修改',
    sc: '删除',
    qk: '清空',
    tj: '提交',
    add: '添加',
    yes: '是',
    no: '否',
    confirm: '确定',
    cancel: '取消',
    gdcd: '更多菜单',
    tg: '通过',
    th: '退回'
  },
  wjrb_msg: {
    jh: '请输入井名',
    zt: '请选择状态',
    optionDate: '请选择日期',
    optionSJ: '请选择时间',
    optionWell: '请选择井号',
    jd_msg: '请输入井段,井段之间使用-隔开',
    sjzq_msg: '请输入周期中间-隔开',
    jth_not_null: '井筒号不能为空',
    rq_not_null: '填报日期不能为空',
    add_success: '添加成功',
    add_error: '添加失败',
    update_success: '修改成功',
    umdate_error: '修改失败',
    del_success: '删除成功',
    del_error: '删除失败',
    ts: '温馨提示',
    ts_del: '确定删除这条数据吗',
    ts_qk: '确定清空所有数据',
    select: '请先勾选数据',
    reback_sunccess: '退回成功',
    reback_error: '退回失败',
    comit_success: '提交成功',
    comit_erro: '提交失败',
    rqsj: '请填写时间和日期'
  },
  wjrb_form: {
    tbrq: '填报日期:',
    wjcw: '完井层位:',
    jth: '井筒:',
    wjfs: '完井方式:',
    jl: '井类:',
    jd: '井段:',
    sgdw: '施工队伍:',
    sjzq: '设计周期/实际周期:',
    zylx: '作业类型:',
    xj: '星级:',
    lingx: '0星',
    sgqk: '施工情况:',
    sydw: '酸压队伍:',
    yjtcrq: '预计投产日期:',
    xbgx: '下步工序:',
    csyy: '超时原因:',
    tczt: '投产状态:',
    sw: '上午',
    xw: '下午',
    null: '无',
    tc: '投产',
    jc: '见产'
  },
  zxbj: {
    rename: '重命名',
    download: '下载',
    del: '删除',
    move: '移动到',
    copy: '复制到',
    upload: '上传文件',
    create: '新建',
    cfolder: '新建文件夹',
    cfile: '模版中新建文件',
    back: '返回上一级',
    uncomit: '未提交',
    comit: '提交',
    comited: '已提交',
    sb: '识别',
    ysb: '已识别',
    wsb: '未识别',
    rk: '入库',
    yrk: '已入库',
    rk_error: '入库失败',
    init: '恢复初始',
    update: '编辑',
    cancel: '取消',
    confirm: '确定',
    name: '文件名',
    size: '大小',
    date: '更新日期',
    status: '状态',
    tempName: '模版名称',
    tempNameMsg: '请选择模板',
    createTempTitle: '从模板中新建',
    well: '井',
    wellMsg: '请选择井',
    fileName: '文档名称',
    fileNameMsg: '请输入名称',
    op: '操作',
    jh_msg: '所属井不能为空',
    lx_msg: '所属类型不能为空',
    mb_msg: '模板不能为空',
    initSuccess: '恢复初始成功',
    initError: '恢复初始失败',
    comitSuccess: '提交成功',
    comitError: '数据提交失败',
    select: '请勾选数据',
    distinError: '获取识别列表失败',
    sbError: '识别失败',
    sbing: '努力识别中……',
    initing: '恢复中……',
    nameMsg: '请输入名称',
    msg: '提示',
    nameErrorMsg: '名称长度必须小于100',
    addError: '创建失败',
    addSuccess: '创建成功',
    renameSuccess: '重命名成功',
    delMsg: '确定删除文件？',
    file: '文件',
    delSuccess: '删除成功',
    delError: '删除失败',
    selectMsg: '必须选择一个目标文件夹',
    moveSuccess: '移动文件成功',
    copySuccess: '复制文件成功',
    rukuSuccess: '入库成功',
    wpsVilMsg: 'WPS验证失败，是否重新验证?',
    wpsTitle: 'WPS验证'
  },
  zxbjM: {
    jh: '井号',
    jhMsg: '请选择井',
    name: '报告名称',
    nameMsg: '请输入报告名称',
    query: '查询',
    xh: '序号',
    rktime: '入库时间',
    rksj: '入库数据',
    ckbg: '查看表格',
    view: '浏览',
    down: '下载',
    del: '删除',
    bgmc: '表格名称',
    hs: '行数',
    zt: '状态',
    ysb: '已识别',
    yrk: '已入库',
    op: '操作',
    ck: '查看',
    error: '获取识别列表失败',
    delMsg: '确定删除文件{井:',
    file: '文件',
    delSuccess: '删除成功',
    delError: '删除失败',
    msg: '提示',
    cancel: '取消',
    confirm: '确定'
  },
  xsb: {
    jh: '井号',
    jhMsg: '请选择井',
    lx: '类型',
    lxMsg: '请选择类型',
    qzrq: '起止日期',
    to: '至',
    query: '查询',
    export: '导出',
    set: '参数设置',
    mc: '名称',
    zd: '字段',
    cancel: '取消',
    confirm: '确定',
    lxCX: '掺稀',
    lxJD: '解堵',
    lxQJ: '气举',
    lxZP: '自喷',
    mcMsg: '请输入名称',
    msg: '提示',
    inputmsg: '文件夹名长度必须小于100',
    setSuccess: '参数设置成功'
  }
  // 盛华模块国际化********************END
}
