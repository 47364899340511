export default {
    dailyFee: {
        add: '添加',
        edit: '编辑',
        delete: '删除',
        action: '操作',
        search: '查询',
        file: '文件',
        save: '保存',
        yes: '是',
        not: '否',
        submit: '提 交',
        jhlb: '井号列表',
        jhwh: '井号维护',
        plcx: '评论查询',
        zljs: '资料检索',
        zlwh: '资料维护',
        plsc: '批量删除',
        tjwd: '添加文档',
        tjfj: '添加附件',
        wxz: '未选择任何文件',
        filename: '文件名',
        filetype: '文件类型',
        isAllowDownload: '是否允许下载',
        summary: '摘要',
        author: '作者',
        subdate: '发布日期',
        comment: '评论',
        bjwd: '编辑文档',
        pldx: '评论对象',
        plnr: '评论内容',
        plsj: '评论时间',
        plr: '评论人',
        gjc: '关键词',
        yhxm: '用户姓名',
        xzrq: '选择时间',
        qsrgjc: '请输入关键词',
        xh: '序号',
        download: '下载',
        xgfl: '修改分类',
        scfl: '删除分类',
        tjzl: '添加子类',
        tjgfl: '添加根分类',
        xbqdk: '新标签打开',
        qd: '确定',
        qx: '取消'

    },
}