export default {
    processcenters: {
        fjck: '附件查看',
        jbs: '井部署',
        jhlr: '井号录入',
        jhdr: '井号导入',
        jbspc: '井位部署批次',
        pleaseInput: '请输入',
        query: '查询',
        xzbsjpc: '新增井位部署批次',
        serialNumber: '序号',
        operator: '操作人',
        creationTime: '创建时间',
        wellDeployment: '井部署',
        operation: '操作',
        qsrgjzcx: '请输入关键字查询',
        editor: '编辑',
        ps: '评审',
        toView: '查看',
        taskName: '任务名称',
        sjbg: '设计报告',
        organizePeople: '组织人',
        wellNumberList: '井号列表',
        wellboreName: '井号',
        deployType: '井别',
        wellType: '井型',
        manageDepartment: '管理单位',
        uploadTime: '上传时间',
        sjbgwdfj: '设计报告文档附件',
        zsyj: '主审意见',
        hyjlsmj: '会议结论扫描件',
        remark: '备注',
        zjsc: '钻井审查',
        submit: '提交',
        save: '保存',
        saveSelection: '保存选择',
        cancel: '取消',
        shutDowm: '关闭',
        delete: '删除',
        add: '添加',
        typelx: '类型',
        audit: '审核',
        review: '审查',
        zjxdsh: '钻井修订审核',
        zjxdshxq: '钻井修订审核详情',
        bsyj: '部署依据',
        otherAccessory: '其他附件',
        addWellboreName: '添加井号',
        drWellboreName: '导入井号',
        wTo: '至',
        time: '时间',
        startDate: '起始日期',
        endDate: '结束日期',
        changePeople: '选择人员',
        submitTime: '提交时间',
        reviewTime: '审查时间',
        reviewResults: '审查结果',
        organizeReview: '组织审查',
        yscjlb: '已审查井列表',
        dzzscjlb: '待组织审查井列表',
        zzxssc: '组织线上审查',
        zzxsscxx: '组织线上审查详情',
        zzxxsc: '组织线下审查',
        zzxxscxx: '组织线下审查详情',
        bsjpc: '井位部署批次',
        wscwj: '未上传文件',
        noUpload: '未上传',
        releasePeople: '发布人',
        designPeriod: '设计期限',
        Yes: '是',
        No: '否',
        sfyxfptj: '是否允许分批提交',
        qsrbzxx: '请输入备注信息',
        zsyj: '主审意见',
        sjbgwd: '设计报告文档',
        shsftg: '审核是否通过',
        experts: '专家',
        opinion: '意见',
        expertsOpinion: '专家意见',
        reviewConclusion: '审查结论',
        approvalOpinion: '审批意见',
        qtxjh: '请填写井号',
        qxzjx: '请选择井型',
        qxzgldw: '请选择管理单位',
        revision: '修订',
        shr: '审核人',
        cjr: '创建人',
        sczj: '审查专家',
        zjsjxssczj: '钻井设计线上专家审查',
        zjsjxssc: '钻井设计线上主审审查',
        zjsjxxsc: '线下设计审查会议结论入库',
        zjsjxsscxq: '钻井设计线上审查详情',
        zjsjxxscxq: '钻井设计线下审查详情',
        zjxd: '钻井修订',
        state: '状态',
        sjrw: '设计任务',
        sjrwxq: '设计任务详情',
        fbsjrw: '发布设计任务',
        pc: '批次',
        yfbrw: '已发布任务',
        dfbjhlb: '待发布井号列表',
        zjsjxd: '钻井设计修订',
        zjsjxdxq: '钻井设计修订详情',
        change: '选择',
        ndzjgczlb: '年度钻井工程总览表',
        annual: '年度',
        qxz: '请选择',
        sjrwqdlb: '设计任务清单列表',
        ybsjh: '已部署井号',
        zjgczbjd: '钻井工程中标井队',
        wellDrillCrew: '钻井',
        wellCementationCrew: '固井',
        wellCompletionCrew: '完井',
        oilTestCrew: '试油试气',
        currentConstructionNode: '当前施工环节',
        gcpdjg: '工程评定结果',
        drillLevel: '钻井工程',
        cementationLevel: '固井工程',
        completionLevel: '完井工程',
        totalLevel: '总体评定',
        tjjdrq: '工程节点填报',
        yk: '一开',
        ykgj: '一开固井',
        ek: '二开',
        ekgj: '二开固井',
        sk: '三开',
        skgj: '三开固井',
        sik: '四开',
        sikgj: '四开固井',
        wk: '五开',
        wkgj: '五开固井',
        lk: '六开',
        lkgj: '六开固井',
        qk: '七开',
        qkgj: '七开固井',
        bk: '八开',
        bkgj: '八开固井',
        removal: '搬迁',
        install: '安装',
        months: '月份',
        zjdh: '钻井队号',
        zjgsm: '钻井公司名',
        fgsm: '分公司名',
        xmbm: '项目部名',
        isHostoryWell: '是否历史井',
        jwlzrq: '井位论证日期',
        changeDate: '选择日期',
        sjbgzl: '设计报告资料',
        auditStatus: '审核状态',
        pass: '通过',
        noPass: '不通过',
        all: '全部',
        pending: '待审批',
        pendingReplace: '待替换',
        replaced: '已替换',
        materialsReplace: '资料替换',
        invalid: '作废',
        creationDate: '创建日期',
        submitDate: '提交日期',
        submitSjbg: '提交设计报告',
        designer: '设计人',
        sfxzsjbg: '是否从在线设计中选择',
        xzsjbg: '选择设计报告',
        scsjbg: '上传设计报告',
        newsjbg: '新增设计报告',
        sjbgList: '设计报告列表',
        sjbgName: '设计报告全名',
        preview: '预览',
        download: '下载',
        pdsmbxz: '评定书模板下载',
        clickUpload: '点击上传',
        yscdpds: '已生成的评定书',
        generatedTime: '生成时间',
        pds: '评定书',
        ndzjgzzlb: '年度钻井工程总览表',
        dcgczlb: '导出工程总览表',
        logging: '录井',
        jdxx: '井队信息',
        qsrnr: '请输入内容',
        zjgs: '钻井公司',
        zjd: '钻井队',
        zjdz: '钻井队长',
        zjgh: '钻井固话',
        zjdsjh: '钻井队手机号及其他网络联系方式',
        gjgs: '固井公司',
        gjd: '固井队',
        gjdz: '固井队长',
        gjgh: '固井固话',
        gjdsjh: '固井队手机号及其他网络联系方式',
        wjgs: '完井公司',
        wjd: '完井队',
        wjdz: '完井队长',
        wjgh: '完井固话',
        wjdsjh: '完井队手机号及其他网络联系方式',
        sysqgs: '试油试气公司',
        sysqd: '试油试气队',
        sysqdz: '试油试气队长',
        sysqgh: '试油试气固话',
        sysqsjh: '试油试气队手机号及其他网络联系方式',
        anfcx: '按年份查询',
        newAdd: '新增',
        years: '年份',
        months: '月份',
        zjdh: '钻井队号',
        txyj: '填写意见',
        wtx: '未填写',
        return: '返回',
        hyrq: '会议日期',
        chzj: '参会专家',
        zzsjscbz: '组织设计审查备注',
        sqghfj: '申请更换附件',
        zszjyj: '主审专家意见',
        sjbgbg: '设计报告更换',
        zszj: '主审专家',
        scqx: '审查期限',
        zszjytjzzps: '主审专家已提交最终评审结论！',
        tjsjbgwd: '添加设计报告文档',
        wdlb: '文档类别',
        wdmc: '文档名称',
        xzjh: '选择井号',
        wdfjsc: '文档附件上传',
        scr: '上传人',
        djsc: '点击上传',
        tdwjzcc: '将文件拖到此处，或',
        yjck: '意见查看',
    },
}