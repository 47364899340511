<template>
  <div class="menu-wrapper">
    <template v-for="child in routes" v-if="!child.hidden && !hiddenMenu.includes(child.path)">
      <el-submenu v-if="child.children && child.children.length > 0" :index="child.path">
        <template slot="title">
          <svg-icon v-if="child.meta && child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
          <span v-if="child.meta && child.meta.title">{{ generateTitle(child.meta.title) }}</span>
        </template>
        <sidebar-item :is-nest="true" class="nest-menu" :routes="child.children"
                      :partnerPath="partnerPath + '/' + child.path" :key="child.path"></sidebar-item>
      </el-submenu>


      <!-- <div v-else><router-link :to="partnerPath+'/'+child.path">{{partnerPath+'/'+child.path}}</router-link></div> -->
      <!-- <router-link v-else :to="partnerPath+'/'+child.path" :key="child.name"> -->
      <el-menu-item v-else :index="partnerPath + '/' + child.path" @click="clickItem(child)">
        <svg-icon v-if="child.meta && child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
        <span slot="title" class="svgicon" v-if="child.meta && child.meta.title">{{
            generateTitle(child.meta.title)
          }}</span>
      </el-menu-item>
      <!-- </router-link> -->
    </template>
  </div>
</template>

<script>
import { generateTitle } from '@/framework/utils/i18n'
import { clickMenu } from '@/XTGL/api/frequencyStatisticsApi.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    partnerPath: {
      type: String
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['name'])
  },
  data() {
    return {
      target: '',
      hiddenMenu: ['torqueAnalysis']
    }
  },
  methods: {
    generateTitle,
    clickItem(data) {
      if (data) {
        let params = {
          id: data.id,
          parentId: data.parentId,
          browsers: this.name
        }
        clickMenu(params)
      }
    }
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
.menu-wrapper {
  .svg-icon {
    margin-left: 4px;
    color: white;
  }
}
</style>

