export default {
    changeProcess: {
        annual: '年度',
        pleaseChoose: '请选择',
        state: '状态',
        keywords: '请输入关键字',
        pleaseInput: '请输入',
        query: '查询',
        assignChangeDesignTasks: '下达变更设计任务',
        cancelTask: '撤销任务',
        changeNamw: '变更名称',
        changeDesignReport: '变更设计报告',
        attachmentToSee: '附件查看',
        notUpload: '未上传',
        taskReleaseDate: '任务发布日期',
        designPeriod: '设计期限',
        reviewStatus: '审核状态',
        operation: '操作',
        editor: '编辑',
        toView: '查看',
        historyOfDesignReports: '设计报告历史',
        historyReports: '历史报告',
        changeDesignTaskDetails: '变更设计任务详情',
        taskNamw: '任务名称',
        changeDesignType: '变更设计类型',
        drillingDesingChange: '钻井设计变更',
        changeTime: '选择时间',
        executor: '执行人',
        reviewDirector: '评审负责人',
        focusOnThePeople: '关注人',
        ececutionLogForm: '执行日志表格',
        numericalOrder: '序号',
        time: '时间',
        Operator: '操作人',
        operatingSpecies: '操作种类',
        operatingResults: '操作结果',
        missionStatemnet: '任务说明',
        shutDown: '关闭',
        cancel: '取消',
        checkTheAttachment: '附件查看',
        submissionDeadline: '提交期限',
        submitDesign: '提交设计',
        submit: '提交',
        save: '保存',
        evaluationExperts: '评审专家',
        organizePeople: '组织人',
        updateDate: '更新时间',
        review: '评审',
        addReviewers: '添加评审人',
        reviewOpinions: '评审意见',
        approvalOpinion: '审批意见',
        reviewConclusion: '审查结论',
        currentUserIsNotNotifiedOfTheTask:'当前用户未获取该任务的评审通知',
        expertHistoricalOpinion:'专家意见',
        experts:'专家',
        opinion:'意见',
        expertReviewOpinion:'专家评审意见',
        selectPersonnel:'选择人员',
        addReviewExperts:'添加评审专家',
        add:'添加',
        wells:'井号',
    }
}
