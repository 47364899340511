<template>
  <scroll-bar class="scroll-bar">
    <div>
      <ul class="menuListName">
        <li class="listName" :class="{menuShow:!isCollapse}">
          <svg-icon icon-class="menublock" className="menublock" class="menublock"></svg-icon>
         {{moduleName ? $t('route.' + moduleName) : '系统模块'}}
        </li>
        <li class="listButton">
          <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
        </li>
      </ul>
      <el-menu class="menuList" mode="vertical" unique-opened :default-active="defaultActive" :collapse="isCollapse"
        :text-color="menu.text" :active-text-color="menu.active"
        @select="handleSelect">
        <sidebar-item :routes="routes" :partnerPath="partnerPath"></sidebar-item>
      </el-menu>

    </div>
  </scroll-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import ScrollBar from '@/framework/components/ScrollBar'
import Hamburger from "@/framework/components/Hamburger"

export default {
  data () {
    return {
      moduleName: '',
      menu: {
        background: '#1c6bd9',
        text: '#FFF',
        active: '#0549b5'
      },
      routes: [],
      partnerPath: ''
    }
  },
  components: { SidebarItem, ScrollBar,Hamburger },
  mounted () {
    if (window._USERMENUTREE.length) {
      window._USERMENUTREE.forEach(item => {
        if (item.path === '/' + window.location.pathname.split('/')[1] || item.path === '/' + window.location.pathname.split('/')[1] + '/' + window.location.pathname.split('/')[2]) {
          this.moduleName = item.meta.title
          document.title = process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME + ' -- ' + item.title : item.title
        }
      })
    }
    // if (window.location.pathname.split('/')[1] === 'sys' && window.location.pathname.split('/')[2] === 'admin') {
    //   this.moduleName = '系统管理'
    // } else if (window.location.pathname.split('/')[1] === 'well') {
    //   this.moduleName = '钻井跟踪'
    // } else if (window.location.pathname.split('/')[1] === 'video'){
    //   this.moduleName = '网络会议'
    // } else if (window.location.pathname.split('/')[1] === 'test2') {
    //   this.moduleName = '区域复杂情况统计'
    // } else if (window.location.pathname.split('/')[1] === 'staticBase') {
		// 	this.moduleName = '数据管理'
    // } else if (window.location.pathname.split('/')[1] ==='surveillance') {
		// 	this.moduleName = '视频监控'
    // } else if (window.location.pathname.split('/')[1] ==='gasManage') {
		// 	this.moduleName = '完测跟踪'
    // }
    switch (this.$store.state.app.css) {
      case 'simple':
        this.menu = {
          background: '#e8eaec',
          text: '#FFF',
          active: '#fff'
        }
        return
      case 'classic':
        this.menu = {
          background: '#e8eaec',
          text: '#FFF',
          active: '#fff'
        }
        return
      case 'black':
        this.menu = {
          background: '#38393d',
          text: '#9daac4',
          active: '#ffffff'
        }
        return
      case 'blue':
        this.menu = {
          background: '#1e3078',
          text: '#9daac4',
          active: '#ffffff'
        }
        return
    }
  },
  computed: {
    ...mapGetters([
      'permission_routers',
      'sidebar'
    ]),
    isCollapse() {
      return !this.sidebar.opened
    },
    css () {
      return this.$store.state.app.css
    },
    defaultActive () {
      return this.$route.path
    }
  },
  watch: {
    css (newVal, oldVal) {

      // console.log(newVal)
      if (newVal === 'simple') {
        this.menu = {
          background: '#1c6bd9',
          text: '#fff',
          active: '#0549b5'
        }
      } else if (newVal === 'classic') {
        this.menu = {
          background: '#222d32',
          text: '#4b646f',
          active: '#ffffff'
        }
      } else if (newVal === 'black') {
        this.menu = {
          background: '#38393d',
          text: '#ffffff',
          active: '#ffffff'
        }
      } else if (newVal === 'blue') {
        this.menu = {
          background: '#1e3078',
          text: '#000',
          active: '#ffffff'
        }
      }
    }
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch("toggleSideBar")
    },
    handleSelect(key, keyPath) {
      this.$router.push({path: key})
    }
  },
  created () {
    const path = window.location.pathname
    this.permission_routers.forEach(item => {
      if (item.path && item.path.split('/')[1] === path.split('/')[1]) {
        this.routes = item.children
        this.partnerPath = item.path
      }
    })
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../../styles/const.scss";
.menuList{
  background: none;
}
.menublock {
  height: 1.5rem !important;
  width: 1.5rem !important;
  vertical-align: -0.4rem !important;
  margin-right: 8px !important;
}
.menuListName{
  // border-bottom: 1px solid #84a2da;
  color: #fff;
  list-style: none;
  height: $tags-height;
  line-height: $tags-height;
  font-size: 16px;
  text-align: left;
  padding: 0 20px;
  // margin: 0;
  // border-top:0.1px solid #fff;
  position: relative;
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 0px;
    border-top:1px solid #fff;
    position: absolute;
    top: 0;
    left:0;
  }
}
.listName{
  display: inline-block;
  width: 0px;
  height: $tags-height;
  line-height: $tags-height;
  float: left;
  overflow: hidden;
}
.listButton{
  position: absolute;
  right: 20px;
  // display: inline-block;
  // width: 70px;
  // height: $tags-height;
  // line-height: $tags-height;
}
// .listButton:hover{
//   background: #E0EAF6 100%;
// }
 @media all and (max-height: 780px) {
   .listButton{
     height: $tags-height;
     line-height: $tags-height;
   }
   .listName{
     height: $tags-height;
     line-height: $tags-height;
   }
   .menuListName{
     height: $tags-height;
     line-height: $tags-height;
   }
 }

.menuShow{
  width: auto;
  max-width: 162px;
  transition: width 0.28s;

}
</style>

