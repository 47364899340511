<template>
  <!--<div class="workbench-wrapper" :class="{hideSidebar:!sidebar.opened}">-->
  <div class="workbench-wrapper app-workbench-wrapper">
    <!--<sidebar class="sidebar-container"></sidebar>
    <div class="main-container">
      <div class="workbench-top">
        <navbar></navbar>
      </div>
      <tags-view></tags-view>
      <div class="workbench-bottom">
        <app-main></app-main>
      </div>
    </div>-->
    <div class="workbench-top">
      <navbar :hasGoBackBtn="false"></navbar>
    </div>
    <div class="workbench-bottom">
      <!-- <el-menu class="menuUl" mode="horizontal" @select="handleSelect" :default-active="activeIndex" background-color="#003265" text-color="#fff" active-text-color="#ffd04b"> -->
        <benchNavNew style="position:absolute;z-index:99;" :navList="navList" :token="token" :parentPath="''"></benchNavNew>
      <!-- </el-menu> -->
      <app-main style="position:absolute;z-index:0;width:100%;"></app-main>
    </div>
  </div>
</template>

<script>
// import { Navbar, Sidebar, AppMain, TagsView } from "./components";
// import benchNav from "../../../Workbench/components/benchNav/index";
import {
  Navbar,
  Sidebar,
  AppMain,
  TagsView
} from "@/framework/views/layout/components";
import benchNavNew from "../components/benchNav/index";
import { getMenuTree, getUserInfo } from "../api/index";
export default {
  name: "layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
    benchNavNew
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  data() {
    return {
      navList: [],
      token: "",
      activeIndex: "1"
    };
  },
  methods: {
    // 獲取token
    _initToken() {
      this.token = this.$store.getters.token;
      let tokenStart = this.token.indexOf("bearer");
      if (tokenStart != 0) {
        //不以这个开头
        this.token = "bearer" + this.token;
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath, 333);
    },
    GetMenuTree() {
      getMenuTree().then(res => {
        console.log(res, "菜单");
        if (res.code == 0) {
          // .slice(1);
          this.navList = res.data;
          console.log("菜单标题", this.navList);
        }
      });
    }
  },
  mounted() {
    this.GetMenuTree();
    this._initToken();
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/framework/styles/const.scss";
@import "src/framework/styles/mixin.scss";

.workbench-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  // width: 100%;
  background: #f0f2f5;
  // overflow: hidden;
  .workbench-top {
    height: $navbar-height;
    line-height: $navbar-height;
    border-radius: 0px !important;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 999;
  }
  @media all and (max-height: 780px) {
    .workbench-top {
      height: $navbar-height;
      line-height: $navbar-height;
    }
  }
  .workbench-bottom {
    position: absolute;
    width: 100%;
    // top: $navbar-height + $tags-height;
    top: $navbar-height;

    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  @media all and (max-height: 780px) {
    .workbench-bottom {
      position: absolute;
      width: 100%;
      // top: $navbar-height + $tags-height;
      top: $navbar-height;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }
}
.menuUl {
  overflow: hidden;
  height: 72px;
  line-height: 40px;
  position: fixed;
  width: 100%;
  min-width: 1509px;
  //margin-left: -5px;
  // z-index: 9999;
  z-index: 3;
  background: #333f54;
  padding: 0 20px 0 154px;
  /*li {
      float: left;
     !* padding: 0 10px;*!
      &:hover {
        border-bottom: 2px solid #409eff;
      }
      span {
        height: 100%;
        display: inline-block;
      }
      a {
        color: #fff;
        &:hover {
          // color: #f66f6a;
          color: #409eff;
        }
      }
      .fontColor {
        color: #fff;
      }
    }*/
}
</style>
